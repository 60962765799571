// Tekion Field validators
import { isEmailRule, isRequiredRule, isPhoneNumberFormatRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { EMPTY_ARRAY } from '@tekion/tekion-base/utils/constant';

// Components
import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/TextInput';
import PhoneNumberField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/PhoneNumberField';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';

import PersonNameInput from '../../../../../../molecules/personNameInput';
import MediaUploaderFormRenderer from '../../../../../../organisms/mediaUploader/MediaUploaderFormRenderer';
import AsyncPaginatedSelect from '../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';

// Field validators
import { isPersonNameRequiredRule } from '../../../../../../utils/formValidators';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import { getProfileList } from '../../../../../../actions/profile.actions';
import { getOptionsForProfile } from './workspaceUserForm.helpers';

// Constants
import FIELD_IDS from '../constants/workspaceUserForm.fieldIds';

const NAME_FIELD = {
  id: FIELD_IDS.NAME,
  renderer: PersonNameInput,
  renderOptions: {
    required: true,
    label: __('Name'),
    firstNameKey: FIELD_IDS.FIRST_NAME,
    lastNameKey: FIELD_IDS.LAST_NAME,
    validators: [isPersonNameRequiredRule(FIELD_IDS.FIRST_NAME, FIELD_IDS.LAST_NAME)],
  },
};

const DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.DISPLAY_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Display name'),
    placeholder: __('Enter display name'),
  },
};

const EMAIL_FIELD = {
  id: FIELD_IDS.EMAIL,
  renderer: TextInputField,
  renderOptions: {
    label: __('E-mail'),
    placeholder: 'example@tekion.com',
  },
};

const PHONE_NUMBER_FIELD = {
  id: FIELD_IDS.PHONE,
  renderer: PhoneNumberField,
  renderOptions: {
    label: __('Phone number'),
    placeholder: '(___) ___-____',
    validators: [isPhoneNumberFormatRule, isRequiredRule],
    required: true,
  },
};

const PROFILE_NAME_FIELD = {
  id: FIELD_IDS.PROFILE_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    label: __('Profile Name'),
    required: true,
    validators: [isRequiredRule],
    serviceHandler: getProfileList,
    getOptions: getOptionsForProfile,
  },
};

const ROLE_FIELD = {
  id: FIELD_IDS.ROLE,
  renderer: SelectInput,
  renderOptions: {
    label: __('Role'),
  },
};

const PROFILE_PICTURE_FIELD = {
  id: FIELD_IDS.PROFILE_PICTURE,
  renderer: MediaUploaderFormRenderer,
  renderOptions: {
    label: __('Profile Picture'),
    accept: '.jpg,.png,.jpeg',
  },
};

const getUserFormFields = (mode, isUserPresentInOtherWorkspace, roleOptions) => ({
  [FIELD_IDS.NAME]: addToRenderOptions(NAME_FIELD, [{ path: 'disabled', value: isUserPresentInOtherWorkspace }]),
  [FIELD_IDS.DISPLAY_NAME]: addToRenderOptions(DISPLAY_NAME_FIELD, [{ path: 'disabled', value: isUserPresentInOtherWorkspace }]),
  [FIELD_IDS.PHONE]: addToRenderOptions(PHONE_NUMBER_FIELD, [{ path: 'disabled', value: isUserPresentInOtherWorkspace }]),
  [FIELD_IDS.PROFILE_NAME]: PROFILE_NAME_FIELD,
  [FIELD_IDS.ROLE]: addToRenderOptions(ROLE_FIELD, [{ path: 'options', value: roleOptions }]),
  [FIELD_IDS.PROFILE_PICTURE]: addToRenderOptions(PROFILE_PICTURE_FIELD, [{ path: 'disabled', value: isUserPresentInOtherWorkspace }]),
  [FIELD_IDS.EMAIL]: addToRenderOptions(EMAIL_FIELD, [
    { path: 'disabled', value: mode !== FORM_MODES.CREATE },
    { path: 'required', value: mode === FORM_MODES.CREATE },
    { path: 'validators', value: mode === FORM_MODES.CREATE ? [isRequiredRule, isEmailRule] : EMPTY_ARRAY },
  ]),
});

export { getUserFormFields };
