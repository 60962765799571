import React, { useEffect, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import Dropdown, { DROPDOWN_PLACEMENT, DROPDOWN_TRIGGER } from '@tekion/tekion-components/molecules/DropDown';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Tooltip from '@tekion/tekion-components/atoms/tooltip';
import Menu from '@tekion/tekion-components/molecules/Menu';

import useDeepCompareEffect from '../../../../hooks/useDeepCompareEffect';
import withActionExecutionModal from '../../../../connectors/withActionExecutionModal';
import EntityViewViewer from '../../../viewBuilder/organisms/entityViewViewer/EntityViewViewer';
import BlankWidgetContainer from '../../atoms/blankWidgetContainer';
import VisualBuilderEventEmitter from '../../../../eventEmitters/visualBuilderEventEmitter';
import GeneralEventEmitter from '../../../../eventEmitters/generalEventEmitter';
import { GENERAL_EVENT_NAMES, ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME } from '../../../../eventEmitters/constants/generalEventEmitter.constants';

import ApplicationRendererContext from '../../../../context/applicationRenderer.context';

import ACTION_HANDLERS from './detailViewWidgetRenderer.actionHandlers';

import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import { COMPONENT_CONFIG_KEYS, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { ACTION_TYPES } from './detailViewWidgetRenderer.constants';
import { EVENT_ACTIONS } from '../../../../constants/eventActions.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../constants/applicationRenderer.constants';
import { ACTION_DEFINITION_ACTION_TYPES } from '../../../../constants/actionBuilder.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

import entityReader from '../../../../readers/entity.reader';

import styles from './detailViewWidgetRenderer.module.scss';

const SUPPORTED_CUSTOM_STYLE_DETAIL = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.STANDARD_WIDGET];
const DetailViewWidgetRenderer = ({
  isPreviewMode,
  isViewConfigLoading,
  isEntityDefLoading,
  isViewConfigure,
  isActionLoading,
  isEntityRecordRefetching,
  componentConfig,
  entityDef,
  entityRecord,
  detailEntityViewConfiguration,
  actionOptions,
  onAction,
}) => {
  const applicationContext = useContext(ApplicationRendererContext);

  const entityName = entityReader.name(entityDef);

  const componentProperties = useMemo(() => tget(componentConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, EMPTY_OBJECT), [componentConfig]);
  const showActions = useMemo(() => tget(componentProperties, 'showActions', false), [componentProperties]);
  const customStyles = useMemo(() => tget(componentProperties, COMPONENT_CONFIG_KEYS.CUSTOM_STYLES, {}), [componentProperties]);
  const widgetName = useMemo(() => tget(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME), [componentConfig]);

  const cachedEntitiesDef = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.ENTITIES_DEF, EMPTY_OBJECT), [applicationContext]);
  const cachedEntityViewConfigByNameForEntities = useMemo(
    () => tget(applicationContext, APPLICATION_CONTEXT_KEYS.ENTITY_VIEW_CONFIG_BY_NAME_FOR_ENTITIES, EMPTY_OBJECT),
    [applicationContext],
  );
  const handleSetEntityDef = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_ENTITY_DEF, _noop), [applicationContext]);
  const handleSetEntityViewConfig = useMemo(
    () => tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_ENTITY_VIEW_CONFIG, _noop),
    [applicationContext],
  );

  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_DETAIL, customStyles, entityRecord),
    [entityRecord, customStyles],
  );

  const handleMenuClick = useCallback(
    (payload) => {
      onAction({ type: ACTION_TYPES.ACTION_CLICK, payload });
    },
    [onAction],
  );

  const handleFetchActions = useCallback(() => {
    onAction({ type: ACTION_TYPES.FETCH_ACTIONS });
  }, [onAction]);

  const renderActionMenu = useCallback(
    () =>
      isActionLoading ? (
        <Loader />
      ) : (
        <Menu onClick={handleMenuClick}>
          {!_isEmpty(actionOptions) ? (
            _map(actionOptions, ({ id, name, disabled = false, errorMessage }) => (
              <Menu.Item key={id} disabled={disabled} className={styles.actionMenuItem}>
                <div>{name}</div>
                {disabled ? (
                  <Tooltip className={styles.actionMenuTooltip} placement="topRight" title={errorMessage}>
                    <FontIcon>icon-information-filled</FontIcon>
                  </Tooltip>
                ) : null}
              </Menu.Item>
            ))
          ) : (
            <div className={styles.noDataContainer}>{__('No Actions Added')}</div>
          )}
        </Menu>
      ),
    [actionOptions, handleMenuClick, isActionLoading],
  );

  const renderWidget = () => (
    <div className="full-width" style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}>
      {showActions && (
        <div className={styles.actionContainer}>
          <Dropdown
            placement={DROPDOWN_PLACEMENT.BOTTOM_RIGHT}
            trigger={DROPDOWN_TRIGGER.CLICK}
            className={styles.actionDropDown}
            overlay={renderActionMenu}
            onClick={handleFetchActions}
          >
            <FontIcon data-disable-sort-start>icon-overflow</FontIcon>
          </Dropdown>
        </div>
      )}
      <EntityViewViewer
        isPreviewMode
        widgetName={widgetName}
        entityViewConfiguration={detailEntityViewConfiguration}
        entityDef={entityDef}
        entityRecord={entityRecord}
        applicationContext={applicationContext}
      />
    </div>
  );

  useDeepCompareEffect(() => {
    onAction({
      type: ACTION_TYPES.LOAD_ENTITY_DEF,
      payload: {
        cachedEntitiesDef,
        handleSetEntityDef,
      },
    });
  }, [cachedEntitiesDef, handleSetEntityDef, onAction, componentConfig]);

  useDeepCompareEffect(() => {
    onAction({
      type: ACTION_TYPES.LOAD_ENTITY_VIEW_CONFIG,
      payload: {
        cachedEntityViewConfigByNameForEntities,
        handleSetEntityViewConfig,
      },
    });
  }, [cachedEntityViewConfigByNameForEntities, handleSetEntityViewConfig, onAction]);

  useEffect(() => {
    const handleActionTrigger = (payload) => {
      onAction({ type: ACTION_TYPES.TRIGGER_ACTION_BUILDER_ACTION, payload });
    };

    VisualBuilderEventEmitter.on(`${widgetName}:${EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION}`, handleActionTrigger);

    return () => {
      VisualBuilderEventEmitter.removeAllListeners(`${widgetName}:${EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION}`);
    };
  }, [widgetName, onAction]);

  useEffect(() => {
    const ACTION_BUILDER_EVENT_FUNCTIONS = {
      UPDATE_RECORD: (payload) => {
        const ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT = ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME[GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT];

        const entityNameFromEvent = tget(payload, ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_NAME);

        // Currently we assume that when update page entity record event comes we want to refresh
        if (entityName === entityNameFromEvent) {
          onAction({ type: ACTION_TYPES.REFETCH_ENTITY_RECORD });
        }
      },
    };

    // Registering General events

    // Registering Action Builder Events
    GeneralEventEmitter.on(
      `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD}`,
      ACTION_BUILDER_EVENT_FUNCTIONS.UPDATE_RECORD,
    );

    return () => {
      GeneralEventEmitter.removeListener(
        `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD}`,
        ACTION_BUILDER_EVENT_FUNCTIONS.UPDATE_RECORD,
      );
    };
  }, [onAction, entityName]);

  if (isViewConfigLoading || isEntityDefLoading || isEntityRecordRefetching) {
    return <Loader />;
  }

  if (!isViewConfigure && !isPreviewMode) return <BlankWidgetContainer componentConfig={componentConfig} />;

  // Returning null is record is not found
  if (_isEmpty(entityRecord)) return null;

  return renderWidget();
};

DetailViewWidgetRenderer.propTypes = {
  isPreviewMode: PropTypes.bool,
  isViewConfigure: PropTypes.bool,
  isViewConfigLoading: PropTypes.bool,
  isActionLoading: PropTypes.bool,
  isEntityDefLoading: PropTypes.bool,
  isEntityRecordRefetching: PropTypes.bool,
  componentConfig: PropTypes.object,
  entityRecord: PropTypes.object,
  entityDef: PropTypes.object,
  detailEntityViewConfiguration: PropTypes.object,
  actionOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

DetailViewWidgetRenderer.defaultProps = {
  isPreviewMode: false,
  isViewConfigure: false,
  isViewConfigLoading: false,
  isActionLoading: false,
  isEntityDefLoading: false,
  isEntityRecordRefetching: false,
  componentConfig: EMPTY_OBJECT,
  entityRecord: EMPTY_OBJECT,
  entityDef: EMPTY_OBJECT,
  detailEntityViewConfiguration: EMPTY_OBJECT,
  actionOptions: EMPTY_ARRAY,
};

export default compose(withActionExecutionModal, withActions(EMPTY_OBJECT, ACTION_HANDLERS))(DetailViewWidgetRenderer);
