import NullComponent from '@tekion/tekion-components/atoms/nullComponent';

// Common across Experience & Studio
import Section from '../atoms/section/Section';
import SectionColumn from '../atoms/sectionColumn/SectionColumn';
import CommentSection from '../atoms/commentSection/CommentSection';
import DetailViewAttributeRenderer from '../components/detailViewAttributeRenderer/DetailViewAttributeRenderer';
import FormSection from '../atoms/formSection/FormSection';
import FormSectionRow from '../atoms/formSectionRow/FormSectionRow';
import FormSectionRowColumn from '../atoms/formSectionRowColumn/FormSectionRowColumn';
import RightPaneViewAttributeRenderer from '../components/rightPaneViewAttributeRenderer/RightPaneViewAttributeRenderer';
import Row from '../atoms/row/Row';
import Column from '../atoms/column/Column';
import CellViewAttributeRenderer from '../components/cellViewAttributeRenderer/CellViewAttributeRenderer';
import ImageRenderer from '../components/imageRenderer/ImageRenderer';
import ListColumn from '../atoms/listColumn/ListColumn';
import ButtonRenderer from '../components/buttonRenderer';

// Studio side renderers
import ListBuilderViewRenderer from '../components/listBuilderViewRenderer/ListBuilderViewRenderer';
import FormBuilderViewRenderer from '../components/formBuilderViewRenderer/FormBuilderViewRenderer';

// Experience side renderers
import FormViewRenderer from '../components/formViewRenderer/FormViewRenderer';
import GridViewRenderer from '../components/gridViewRenderer/GridViewRenderer';
import WithListActionExecution from '../containers/withListActionExecution/WithListActionExecution';

import { COMPONENT_TYPES } from '../constants/viewBuilder.constants';

const getComponentFromComponentType = (componentType, isPreviewMode) => {
  switch (componentType) {
    case COMPONENT_TYPES.SECTION: {
      return Section;
    }

    case COMPONENT_TYPES.COMMENT: {
      return CommentSection;
    }

    case COMPONENT_TYPES.SECTION_COLUMN: {
      return SectionColumn;
    }

    case COMPONENT_TYPES.FORM_VIEW_SECTION: {
      return FormSection;
    }

    case COMPONENT_TYPES.FORM_VIEW_ROW: {
      return FormSectionRow;
    }

    case COMPONENT_TYPES.FORM_VIEW_COLUMN: {
      return FormSectionRowColumn;
    }

    case COMPONENT_TYPES.DETAIL_VIEW_ATTRIBUTE: {
      return DetailViewAttributeRenderer;
    }

    case COMPONENT_TYPES.LIST_VIEW_COLUMN: {
      return ListColumn;
    }

    case COMPONENT_TYPES.GRID_VIEW: {
      return isPreviewMode ? WithListActionExecution : GridViewRenderer;
    }

    case COMPONENT_TYPES.LIST_VIEW_RENDERER: {
      return isPreviewMode ? WithListActionExecution : ListBuilderViewRenderer;
    }

    case COMPONENT_TYPES.FORM_VIEW_RENDERER: {
      return isPreviewMode ? FormViewRenderer : FormBuilderViewRenderer;
    }

    case COMPONENT_TYPES.RIGHT_PANE_VIEW_ATTRIBUTE: {
      return RightPaneViewAttributeRenderer;
    }

    case COMPONENT_TYPES.ROW: {
      return Row;
    }

    case COMPONENT_TYPES.COLUMN: {
      return Column;
    }

    case COMPONENT_TYPES.CELL_VIEW_ATTRIBUTE: {
      return CellViewAttributeRenderer;
    }

    case COMPONENT_TYPES.IMAGE: {
      return ImageRenderer;
    }

    case COMPONENT_TYPES.BUTTON: {
      return ButtonRenderer;
    }

    default: {
      return NullComponent;
    }
  }
};

export { getComponentFromComponentType };
