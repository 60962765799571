import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import _split from 'lodash/split';
import _last from 'lodash/last';
import _toLower from 'lodash/toLower';
import _isNil from 'lodash/isNil';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget, uuid } from '@tekion/tekion-base/utils/general';

import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { fetchPageConfigurationByName, savePageAndWidgetConfiguration } from '../../../actions/visualBuilder.actions';
import { fetchEntityRecordById } from '../../../actions/recordManagement.actions';
import { getMasterEntityRecord, getMasterEntityDef } from '../../../actions/entityManagement.actions';

import {
  getUpdatedPageConfiguration,
  addNewContainerToConfiguration,
  getPageConfigurationOnDrop,
  getPageConfigurationOnDeleteWidget,
  getDefaultPropertiesForGivenWidgetType,
  getFlattenProperties,
  modifyPageConfigurationToApiFormat,
  updatePageAndWidgetConfiguration,
} from './visualBuilder.helper';

import { STUDIO_ROUTE } from '../../../constants/routes';
import PAGE_IDS from '../../../pages/devPlatform/constants/PageIds.constants';
import { ACTION_TYPES, COMPONENT_CONFIG_KEYS, PAGE_CONFIGURATION_FIELD_IDS, WIDGET_POSITIONS } from '../constants/visualBuilder.general.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../constants/applicationRenderer.constants';
import { PAGE_TYPES, WIDGET_TYPES } from '../../../constants/visualBuilder';
import { STANDARD_ENTITY_NAME } from '../../../constants/general.constants';

import RECORD_FIELDS from '../../../constants/recordFields';

import localStorageReader from '../../../readers/localStorage.reader';

const handleDrop = ({ getState, setState, params }) => {
  const { pageConfiguration, pageEntity, defaultWidgetNameCount = EMPTY_OBJECT } = getState();
  const containers = tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY);
  const containerId = tget(params, 'id', '');
  const widgetIndex = tget(params, 'index', 0);
  const droppedComponent = tget(params, 'droppedComponent', {});

  let updatedDefaultWidgetNameCount = { ...defaultWidgetNameCount };
  let modifiedPageConfiguration = { ...pageConfiguration };

  const { type = '', widgetComponentConfig = {}, index: droppedComponentIndex } = droppedComponent;

  let isDroppedComponentExistInPageConfiguration = true;
  let selectedWidgetConfig = widgetComponentConfig;
  if (_isEmpty(selectedWidgetConfig)) {
    isDroppedComponentExistInPageConfiguration = false;
    if (_isNil(_get(updatedDefaultWidgetNameCount, [type]))) {
      updatedDefaultWidgetNameCount = { ...updatedDefaultWidgetNameCount, [type]: 1 };
    } else {
      updatedDefaultWidgetNameCount[type] += 1;
    }
    selectedWidgetConfig = {
      widgetName: `${_toLower(type)}_${_last(_split(uuid(), '-'))}`,
      widgetType: type,
      properties: getDefaultPropertiesForGivenWidgetType(type, pageEntity, _get(updatedDefaultWidgetNameCount, [type])),
      children:
        type === WIDGET_TYPES.TAB_WIDGET
          ? [
              {
                widgetName: `${_toLower(WIDGET_TYPES.CONTAINER)}_${_last(_split(uuid(), '-'))}`,
                widgetType: WIDGET_TYPES.CONTAINER,
                properties: { width: 20, widgetPosition: WIDGET_POSITIONS.VERTICAL },
              },
            ]
          : [],
    };
  }

  if (isDroppedComponentExistInPageConfiguration) {
    modifiedPageConfiguration = {
      ...getPageConfigurationOnDeleteWidget(
        tget(selectedWidgetConfig, 'widgetName', ''),
        containers,
        modifiedPageConfiguration,
        droppedComponentIndex,
      ),
    };
  }

  setState({
    defaultWidgetNameCount: { ...updatedDefaultWidgetNameCount },
    selectedWidgetConfig,
    pageConfiguration: { ...getPageConfigurationOnDrop(containerId, containers, selectedWidgetConfig, modifiedPageConfiguration, widgetIndex) },
  });
};

const handleInit = async ({ getState, setState }) => {
  const { pageConfiguration, match = EMPTY_OBJECT, history = EMPTY_OBJECT } = getState();
  const pageName = _get(match, 'params.pageName');
  setState({
    isLoading: true,
  });
  if (_isEmpty(pageConfiguration)) {
    history.replace(`${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER_CREATE}`);
  }
  let newPageConfiguration = { ...pageConfiguration };

  // below condition will never occur. needs to be remove while refactoring
  if (pageName && _isEmpty(newPageConfiguration)) {
    newPageConfiguration = await fetchPageConfigurationByName(pageName, false);
  }

  const pageType = _get(pageConfiguration, 'pageType');
  // const modifiedPageConfiguration = getPageConfigWithFlattenProperties(newPageConfiguration);

  if (pageType === PAGE_TYPES.RECORD_DETAIL_PAGE) {
    setState({ isLoading: true });

    const properties = tget(pageConfiguration, COMPONENT_CONFIG_KEYS.PROPERTIES, []);
    const flattenedProperties = getFlattenProperties(properties);
    _set(pageConfiguration, COMPONENT_CONFIG_KEYS.PROPERTIES, flattenedProperties);
    const entityName = _get(pageConfiguration, 'properties.entityName');
    const masterEntityDef = await getMasterEntityDef(entityName);

    const entityRecord = await getMasterEntityRecord(undefined, RECORD_FIELDS.ID, masterEntityDef, true);

    setState({
      pageEntity: masterEntityDef,
      pageEntityRecord: entityRecord,
    });
  }

  const userResponse = await fetchEntityRecordById(STANDARD_ENTITY_NAME.USER, tget(localStorageReader.userInfo(), 'userId', ''));

  setState({
    isLoading: false,
    pageConfiguration,
    pageType,
    selectedWidgetConfig: getArraySafeValue(tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY)),
    visualBuilderRendererContextValue: {
      [APPLICATION_CONTEXT_KEYS.CURRENT_USER]: {
        ..._omit(userResponse, ['entity']),
        ...tget(userResponse, 'entity'),
      },
    },
  });
};

const handleUpdateGridLayout = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { pageConfiguration } = getState();
  const updatedContainers = tget(params, 'value', tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY));
  const widgetName = PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS;
  const newPageConfiguration = getUpdatedPageConfiguration(widgetName, pageConfiguration, updatedContainers);
  setState({ pageConfiguration: newPageConfiguration });
};

const handleUpdateWidgetConfiguration = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const newWidgetConfigurationValue = tget(params, 'value.widgetConfiguration');
  const widgetName = tget(params, 'value.widgetName');
  const { pageConfiguration } = getState();
  const newPageConfiguration = getUpdatedPageConfiguration(widgetName, pageConfiguration, newWidgetConfigurationValue);
  setState({
    pageConfiguration: { ...newPageConfiguration },
    selectedWidgetConfig: _cloneDeep(newWidgetConfigurationValue),
  });
};

const handleAddGridLayout = ({ getState, setState }) => {
  const { pageConfiguration } = getState();

  const newPageConfiguration = addNewContainerToConfiguration(pageConfiguration);

  setState({ pageConfiguration: newPageConfiguration });
};

const handleWidgetClick = ({ params, setState }) => {
  const selectedWidgetConfig = tget(params, 'widgetComponentConfig', {});
  setState({ selectedWidgetConfig: _cloneDeep(selectedWidgetConfig) });
};

const handleCancel = ({ setState }) => {
  setState({ showCancelModal: true });
};

const handleConfirmationDialogSubmit = ({ getState }) => {
  const { history } = getState();
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.VISUAL_BUILDER}`);
};

const handleConfirmationDialogCancel = ({ setState }) => {
  setState({ showCancelModal: false });
};

const handleSubmitPageConfiguration = async ({ getState, setState }) => {
  const { match, pageConfiguration, widgetConfiguration } = getState();
  const oldWidgetConfiguration = _cloneDeep(widgetConfiguration);
  const pageName = _get(match, 'params.pageName');

  const modifiedPageConfiguration = modifyPageConfigurationToApiFormat(_cloneDeep(pageConfiguration));

  let response = {};
  setState({ isSaveLoading: true });

  if (!_isEmpty(pageName)) {
    response = await updatePageAndWidgetConfiguration(pageConfiguration, oldWidgetConfiguration);
  } else {
    response = await savePageAndWidgetConfiguration(modifiedPageConfiguration);
  }

  if (response) {
    setTimeout(() => {
      handleConfirmationDialogSubmit({ getState });
    }, ES_REFETCH_DELAY);
  } else {
    setState({ isSaveLoading: false });
  }
};

const handlePreviewModalClose = ({ setState }) => {
  setState({ showPreviewModal: false });
};

const handlePreviewModalOpen = ({ setState, getState }) => {
  const { pageConfiguration } = getState();
  setState({ showPreviewModal: true, previewPageConfiguration: _cloneDeep(pageConfiguration) });
};

const handleWidgetDelete = ({ setState, getState, params }) => {
  const { pageConfiguration } = getState();
  const containers = tget(pageConfiguration, PAGE_CONFIGURATION_FIELD_IDS.CONTAINERS, EMPTY_ARRAY);
  const widgetName = tget(params, 'deletedWidgetComponentId', '');
  const widgetIndex = tget(params, 'index', 0);
  setState({
    selectedWidgetConfig: {},
    pageConfiguration: { ...getPageConfigurationOnDeleteWidget(widgetName, containers, pageConfiguration, widgetIndex) },
  });
};

const handleSetApplicationVariables = ({ getState, setState, params }) => {
  const { variableName, value } = params;
  const { visualBuilderRendererContextValue = {} } = getState();

  const previousApplicationVariables = tget(visualBuilderRendererContextValue, APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES, {});

  setState({
    visualBuilderRendererContextValue: {
      ...visualBuilderRendererContextValue,
      [APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES]: { ...previousApplicationVariables, [variableName]: value },
    },
  });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_DROP]: handleDrop,
  [ACTION_TYPES.ON_INIT]: handleInit,
  [ACTION_TYPES.UPDATE_GRID_LAYOUT]: handleUpdateGridLayout,
  [ACTION_TYPES.ADD_GRID_LAYOUT]: handleAddGridLayout,
  [ACTION_TYPES.UPDATE_WIDGET_CONFIGURATION]: handleUpdateWidgetConfiguration,
  [ACTION_TYPES.ON_WIDGET_CLICK]: handleWidgetClick,
  [ACTION_TYPES.SET_APPLICATION_VARIABLES]: handleSetApplicationVariables,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
  [ACTION_TYPES.ON_CONFIRM_DIALOG_SUBMIT]: handleConfirmationDialogSubmit,
  [ACTION_TYPES.ON_CONFIRM_DIALOG_CANCEL]: handleConfirmationDialogCancel,
  [ACTION_TYPES.ON_SUBMIT]: handleSubmitPageConfiguration,
  [ACTION_TYPES.ON_PREVIEW_MODAL_CLOSE]: handlePreviewModalClose,
  [ACTION_TYPES.ON_PREVIEW_MODAL_OPEN]: handlePreviewModalOpen,
  [ACTION_TYPES.ON_WIDGET_DELETE]: handleWidgetDelete,
};

export default ACTION_HANDLERS;
