exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".emailPreviewRenderer_emailNotPresent__ck3GGhYh92{font-size:1.8rem}[dir] .emailPreviewRenderer_emailNotPresent__ck3GGhYh92{text-align:center}.emailPreviewRenderer_container__2BQsAJXVTN{width:100%;height:100%;overflow-y:auto}[dir] .emailPreviewRenderer_container__2BQsAJXVTN{background-color:#fff;padding:.8rem}.emailPreviewRenderer_flexRow__dkNtteCmdX{display:flex}.emailPreviewRenderer_subContainer__shP8rCHtDM{align-items:center}.emailPreviewRenderer_richTextEditorWrapper__umwUnBJfnF{min-height:30rem;max-height:50rem;width:90%}", ""]);

// Exports
exports.locals = {
	"emailNotPresent": "emailPreviewRenderer_emailNotPresent__ck3GGhYh92",
	"container": "emailPreviewRenderer_container__2BQsAJXVTN",
	"flexRow": "emailPreviewRenderer_flexRow__dkNtteCmdX",
	"subContainer": "emailPreviewRenderer_subContainer__shP8rCHtDM",
	"richTextEditorWrapper": "emailPreviewRenderer_richTextEditorWrapper__umwUnBJfnF"
};