exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".widgetComponent_widgetComponent__71BUaFpRJB{-webkit-user-select:none;user-select:none;position:relative;height:100%}[dir] .widgetComponent_widgetComponent__71BUaFpRJB{border-radius:5px;cursor:pointer;background-color:#f4f5f6;border:.08rem solid #969aa3;padding:.5rem}.widgetComponent_widgetComponent__71BUaFpRJB .widgetComponent_btnCross__cEM8kd8A65{width:1rem;height:1rem;top:.8rem}[dir=ltr] .widgetComponent_widgetComponent__71BUaFpRJB .widgetComponent_btnCross__cEM8kd8A65{right:.8rem}[dir=rtl] .widgetComponent_widgetComponent__71BUaFpRJB .widgetComponent_btnCross__cEM8kd8A65{left:.8rem}.widgetComponent_widgetComponent__71BUaFpRJB .widgetComponent_btnEdit__dER8r9chmT{width:1rem;height:1rem;position:absolute;display:none;top:.8rem}[dir=ltr] .widgetComponent_widgetComponent__71BUaFpRJB .widgetComponent_btnEdit__dER8r9chmT{right:4rem}[dir=rtl] .widgetComponent_widgetComponent__71BUaFpRJB .widgetComponent_btnEdit__dER8r9chmT{left:4rem}.widgetComponent_widgetComponent__71BUaFpRJB .widgetComponent_content__9fvftwqLEb{width:100%;display:flex;flex-direction:column;flex-grow:0}.widgetComponent_widgetComponent__71BUaFpRJB.widgetComponent_selected__3jy2S1KSuu{display:flex;justify-content:space-between}[dir] .widgetComponent_widgetComponent__71BUaFpRJB.widgetComponent_selected__3jy2S1KSuu{border:1px solid #4285f4}.widgetComponent_widgetComponent__71BUaFpRJB.widgetComponent_selected__3jy2S1KSuu>.widgetComponent_btnCross__cEM8kd8A65{display:block}[dir=ltr] .widgetComponent_widgetComponent__71BUaFpRJB.widgetComponent_selected__3jy2S1KSuu>.widgetComponent_btnCross__cEM8kd8A65{margin:.4rem .8rem 0 0}[dir=rtl] .widgetComponent_widgetComponent__71BUaFpRJB.widgetComponent_selected__3jy2S1KSuu>.widgetComponent_btnCross__cEM8kd8A65{margin:.4rem 0 0 .8rem}", ""]);

// Exports
exports.locals = {
	"widgetComponent": "widgetComponent_widgetComponent__71BUaFpRJB",
	"btnCross": "widgetComponent_btnCross__cEM8kd8A65",
	"btnEdit": "widgetComponent_btnEdit__dER8r9chmT",
	"content": "widgetComponent_content__9fvftwqLEb",
	"selected": "widgetComponent_selected__3jy2S1KSuu"
};