import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import AsyncPaginatedSelect from '../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';

import { fetchActions } from '../../../../../../../actions/actionBuilder.actions';

import { getPayload, getActionNameOptions } from './customActionsVisualConfigurator.helper';
import { FIELD_IDS } from '../constants/customActionsVisualConfigurator.constants';

const ENTITY_ACTIONS_TO_SHOW = {
  id: FIELD_IDS.ACTIONS_TO_SHOW,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    isMulti: true,
    label: __('Select Actions'),
    id: FIELD_IDS.ACTIONS_TO_SHOW,
    serviceHandler: fetchActions,
    getOptions: getActionNameOptions,
  },
};

const CUSTOM_ACTIONS_FORM_FIELDS = {
  [FIELD_IDS.ACTIONS_TO_SHOW]: ENTITY_ACTIONS_TO_SHOW,
};

const getFields = (entityName) => ({
  ...CUSTOM_ACTIONS_FORM_FIELDS,
  [FIELD_IDS.ACTIONS_TO_SHOW]: addToRenderOptions(CUSTOM_ACTIONS_FORM_FIELDS[FIELD_IDS.ACTIONS_TO_SHOW], [
    { path: 'getPayload', value: getPayload(entityName) },
  ]),
});

export default getFields;
