import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import cx from 'classnames';

import VisualBuilderContext from '../../../../context/visualBuilder.context';

import styles from './dropZone.module.scss';

const DropZone = ({ id, index, className, acceptedComponentTypes }) => {
  const { onDrop } = useContext(VisualBuilderContext);
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: acceptedComponentTypes,
    drop: (...args) => onDrop(id, index, ...args),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={dropRef}
      className={cx(styles.dropZone, className, {
        [styles.dropZoneHover]: canDrop && isOver,
        [styles.onHoverClassName]: canDrop && !isOver,
      })}
    >
      {__('Drop Here')}
    </div>
  );
};

DropZone.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  acceptedComponentTypes: PropTypes.arrayOf(PropTypes.string),
};

DropZone.defaultProps = {
  className: '',
  acceptedComponentTypes: [],
};

export default DropZone;
