import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _concat from 'lodash/concat';
import _keyBy from 'lodash/keyBy';
import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import _forEach from 'lodash/forEach';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchAction, fetchApplicableActionsForRecords, fetchActions } from '../../../../actions/actionBuilder.actions';
import { getEntityViewDefFromCache } from '../../../../actions/applicationRenderer.actions';
import { getMasterEntityRecord } from '../../../../actions/entityManagement.actions';

import { getEntityRecordAndDefFromWidgetType, getPayloadForFetchActions } from './detailViewWidgetRenderer.helpers';
import { triggerOnActionModalInit } from '../../../../molecules/actionExecutionModal/actionExecutionModal.helpers';
import { getResolvedRichTextEditorFieldRecords } from '../../../../helpers/richTextField.helpers';

import { ACTION_TYPES } from './detailViewWidgetRenderer.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../constants/actionBuilder.constants';
import { COMPONENT_CONFIG_KEYS } from '../../constants/visualBuilder.general.constants';
import { EVENT_META_DATA_FIELD_IDS } from '../../../../constants/event.fieldIds';
import { CUSTOM_ACTIONS_PROPERTIES } from '../../constants/visualBuilder.properties.constants';

const handleLoadEntityViewConfig = async ({ getState, setState, params }) => {
  const { componentConfig = EMPTY_OBJECT } = getState();
  const { cachedEntityViewConfigByNameForEntities, handleSetEntityViewConfig } = params;

  const entityViewName = _get(componentConfig, 'properties.entityViewName');
  const entityName = _get(componentConfig, 'properties.entityName');

  if (!_isEmpty(entityViewName)) {
    setState({ isViewConfigLoading: true });

    const detailEntityViewConfiguration = await getEntityViewDefFromCache({
      cacheValue: cachedEntityViewConfigByNameForEntities,
      setCacheHandler: handleSetEntityViewConfig,
      entityViewName,
      entityName,
    });

    setState({
      isViewConfigLoading: false,
      isViewConfigure: true,
      detailEntityViewConfiguration,
    });
  } else {
    setState({
      isViewConfigLoading: false,
      isViewConfigure: false,
    });
  }
};

const handleLoadEntityDef = async ({ getState, setState, params }) => {
  const { componentConfig = EMPTY_OBJECT, pageEntity, pageEntityRecord, isEntityRecordFetched = false } = getState();

  const { cachedEntitiesDef, handleSetEntityDef } = params;

  const detailViewEntityName = tget(componentConfig, 'properties.entityName');

  if (!_isEmpty(detailViewEntityName)) {
    setState({ isEntityDefLoading: true });

    const { entityDef, entityRecord } = await getEntityRecordAndDefFromWidgetType({
      pageEntity,
      pageEntityRecord,
      componentConfig,
      detailViewEntityName,
      cachedEntitiesDef,
      handleSetEntityDef,
      isEntityRecordFetched,
    });

    if (!isEntityRecordFetched && !_isNil(entityRecord)) {
      let newEntityRecord = await getResolvedRichTextEditorFieldRecords(entityDef, entityRecord);
      newEntityRecord = getArraySafeValue(newEntityRecord);

      setState({
        isEntityRecordFetched: true,
        entityRecord: newEntityRecord,
        entityDef,
      });
    } else if (_isNil(entityRecord)) {
      setState({ entityDef, entityRecord });
    }
  }

  setState({
    isEntityDefLoading: false,
  });
};

const handleActionClick = async ({ getState, params = EMPTY_OBJECT }) => {
  const { actionDefinitions, entityDef, entityRecord, actionModalContextId } = getState();

  const actionName = _get(params, 'key');
  const recordId = _get(entityRecord, 'id');
  const actionDefinition = _get(actionDefinitions, actionName, EMPTY_OBJECT);

  triggerOnActionModalInit(actionModalContextId, {
    recordId,
    actionName,
    actionDef: actionDefinition,
    recordData: entityRecord,
    entityDef,
  });
};

const handleFetchActions = async ({ getState, setState }) => {
  const { actionDefinitions = {}, componentConfig, entityRecord } = getState();

  setState({ isActionLoading: true });

  const actionNamesToShow = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${CUSTOM_ACTIONS_PROPERTIES.ACTION_NAMES}`, []);
  const entityName = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.entityName`);
  const entityRecordId = _get(entityRecord, 'id');

  const [actionDefsFromActionNamesFromConfigResponse, actionDefsForApplicableActionsResponse] = await Promise.all([
    fetchActions(getPayloadForFetchActions({ entityName, actionNames: actionNamesToShow })),
    fetchApplicableActionsForRecords(entityName, { recordIds: [entityRecordId] }),
  ]);

  const actionDefsFromActionNamesFromConfig = tget(actionDefsFromActionNamesFromConfigResponse, 'hits', EMPTY_ARRAY);

  const actionDefsForApplicableActions = _get(actionDefsForApplicableActionsResponse, entityRecordId, []);

  const actionDefsNameForApplicableActions = _map(actionDefsForApplicableActions, (action) => tget(action, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME));

  const actionDefsAvailableToTrigger = [];
  const disableActionDefs = [];

  _forEach(actionDefsFromActionNamesFromConfig, (actionDef) => {
    const name = tget(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME);
    // Action available to trigger
    if (_includes(actionDefsNameForApplicableActions, name)) {
      actionDefsAvailableToTrigger.push(actionDef);
    } else {
      disableActionDefs.push(actionDef);
    }
  });

  const newActionOptions = _concat(
    _map(actionDefsAvailableToTrigger, (action) => ({
      id: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, EMPTY_STRING),
      name: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, EMPTY_STRING),
    })),
    _map(disableActionDefs, (action) => ({
      id: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME, EMPTY_STRING),
      name: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, EMPTY_STRING),
      disabled: true,
      errorMessage: _get(action, 'errorMessage', __('This record is not qualifying the entry condition for this action.')),
    })),
  );

  const newActionDefinitions = {
    ...actionDefinitions,
    ..._keyBy(actionDefsAvailableToTrigger, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME),
    ..._keyBy(disableActionDefs, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME),
  };

  setState({
    isActionLoading: false,
    actionOptions: newActionOptions,
    actionDefinitions: newActionDefinitions,
  });
};

const handleTriggerActionBuilderAction = async ({ getState, setState, params }) => {
  const { actionDefinitions = {}, entityDef, entityRecord, actionModalContextId } = getState();

  const actionName = _get(params, EVENT_META_DATA_FIELD_IDS.ACTION_NAME_TO_EXECUTE);
  const recordId = _get(entityRecord, 'id');
  let actionDefinition = _get(actionDefinitions, actionName, EMPTY_OBJECT);

  if (_isEmpty(actionDefinition)) {
    actionDefinition = await fetchAction(actionName);

    setState({ actionDefinitions: { ...actionDefinitions, [actionName]: actionDefinition } });
  }

  triggerOnActionModalInit(actionModalContextId, {
    recordId,
    actionName,
    actionDef: actionDefinition,
    recordData: entityRecord,
    entityDef,
  });
};

const refetchEntityRecord = async ({ getState, setState }) => {
  const { entityRecord, entityDef } = getState();

  setState({ isEntityRecordRefetching: true });

  const id = tget(entityRecord, 'id');

  if (!_isEmpty(id)) {
    const updatedEntityRecord = await getMasterEntityRecord(id, 'id', entityDef);

    setState({ entityRecord: updatedEntityRecord });
  }

  setState({ isEntityRecordRefetching: false });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOAD_ENTITY_DEF]: handleLoadEntityDef,
  [ACTION_TYPES.LOAD_ENTITY_VIEW_CONFIG]: handleLoadEntityViewConfig,
  [ACTION_TYPES.ACTION_CLICK]: handleActionClick,
  [ACTION_TYPES.FETCH_ACTIONS]: handleFetchActions,
  [ACTION_TYPES.TRIGGER_ACTION_BUILDER_ACTION]: handleTriggerActionBuilderAction,
  [ACTION_TYPES.REFETCH_ENTITY_RECORD]: refetchEntityRecord,
};

export default ACTION_HANDLERS;
