import _isEmpty from 'lodash/isEmpty';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput/TextInput';
import CreatableSelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/CreatableSelectInput';
import Radio from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';

import CalloutRequestDetailsRenderer from './components/calloutRequestDetailsRenderer/CalloutRequestDetailsRenderer';
import AsyncPaginatedSelect from '../../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';
import SelectInputFieldWrapper from '../../../../../../../../connectors/selectInputFieldWrapper/SelectInputFieldWrapper';
import EntityAsyncSelect from '../../../../../../molecules/EntityAsyncSelect';

import { searchServerCredentials } from '../../../../../../../../actions/serverCredential.actions';

import { getServerCredentialOptions, getServerCredentialPayload } from './calloutModal.helpers';

import { ASSIGNED_VARIABLE_DATA_TYPE_OPTIONS } from '../../../constants/workflow.nodeVariableConstants';
import { CALLOUT_FIELD_IDS } from '../../../constants/nodeDataFieldIds';
import { HTTP_METHOD_OPTIONS } from './calloutModal.constants';

import styles from './calloutModal.module.scss';

const rowFilter = {
  [CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE]: ({ isResponseExpected, entityName }) => isResponseExpected && !_isEmpty(entityName),
  [CALLOUT_FIELD_IDS.ENTITY_NAME]: ({ isResponseExpected }) => isResponseExpected,
  [CALLOUT_FIELD_IDS.VARIABLE_TYPE]: ({ isResponseExpected }) => isResponseExpected,
};

const getSections = (formValues) => {
  const isResponseExpected = tget(formValues, CALLOUT_FIELD_IDS.RESPONSE_EXPECTED);
  const entityName = tget(formValues, CALLOUT_FIELD_IDS.ENTITY_NAME, EMPTY_STRING);
  return [
    {
      className: styles.section,
      rows: [
        {
          columns: [CALLOUT_FIELD_IDS.CALLOUT_ACTION_NAME, CALLOUT_FIELD_IDS.SERVER_CREDENTIAL_NAME, CALLOUT_FIELD_IDS.HTTP_METHOD],
        },
        {
          columns: [CALLOUT_FIELD_IDS.PATH],
        },

        {
          columns: [CALLOUT_FIELD_IDS.CONNECT_TIME_OUT, CALLOUT_FIELD_IDS.READ_TIME_OUT],
        },

        {
          columns: [CALLOUT_FIELD_IDS.RESPONSE_EXPECTED],
        },
        {
          columns: filterRows([CALLOUT_FIELD_IDS.VARIABLE_TYPE], { isResponseExpected }, rowFilter),
        },
        {
          columns: filterRows([CALLOUT_FIELD_IDS.ENTITY_NAME, CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE], { isResponseExpected, entityName }, rowFilter),
        },
        {
          className: styles.requestDetailSection,
          columns: [CALLOUT_FIELD_IDS.HEADERS],
        },
        {
          className: styles.requestDetailSection,
          columns: [CALLOUT_FIELD_IDS.REQUEST_BODY],
        },
        {
          className: styles.requestDetailSection,
          columns: [CALLOUT_FIELD_IDS.REQUEST_PARAMS],
        },
      ],
    },
  ];
};

const HTTP_METHOD_FIELD = {
  id: CALLOUT_FIELD_IDS.HTTP_METHOD,
  renderer: SelectInputFieldWrapper(SelectInput),
  renderOptions: {
    label: __('HTTP Method'),
    required: true,
    validators: [isRequiredRule],
    options: HTTP_METHOD_OPTIONS,
    placeholder: __('Select Here'),
  },
};

const PATH_FIELD = {
  id: CALLOUT_FIELD_IDS.PATH,
  renderer: TextInputField,
  renderOptions: {
    label: __('Path'),
    required: true,
    validators: [isRequiredRule],
    placeholder: __('Enter Here'),
    className: styles.section,
  },
};

const SERVER_CREDENTIAL_NAME_FIELD = {
  id: CALLOUT_FIELD_IDS.SERVER_CREDENTIAL_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    label: __('Server Credential'),
    id: CALLOUT_FIELD_IDS.SERVER_CREDENTIAL_NAME,
    required: true,
    validators: [isRequiredRule],
    serviceHandler: searchServerCredentials,
    getOptions: getServerCredentialOptions,
    getPayload: getServerCredentialPayload,
  },
};

const CONNECT_TIME_OUT_FIELD = {
  id: CALLOUT_FIELD_IDS.CONNECT_TIME_OUT,
  renderer: NumberInputField,
  renderOptions: {
    min: 0,
    label: __('Connect Timeout'),
    required: true,
    validators: [isRequiredRule],
    triggerChangeOnBlur: false,
    placeholder: __('Seconds'),
    addonAfter: __('Seconds'),
  },
};

const READ_TIME_OUT_FIELD = {
  id: CALLOUT_FIELD_IDS.READ_TIME_OUT,
  renderer: NumberInputField,
  renderOptions: {
    min: 0,
    label: __('Read Timeout'),
    required: true,
    triggerChangeOnBlur: false,
    validators: [isRequiredRule],
    placeholder: __('Seconds'),
    addonAfter: __('Seconds'),
  },
};

const HEADERS_FIELD = {
  id: CALLOUT_FIELD_IDS.HEADERS,
  renderer: CalloutRequestDetailsRenderer,
  renderOptions: {
    buttonLabel: __('Headers'),
  },
};

const VARIABLE_TYPE_FIELD = {
  id: CALLOUT_FIELD_IDS.VARIABLE_TYPE,
  renderer: Radio,
  renderOptions: {
    required: true,
    label: __('Response Type'),
    validators: [isRequiredRule],
    radios: ASSIGNED_VARIABLE_DATA_TYPE_OPTIONS,
  },
};

const ASSIGNED_VARIABLE_FIELD = {
  id: CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE,
  renderer: SelectInputFieldWrapper(CreatableSelectInput),
  renderOptions: {
    label: __('Response Variable Name'),
  },
};

const ENTITY_NAME_FIELD = {
  id: CALLOUT_FIELD_IDS.ENTITY_NAME,
  renderer: SelectInputFieldWrapper(EntityAsyncSelect),
  renderOptions: {
    required: true,
    size: 6,
    label: __('Entity Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Entity Name'),
  },
};

const RESPONSE_EXPECTED_FIELD = {
  id: CALLOUT_FIELD_IDS.RESPONSE_EXPECTED,
  renderer: Checkbox,
  renderOptions: {
    checkboxLabel: __('Expecting Response'),
  },
};

const REQUEST_BODY_FIELD = {
  id: CALLOUT_FIELD_IDS.REQUEST_BODY,
  renderer: CalloutRequestDetailsRenderer,
  renderOptions: {
    buttonLabel: __('Request Body'),
  },
};

const REQUEST_PARAMS_FIELD = {
  id: CALLOUT_FIELD_IDS.REQUEST_PARAMS,
  renderer: CalloutRequestDetailsRenderer,
  renderOptions: {
    buttonLabel: __('Request Params'),
  },
};

const CALLOUT_NAME_FIELD = {
  id: CALLOUT_FIELD_IDS.CALLOUT_ACTION_NAME,
  renderer: TextInputField,
  renderOptions: {
    label: __('Action name'),
    required: true,
    validators: [isRequiredRule],
    placeholder: __('Enter Here'),
  },
};

const FORM_FIELDS = {
  [CALLOUT_FIELD_IDS.CONNECT_TIME_OUT]: CONNECT_TIME_OUT_FIELD,
  [CALLOUT_FIELD_IDS.HTTP_METHOD]: HTTP_METHOD_FIELD,
  [CALLOUT_FIELD_IDS.READ_TIME_OUT]: READ_TIME_OUT_FIELD,
  [CALLOUT_FIELD_IDS.HEADERS]: HEADERS_FIELD,
  [CALLOUT_FIELD_IDS.REQUEST_BODY]: REQUEST_BODY_FIELD,
  [CALLOUT_FIELD_IDS.REQUEST_PARAMS]: REQUEST_PARAMS_FIELD,
  [CALLOUT_FIELD_IDS.PATH]: PATH_FIELD,
  [CALLOUT_FIELD_IDS.SERVER_CREDENTIAL_NAME]: SERVER_CREDENTIAL_NAME_FIELD,
  [CALLOUT_FIELD_IDS.VARIABLE_TYPE]: VARIABLE_TYPE_FIELD,
  [CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE]: ASSIGNED_VARIABLE_FIELD,
  [CALLOUT_FIELD_IDS.ENTITY_NAME]: ENTITY_NAME_FIELD,
  [CALLOUT_FIELD_IDS.RESPONSE_EXPECTED]: RESPONSE_EXPECTED_FIELD,
  [CALLOUT_FIELD_IDS.CALLOUT_ACTION_NAME]: CALLOUT_NAME_FIELD,
};

const getFieldConfig = (conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, responseVariableOptions) => ({
  ...FORM_FIELDS,
  [CALLOUT_FIELD_IDS.HEADERS]: addToRenderOptions(HEADERS_FIELD, [
    { path: 'conditionBuilderFieldDefinitionObject', value: conditionBuilderFieldDefinitionObject },
    { path: 'mapOfVariableToEntityName', value: mapOfVariableToEntityName },
  ]),
  [CALLOUT_FIELD_IDS.REQUEST_PARAMS]: addToRenderOptions(REQUEST_PARAMS_FIELD, [
    { path: 'conditionBuilderFieldDefinitionObject', value: conditionBuilderFieldDefinitionObject },
    { path: 'mapOfVariableToEntityName', value: mapOfVariableToEntityName },
  ]),
  [CALLOUT_FIELD_IDS.REQUEST_BODY]: addToRenderOptions(REQUEST_BODY_FIELD, [
    { path: 'conditionBuilderFieldDefinitionObject', value: conditionBuilderFieldDefinitionObject },
    { path: 'mapOfVariableToEntityName', value: mapOfVariableToEntityName },
  ]),
  [CALLOUT_FIELD_IDS.ASSIGNED_VARIABLE]: addToRenderOptions(ASSIGNED_VARIABLE_FIELD, [{ path: 'options', value: responseVariableOptions }]),
});

export { getFieldConfig, getSections };
