import _property from 'lodash/property';

const LOGIN_FORM_CONTEXT_ID = 'LOGIN_FORM_CONTEXT_ID';

const LOGIN_ACTION_TYPES = {
  REDIRECT_TO_SSO: 'REDIRECT_TO_SSO',
  RESET_LOGIN: 'RESET_LOGIN',
  GET_HCAPTCHA_TOKEN: 'GET_HCAPTCHA_TOKEN',
  RESEND_OTP: 'RESEND_OTP',
};

const LOGIN_PROVIDERS = {
  OKTA_SAML: 'OKTA_SAML',
  TEKION: 'TEKION',
  OKTA_OPENID: 'OKTA_OPENID',
};

const CDN_PUBLIC_URL = 'https://d36263b6wju30t.cloudfront.net/DMS/common';

const PROVIDER_IMAGES = {
  [LOGIN_PROVIDERS.OKTA_SAML]: `${CDN_PUBLIC_URL}/okta-logo.svg`,
};

const PROVIDERS_DISPLAY_NAME = {
  [LOGIN_PROVIDERS.OKTA_OPENID]: __('OpenId Connect'),
  [LOGIN_PROVIDERS.OKTA_SAML]: __('SAML'),
};

const FIELD_NAMES = {
  USERNAME: 'email',
  PASSWORD: 'password',
  AUTHENTICATOR_TYPE: 'authenticatorType',
  AUTHENTICATOR: 'authenticator',
  OTP: 'otp',
  USER_ID: 'userId',
  TENANT_ID: 'tenantId',
  FORGOT_PASSWORD_BUTTON: 'forgotPassword',
  LOGIN_BUTTON: 'loginButton',
  RESEND_OTP_BUTTON: 'resendOTP',
  EMAIL: 'email',
  DONT_CHALLANGE: 'dontChallengeMfa',
  SSO_PROVIDERS: 'ssoProviders',
  BACK_TO_LOGIN: 'backToLogin',
  MFA_TOKEN: 'mfaToken',
  CAPTCHA_TOKEN: 'captchaToken',
};

const ERROR_CODES = {
  INVALID_CREDENTIAL: 'LOGIN002',
  USER_LOCKED: 'LOGIN004',
  MFA_TOKEN_EXPIRED: 'LOGIN034',
};

const ERROR_CODE_TO_MSG = {
  [ERROR_CODES.INVALID_CREDENTIAL]: __('Invalid username or password'),
  [ERROR_CODES.USER_LOCKED]: __('User is locked. Please try sometime later.'),
  [ERROR_CODES.MFA_TOKEN_EXPIRED]: __('Token expired. Please try again later.'),
};

const FIELDS_TO_PICK_FOR_LOGIN = [FIELD_NAMES.USERNAME, FIELD_NAMES.PASSWORD, FIELD_NAMES.CAPTCHA_TOKEN];
const FIELDS_TO_PICK_FOR_OTP = [FIELD_NAMES.MFA_TOKEN, FIELD_NAMES.USERNAME, FIELD_NAMES.AUTHENTICATOR_TYPE, FIELD_NAMES.CAPTCHA_TOKEN];
const FIELDS_TO_PICK_FOR_OTP_VALIDATION = [...FIELDS_TO_PICK_FOR_OTP, FIELD_NAMES.OTP];

const USER_DATA_KEYS = {
  JOB_TITLE: 'jobTitle',
  EMAIL: 'email',
  USER_ID: 'userId',
  ROLE_ID: 'roleId',
  WORKSPACE_ID: 'workspaceId',
  HOMEWORKSPACE_ID: 'homeWorkspaceId',
  DISPLAY_NAME: 'displayName',
  TENANT_ID: 'tenantId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PASSWORD_SET: 'passwordSet',
  USER_WORKSPACE_DETAILS: 'userWorkspaceDetails',
  TEKION_API_TOKEN: 'accessToken',
  MFA_AUTH_TYPES: 'mfaAuthTypes',
  MFA_ENABLED: 'mfaEnabled',
};

const USER_KEYS = [
  USER_DATA_KEYS.JOB_TITLE,
  USER_DATA_KEYS.EMAIL,
  USER_DATA_KEYS.USER_ID,
  USER_DATA_KEYS.ROLE_ID,
  USER_DATA_KEYS.WORKSPACE_ID,
  USER_DATA_KEYS.DISPLAY_NAME,
  USER_DATA_KEYS.TENANT_ID,
  USER_DATA_KEYS.FIRST_NAME,
  USER_DATA_KEYS.LAST_NAME,
  USER_DATA_KEYS.PASSWORD_SET,
  USER_DATA_KEYS.HOMEWORKSPACE_ID,
  USER_DATA_KEYS.USER_WORKSPACE_DETAILS,
  USER_DATA_KEYS.TEKION_API_TOKEN,
  USER_DATA_KEYS.MFA_REQUIRED,
  USER_DATA_KEYS.MFA_AUTH_TYPES,
];

const LOGIN_PAGE_READER = {
  tenantId: _property(USER_DATA_KEYS.TENANT_ID),
  userId: _property(USER_DATA_KEYS.USER_ID),
  email: _property(USER_DATA_KEYS.EMAIL),
  workspaceId: _property(USER_DATA_KEYS.WORKSPACE_ID),
  tekionApiToken: _property(USER_DATA_KEYS.TEKION_API_TOKEN),
  passwordSet: _property(USER_DATA_KEYS.PASSWORD_SET),
  mfaEnabled: _property(USER_DATA_KEYS.MFA_ENABLED),
  mfaAuthTypes: _property(USER_DATA_KEYS.MFA_AUTH_TYPES),
};

const LOGIN_CONFIG_KEYS = {
  PROVIDER_TYPE: 'providerType',
  IP_SSO_URL: 'IPSSOUrl',
};

const AUTHENTICATOR_TYPES = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  SMS_AND_EMAIL: 'SMS_AND_EMAIL',
};

const MFA_ICONS = {
  [AUTHENTICATOR_TYPES.SMS]: 'icon-chat',
  [AUTHENTICATOR_TYPES.EMAIL]: 'icon-email',
  [AUTHENTICATOR_TYPES.SMS_AND_EMAIL]: 'icon-email',
};

const MFA_HEADINGS = {
  [AUTHENTICATOR_TYPES.SMS]: __('SMS Authentication'),
  [AUTHENTICATOR_TYPES.EMAIL]: __('Email Authentication'),
  [AUTHENTICATOR_TYPES.SMS_AND_EMAIL]: __('SMS/Email Authentication'),
};

const MFA_MESSAGES = {
  [AUTHENTICATOR_TYPES.SMS]: __('Enter the code sent to your registered phone number'),
  [AUTHENTICATOR_TYPES.EMAIL]: __('Enter the code sent to your email'),
  [AUTHENTICATOR_TYPES.SMS_AND_EMAIL]: __('Enter the code sent to your registered phone number or email'),
};

const RESEND_OTP_TIME = 60000;

export {
  LOGIN_PAGE_READER,
  USER_KEYS,
  FIELDS_TO_PICK_FOR_LOGIN,
  ERROR_CODES,
  ERROR_CODE_TO_MSG,
  PROVIDER_IMAGES,
  LOGIN_PROVIDERS,
  LOGIN_ACTION_TYPES,
  LOGIN_FORM_CONTEXT_ID,
  PROVIDERS_DISPLAY_NAME,
  FIELD_NAMES,
  LOGIN_CONFIG_KEYS,
  MFA_MESSAGES,
  MFA_ICONS,
  MFA_HEADINGS,
  AUTHENTICATOR_TYPES,
  FIELDS_TO_PICK_FOR_OTP_VALIDATION,
  FIELDS_TO_PICK_FOR_OTP,
  RESEND_OTP_TIME,
};
