import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import SingleSelect from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SingleSelect';
import Switch from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import MonacoEditor from '../monacoEditor';
import AsyncPaginatedSelect from '../asyncPaginatedSelect';

import { searchPageConfigurations } from '../../actions/visualBuilder.actions';
import { fetchActions } from '../../actions/actionBuilder.actions';
import {
  getFlattenedEventHandlerConfiguration,
  noOptionsMessage,
  getPayload,
  getOptions,
  getActionNameOptions,
  getEventConfigDataFromOnChangeValue,
} from './eventHandler.helpers';

import { CODE_LANGUAGES } from '../monacoEditor/MonacoEditor';
import { FIELD_IDS, GENERATOR_TYPE_OPTIONS, ACTION_TO_EXECUTE_OPTIONS } from './eventHandler.constants';
import { HELPER_EVENT_HANDLER_INITIAL_VALUE_FOR_MONACO_EDITOR } from '../../constants/eventHandlers.constants';
import { EVENT_ACTIONS, EVENT_ACTIONS_TYPES, EVENT_NAMES } from '../../constants/eventActions.constants';

import entityReader from '../../readers/entity.reader';

import styles from './eventHandler.module.scss';

const EventHandlerForm = ({ id, value, error, entityDef, eventTypeOptions, onAction }) => {
  const formEventValue = useMemo(() => getFlattenedEventHandlerConfiguration(value), [value]);

  const eventTypeValue = tget(formEventValue, FIELD_IDS.EVENT_TYPE);
  const subEventType = tget(formEventValue, FIELD_IDS.SUB_EVENT_TYPE);
  const codePanelEnabled = tget(formEventValue, FIELD_IDS.CODE_PANEL, subEventType === EVENT_ACTIONS_TYPES.SCRIPT);
  const actionToExecuteValue = tget(formEventValue, FIELD_IDS.ACTION_TO_EXECUTE);
  const pageNameValue = tget(formEventValue, FIELD_IDS.PAGE_NAME_TO_NAVIGATE);
  const scriptValue = tget(formEventValue, FIELD_IDS.SCRIPT, EMPTY_STRING);
  const scriptGeneratorValue = tget(formEventValue, FIELD_IDS.GENERATOR_TYPE);
  const navigateToPrevPageValue = tget(formEventValue, FIELD_IDS.NAVIGATE_TO_PREV_PAGE);
  const actionName = tget(formEventValue, FIELD_IDS.ACTION_NAME_TO_EXECUTE);

  const showAuditEvent =
    (eventTypeValue === EVENT_NAMES.CLICK || eventTypeValue === EVENT_NAMES.ON_ROW_CLICK) && entityReader.auditEnabled(entityDef);

  const actionToExecuteOptions = useMemo(
    () =>
      showAuditEvent
        ? ACTION_TO_EXECUTE_OPTIONS
        : _filter(ACTION_TO_EXECUTE_OPTIONS, (action) => _get(action, 'value') !== EVENT_ACTIONS.SHOW_AUDIT_LOG_DRAWER),
    [showAuditEvent],
  );

  const handleAction = useCallback(
    (action = EMPTY_OBJECT) => {
      const { type, payload = EMPTY_OBJECT } = action;

      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const eventViewConfigData = getEventConfigDataFromOnChangeValue(formEventValue, payload);

          onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: eventViewConfigData } });

          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [formEventValue, id, onAction],
  );

  const renderEventActionContent = useCallback(() => {
    const isNavigatePreviousPageEnabled = actionToExecuteValue === EVENT_ACTIONS.NAVIGATE && _isEmpty(pageNameValue);
    const isPageIdEnabled = actionToExecuteValue === EVENT_ACTIONS.NAVIGATE && !navigateToPrevPageValue;
    const isActionIdEnabled = actionToExecuteValue === EVENT_ACTIONS.TRIGGER_ACTION_BUILDER_ACTION;

    return (
      <div className={styles.eventActionContent}>
        {!codePanelEnabled && (
          <SingleSelect
            required
            disabled={_isEmpty(eventTypeValue)}
            id={FIELD_IDS.ACTION_TO_EXECUTE}
            fieldClassName={fieldLayoutStyles.fieldC}
            label={__('Action')}
            error={tget(error, FIELD_IDS.ACTION_TO_EXECUTE)}
            value={actionToExecuteValue}
            options={actionToExecuteOptions}
            onAction={handleAction}
          />
        )}
        {isNavigatePreviousPageEnabled && (
          <Checkbox
            id={FIELD_IDS.NAVIGATE_TO_PREV_PAGE}
            fieldClassName={fieldLayoutStyles.fieldC}
            label={__('Navigate To Previous page')}
            error={tget(error, FIELD_IDS.NAVIGATE_TO_PREV_PAGE)}
            value={navigateToPrevPageValue}
            onAction={handleAction}
          />
        )}
        {isPageIdEnabled && (
          <AsyncPaginatedSelect
            required
            isClearable
            disabled={_isEmpty(eventTypeValue)}
            id={FIELD_IDS.PAGE_NAME_TO_NAVIGATE}
            label={__('Page Id')}
            error={tget(error, FIELD_IDS.PAGE_NAME_TO_NAVIGATE)}
            value={pageNameValue}
            serviceHandler={searchPageConfigurations}
            getOptions={getOptions}
            getPayload={getPayload}
            onAction={handleAction}
          />
        )}
        {isActionIdEnabled && (
          <AsyncPaginatedSelect
            required
            isClearable
            disabled={_isEmpty(eventTypeValue)}
            id={FIELD_IDS.ACTION_NAME_TO_EXECUTE}
            label={__('Action Name')}
            error={tget(error, FIELD_IDS.ACTION_NAME_TO_EXECUTE)}
            value={actionName}
            serviceHandler={fetchActions}
            getOptions={getActionNameOptions}
            getPayload={getPayload}
            onAction={handleAction}
          />
        )}
      </div>
    );
  }, [
    actionToExecuteValue,
    pageNameValue,
    codePanelEnabled,
    eventTypeValue,
    error,
    actionToExecuteOptions,
    handleAction,
    navigateToPrevPageValue,
    actionName,
  ]);

  return (
    <div className={styles.container}>
      <SingleSelect
        required
        id={FIELD_IDS.EVENT_TYPE}
        fieldClassName={fieldLayoutStyles.fieldC}
        label={__('Event')}
        error={tget(error, FIELD_IDS.EVENT_TYPE)}
        value={eventTypeValue}
        options={eventTypeOptions}
        noOptionsMessage={noOptionsMessage}
        onAction={handleAction}
      />
      <Switch
        disabled={_isEmpty(eventTypeValue)}
        id={FIELD_IDS.CODE_PANEL}
        fieldClassName={cx(fieldLayoutStyles.fieldC, styles.codePanelContainer)}
        label={__('Code Panel')}
        error={tget(error, FIELD_IDS.CODE_PANEL)}
        value={codePanelEnabled}
        onAction={handleAction}
      />
      {codePanelEnabled && (
        <MonacoEditor
          required
          id={FIELD_IDS.SCRIPT}
          height="20rem"
          helpText={__('Use /* ... */ for adding comments')}
          language={CODE_LANGUAGES.JAVASCRIPT}
          defaultValue={HELPER_EVENT_HANDLER_INITIAL_VALUE_FOR_MONACO_EDITOR}
          error={tget(error, FIELD_IDS.SCRIPT)}
          value={scriptValue}
          onAction={handleAction}
        />
      )}
      {renderEventActionContent()}
      {codePanelEnabled && (
        <SingleSelect
          required
          disabled={_isEmpty(eventTypeValue)}
          id={FIELD_IDS.GENERATOR_TYPE}
          fieldClassName={fieldLayoutStyles.fieldC}
          label={__('Generator Type')}
          error={tget(error, FIELD_IDS.GENERATOR_TYPE)}
          value={scriptGeneratorValue}
          options={GENERATOR_TYPE_OPTIONS}
          onAction={handleAction}
        />
      )}
    </div>
  );
};

EventHandlerForm.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  error: PropTypes.object,
  entityDef: PropTypes.object,
  eventTypeOptions: PropTypes.array,
  onAction: PropTypes.func.isRequired,
};

EventHandlerForm.defaultProps = {
  value: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
  entityDef: EMPTY_OBJECT,
  eventTypeOptions: EMPTY_ARRAY,
};

export default React.memo(EventHandlerForm);
