import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _pickBy from 'lodash/pickBy';
import _keyBy from 'lodash/keyBy';
import _keys from 'lodash/keys';
import _join from 'lodash/join';
import _isNil from 'lodash/isNil';
import _unset from 'lodash/unset';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { uuid } from '@tekion/tekion-base/utils/general';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { TOASTER_TYPE, toaster } from '@tekion/tekion-components/organisms/NotificationWrapper';

import { fetchEntityRecords } from '../../actions/recordManagement.actions';
import { fetchEntities, fetchEntityDefByName } from '../../actions/entityManagement.actions';
import { searchViewConfigurations } from '../../actions/viewBuilderPage.actions';
import { executeAction, fetchRecordForUpdateActionType } from '../../actions/actionBuilder.actions';
import { fetchEntityViewConfigurationByName } from '../../actions/entityViewDefinitions.actions';

import {
  getActionInputData,
  getModalTypeFromActionType,
  getPropsForRenderedComponent,
  getFormViewConfigurationFromFields,
  getUserInputModalData,
} from './actionExecutionModal.helpers';
import { getUpdatedImageTagInHtml } from '../../utils/template.utils';
import { getSearchPayloadsAndMapForNestedEntities, getSelectedInputType } from '../../pages/devPlatformExperience/helpers/actionBuilder.helpers';
import { triggerGeneralEvent } from '../../eventEmitters/helpers/generalEventEmitter.helpers';
import { GENERAL_EVENT_NAMES, ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME } from '../../eventEmitters/constants/generalEventEmitter.constants';
import { ACTION_EXECUTION_MODAL_TYPES, ACTION_TYPES } from './actionExecutionModal.constants';
import {
  ACTION_DEFINITION_FIELD_IDS,
  ACTION_DEFINITION_ACTION_TYPES,
  USER_INPUT_TYPES,
  VALIDATION_OVERRIDE_FIELD_IDS,
  VALIDATION_OVERRIDE_RULE_FIELD_IDS,
} from '../../constants/actionBuilder.constants';
import SYSTEM_DEFINED_FIELDS from '../../constants/systemDefinedFields';

import entityReader from '../../readers/entity.reader';
import fieldDefinitionReader from '../../readers/fieldDefinition.reader';

const handleValidationOverrideUserInput = async ({ getState, setState, params }) => {
  const { actionDef: executedActionDefinition, entityDef, userInputModalData = EMPTY_OBJECT, targetRecordData } = getState();

  let newTargetRecordData = { ...targetRecordData };
  if (!_isEmpty(executedActionDefinition)) {
    const actionInputFormViewName = _get(executedActionDefinition, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME, EMPTY_STRING);
    const selectedFieldsForInput = _get(executedActionDefinition, ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS, EMPTY_ARRAY);
    const selectedInputType = getSelectedInputType(actionInputFormViewName, selectedFieldsForInput);
    const { failedValidationName } = params;

    if (selectedInputType !== USER_INPUT_TYPES.FORM && selectedInputType !== USER_INPUT_TYPES.FIELD) {
      setState({ isUserInputShownOfValidationOverride: true });

      const entityName = _get(entityDef, 'name');
      const targetEntityName = _get(executedActionDefinition, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);
      let targetEntityDef = {};
      if (targetEntityName === entityName) {
        targetEntityDef = entityDef;
      } else if (!_isEmpty(targetEntityName)) {
        targetEntityDef = await fetchEntityDefByName(targetEntityName);
      }

      const validationOverrides = _get(
        executedActionDefinition,
        `${ACTION_DEFINITION_FIELD_IDS.VALIDATION_RULE_OVERRIDE}.${VALIDATION_OVERRIDE_FIELD_IDS.VALIDATION_OVERRIDES}`,
        EMPTY_ARRAY,
      );
      const failedValidationOverrideData = _find(validationOverrides, { ruleName: failedValidationName });
      const failedValidationOverrideFormViewName = _get(
        failedValidationOverrideData,
        VALIDATION_OVERRIDE_RULE_FIELD_IDS.FORM_VIEW_NAME,
        EMPTY_STRING,
      );
      const failedValidationOverrideSelectedFields = _get(
        failedValidationOverrideData,
        VALIDATION_OVERRIDE_RULE_FIELD_IDS.SELECTED_FIELDS,
        EMPTY_ARRAY,
      );
      const failedValidationOverrideSelectedInputType = getSelectedInputType(
        failedValidationOverrideFormViewName,
        failedValidationOverrideSelectedFields,
      );

      const prefilledData = _get(executedActionDefinition, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, EMPTY_OBJECT);
      const actionInputRecord = await getActionInputData(prefilledData, params, entityDef);

      let inputFormViewConfiguration = {};
      switch (failedValidationOverrideSelectedInputType) {
        case USER_INPUT_TYPES.FORM: {
          setState({
            isModalVisible: true,
            isDataLoading: true,
          });
          inputFormViewConfiguration = await fetchEntityViewConfigurationByName(targetEntityName, failedValidationOverrideFormViewName);
          const targetEntityFieldDefByName = _keyBy(entityReader.fieldDefinitions(targetEntityDef), 'name');
          newTargetRecordData = _pickBy({ ...newTargetRecordData, ...newTargetRecordData.entity }, (value, key) => {
            const fieldDef = _get(targetEntityFieldDefByName, key, EMPTY_OBJECT);
            return fieldDefinitionReader.editable(fieldDef);
          });
          break;
        }
        case USER_INPUT_TYPES.FIELD: {
          setState({
            isModalVisible: true,
            isDataLoading: true,
          });
          inputFormViewConfiguration = await getFormViewConfigurationFromFields(targetEntityDef, failedValidationOverrideSelectedFields);
          const selectedFields = _map(failedValidationOverrideSelectedFields, 'fieldName');
          newTargetRecordData = _pickBy({ ...targetRecordData, ..._get(targetRecordData, 'entity', {}) }, (value, fieldId) =>
            _includes(selectedFields, fieldId),
          );
          break;
        }
        default: {
          // do nothing
        }
      }

      const { entityDefsSearchPayload, viewConfigsSearchPayload, complexFieldNameViewNameMap } = getSearchPayloadsAndMapForNestedEntities(
        targetEntityDef,
        inputFormViewConfiguration,
      );

      const [complexEntity, complexViewConfigResponse] = await Promise.all([
        fetchEntities(entityDefsSearchPayload, true),
        searchViewConfigurations(viewConfigsSearchPayload),
      ]);

      const userInputModalPropData = getUserInputModalData(
        complexEntity,
        complexViewConfigResponse,
        complexFieldNameViewNameMap,
        targetEntityDef,
        inputFormViewConfiguration,
        newTargetRecordData,
        actionInputRecord,
        userInputModalData,
      );

      const { entityRecord: additionalUserInputModalData } = userInputModalPropData;
      const contextId = uuid();
      const propsDefaultData = { isPreviewMode: true, fetchEntityRecords, contextId };

      setState({
        isDataLoading: false,
        userInputModalData: { ...userInputModalData, ...additionalUserInputModalData },
        propsForRenderedComponent: { ...propsDefaultData, ...userInputModalPropData },
        contextId,
      });
    }
  }
};

const handleExecuteAction = async ({ getState, setState, params }) => {
  const { payload, isValidationOverrideCheckRequired = true } = params;
  const { actionName, actionDef } = getState();

  const response = await executeAction(actionName, payload);

  if (!_isEmpty(response) && !_isNil(_get(response, 'id'))) {
    // Checking  action is executed successfully and without approval

    const actionType = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
    const targetEntityName = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);
    const eventName = `${GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT}:${actionType}`;
    const ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT = ADDITIONAL_PAYLOAD_IDS_BY_GENERAL_EVENT_NAME[GENERAL_EVENT_NAMES.ACTION_BUILDER_EVENT];

    if (actionType === ACTION_DEFINITION_ACTION_TYPES.CREATE_RECORD) {
      setTimeout(() => {
        triggerGeneralEvent(eventName, { [ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_NAME]: targetEntityName });
      }, [ES_REFETCH_DELAY]);
    } else if (actionType === ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD) {
      const targetRecordId = _get(payload, 'targetRecordId');

      setTimeout(() => {
        triggerGeneralEvent(eventName, {
          [ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_NAME]: targetEntityName,
          [ADDITIONAL_PAYLOAD_IDS_FOR_ACTION_EVENT.ENTITY_RECORD_ID]: targetRecordId,
        });
      }, [ES_REFETCH_DELAY]);
    }
  }

  if (_get(response, 'error', false)) {
    const { failedValidationName } = response;

    if (!_isEmpty(failedValidationName) && isValidationOverrideCheckRequired) {
      handleValidationOverrideUserInput({ params: { ...params, failedValidationName }, getState, setState });
    } else {
      setState({
        isModalVisible: false,
        isUserInputShownOfValidationOverride: false,
        emailData: {},
      });
    }
  } else {
    setState({
      isModalVisible: false,
      isUserInputShownOfValidationOverride: false,
      emailData: {},
    });
  }

  return response;
};

const handleModalInit = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { recordId, actionName, actionDef, recordData, entityDef } = params;

  if (_isEmpty(actionDef)) {
    return;
  }

  const actionType = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const actionInputFormViewName = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME, EMPTY_STRING);
  const selectedFieldsForInput = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS, EMPTY_ARRAY);
  const selectedInputType = getSelectedInputType(actionInputFormViewName, selectedFieldsForInput);
  const modalType = getModalTypeFromActionType(actionType);

  let targetRecordData = {};
  if (actionType === ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD) {
    targetRecordData = await fetchRecordForUpdateActionType(actionName, recordId);
  }

  if (
    selectedInputType !== USER_INPUT_TYPES.FIELD &&
    selectedInputType !== USER_INPUT_TYPES.FORM &&
    modalType === ACTION_EXECUTION_MODAL_TYPES.USER_INPUT
  ) {
    let payload = { recordId, input: {} };

    if (actionType === ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD) {
      const targetRecordId = _get(targetRecordData, 'id');
      payload = { ...payload, targetRecordId };
    }

    setState({ modalType, recordId, actionName, actionType, targetRecordData, actionDef, entityDef, recordData }, async () => {
      await handleExecuteAction({ getState, setState, params: { payload, ...recordData } });
    });
    return;
  }

  setState({ isModalVisible: true, isDataLoading: true, modalType });

  const propsForRenderedComponent = await getPropsForRenderedComponent(modalType, recordId, actionName, entityDef, actionDef, recordData);

  if (modalType === ACTION_EXECUTION_MODAL_TYPES.USER_INPUT) {
    const { contextId } = propsForRenderedComponent;
    setState({ contextId });
  }

  setState({ isDataLoading: false, propsForRenderedComponent, recordId, actionName, actionType, targetRecordData, actionDef, entityDef });
};

const handleOpenModal = ({ setState }) => {
  setState({ isModalVisible: true });
};

const handleCloseModal = ({ setState }) => {
  setState({ isModalVisible: false, emailData: {} });
};

const handleModalSubmit = async ({ getState, setState, params }) => {
  const {
    recordId,
    actionType,
    targetRecordData = EMPTY_OBJECT,
    userInputModalData = {},
    isUserInputShownOfValidationOverride,
    emailData = {},
  } = getState();

  const paramsWithoutEntity = {
    ..._omit(params, 'entity'),
  };

  _unset(paramsWithoutEntity, SYSTEM_DEFINED_FIELDS.ID);

  let input = { ...paramsWithoutEntity, ..._get(params, 'entity', {}) };

  const targetRecordId = _get(targetRecordData, 'id');
  let newUserInputModalData = userInputModalData;
  if (isUserInputShownOfValidationOverride) {
    newUserInputModalData = { ...userInputModalData, ...input };
    input = newUserInputModalData;
  }

  let payload = { recordId, input };
  if (actionType === ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD) {
    payload = { ...payload, targetRecordId };
  }

  if (actionType === ACTION_DEFINITION_ACTION_TYPES.SEND_EMAILS) {
    const newEmailData = _omit(emailData, ['errors']);
    let templateRequest = _get(newEmailData, 'templateRequest', EMPTY_OBJECT);
    const body = getUpdatedImageTagInHtml(_get(templateRequest, 'body', EMPTY_STRING));
    templateRequest = { ...templateRequest, body };
    const errors = _get(emailData, 'errors');
    if (_isEmpty(_get(emailData, 'templateId'))) {
      toaster(TOASTER_TYPE.ERROR, __('The value of Template is Empty.Please select any template to send the email'));
      return;
    }
    if (!_isEmpty(errors)) {
      const variableNames = _join(_keys(errors), ', ');
      toaster(TOASTER_TYPE.ERROR, __(`The value of ${variableNames}  is empty.Please fill these values to send the email`));
      return;
    }
    payload = { ...payload, input: { ...newEmailData, templateRequest } };
  }

  setState({ isModalDataSubmitting: true });

  if (isUserInputShownOfValidationOverride) {
    setState({
      userInputModalData: newUserInputModalData,
    });
    await handleExecuteAction({
      params: { payload },
      getState,
      setState,
    });
  } else {
    const response = await handleExecuteAction({ getState, setState, params: { payload, isValidationOverrideCheckRequired: false } });
    if (!_get(response, 'error', false)) {
      setState({
        isModalVisible: false,
        userInputModalData: {},
        emailData: {},
      });
    }
  }

  setState({ isModalDataSubmitting: false });
};

const handleModalFooterRightSectionClick = ({ getState, setState, params }) => {
  const { modalType } = params;
  const { propsForRenderedComponent = EMPTY_OBJECT } = getState();
  const isEmailPreviewEnabled = _get(propsForRenderedComponent, 'isEmailPreviewEnabled', true);

  if (modalType === ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT) {
    setState({ propsForRenderedComponent: { ...propsForRenderedComponent, isEmailPreviewEnabled: !isEmailPreviewEnabled } });
  }
};

const handleEmailModalContentChange = ({ getState, setState, params }) => {
  const { value, errors } = params;
  const { emailData = EMPTY_OBJECT } = getState();

  setState({ emailData: { ...emailData, ...value, errors } });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_OPEN_MODAL]: handleOpenModal,
  [ACTION_TYPES.ON_CLOSE_MODAL]: handleCloseModal,
  [ACTION_TYPES.ON_MODAL_INIT]: handleModalInit,
  [ACTION_TYPES.ON_MODAL_SUBMIT]: handleModalSubmit,
  [ACTION_TYPES.ON_MODAL_FOOTER_RIGHT_SECTION_CLICK]: handleModalFooterRightSectionClick,
  [ACTION_TYPES.ON_EMAIL_MODAL_CONTENT_CHANGE]: handleEmailModalContentChange,
};

export default ACTION_HANDLERS;
