import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _split from 'lodash/split';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import ValueField from '../../conditionBuilder/atoms/valueField/ValueField';
import AsyncPaginatedSelect from '../../../molecules/asyncPaginatedSelect';

import { getTemplateOptions, getTemplatePayload, handleSearchTemplate } from './templateRenderer.helpers';
import { getVariablesFromTemplate } from '../../../helpers/template.helpers';

import { TEMPLATE_OPERATOR_OPTIONS, TEMPLATE_OPERATOR_VALUE, TEMPLATE_RENDERER_FIELD_IDS } from './templateRenderer.constant';

import styles from './templateRenderer.module.scss';

const TemplateRenderer = ({
  index,
  templateType,
  value,
  templateDefinition,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  templateValueFieldProps,
  onAction,
}) => {
  const selectedTemplate = value[TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE];

  const mapOfVariableValues = _get(value, TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES, EMPTY_OBJECT);

  const subjectVariablesOfTemplate = useMemo(() => getVariablesFromTemplate(_get(templateDefinition, 'subject', EMPTY_STRING)), [templateDefinition]);

  const bodyVariablesOfTemplate = useMemo(() => getVariablesFromTemplate(_get(templateDefinition, 'body', EMPTY_STRING)), [templateDefinition]);

  const variablesOfTemplate = _uniq([...subjectVariablesOfTemplate, ...bodyVariablesOfTemplate]);

  const handleAction = useCallback(
    (action) => {
      const { type, payload } = action;
      switch (type) {
        case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
          const { id: fieldId, value: fieldValue } = payload;
          let updatedValue = {};

          if (_includes(fieldId, '^')) {
            const [changedId, variableName] = _split(fieldId, '^', 2);
            const newValue = { ...mapOfVariableValues, [variableName]: fieldValue };
            updatedValue = { ...value, [changedId]: newValue };
          } else {
            updatedValue = { ...value, [fieldId]: fieldValue };
          }

          onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { index, value: updatedValue } });
          break;
        }

        default: {
          onAction(action);
          break;
        }
      }
    },
    [index, mapOfVariableValues, value, onAction],
  );

  return (
    <div className={styles.container}>
      <AsyncPaginatedSelect
        id={TEMPLATE_RENDERER_FIELD_IDS.SELECTED_TEMPLATE}
        fieldClassName={styles.templateSelectContainer}
        label={__('Template')}
        getPayload={getTemplatePayload(templateType)}
        value={selectedTemplate}
        getOptions={getTemplateOptions}
        serviceHandler={handleSearchTemplate(onAction)}
        onAction={handleAction}
      />
      {_isEmpty(variablesOfTemplate) ? (
        <div>{__('No variables for selected template!')}</div>
      ) : (
        _map(variablesOfTemplate, (variable, subIndex) => (
          <div className={styles.flexRow} key={subIndex}>
            <TextInput disabled label={__('Field')} value={variable} fieldClassName={fieldLayoutStyles.fieldC} />
            <SelectInput
              isDisabled
              label={__('Operator')}
              options={TEMPLATE_OPERATOR_OPTIONS}
              value={TEMPLATE_OPERATOR_VALUE}
              fieldClassName={fieldLayoutStyles.fieldC}
            />
            <ValueField
              {...templateValueFieldProps}
              required
              showFieldSwitcher
              id={`${TEMPLATE_RENDERER_FIELD_IDS.MAP_OF_VARIABLES}^${variable}`}
              label={__('Value')}
              fieldClassName={fieldLayoutStyles.fieldC}
              value={mapOfVariableValues[variable]}
              mapOfVariableToEntityName={mapOfVariableToEntityName}
              conditionBuilderFieldDefinitionObject={conditionBuilderFieldDefinitionObject}
              onAction={handleAction}
            />
          </div>
        ))
      )}
    </div>
  );
};

TemplateRenderer.propTypes = {
  index: PropTypes.number.isRequired,
  actionType: PropTypes.string.isRequired,
  sendEmailsType: PropTypes.string,
  templateType: PropTypes.string,
  sourceEntityName: PropTypes.string.isRequired,
  sourceFieldEntityName: PropTypes.string,
  value: PropTypes.object,
  templateDefinition: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  templateValueFieldProps: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

TemplateRenderer.defaultProps = {
  sendEmailsType: EMPTY_STRING,
  sourceFieldEntityName: EMPTY_STRING,
  templateType: 'EMAIL',
  value: EMPTY_OBJECT,
  templateDefinition: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  templateValueFieldProps: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
};

export default TemplateRenderer;
