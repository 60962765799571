import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import _noop from 'lodash/noop';

import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import withActions from '@tekion/tekion-components/connectors/withActions';
import Loader from '@tekion/tekion-components/molecules/loader';
import { tget } from '@tekion/tekion-base/utils/general';

import useDeepCompareEffect from '../../../../hooks/useDeepCompareEffect';
import EntityViewViewer from '../../../viewBuilder/organisms/entityViewViewer/EntityViewViewer';
import BlankWidgetContainer from '../../atoms/blankWidgetContainer';
import ApplicationRendererContext from '../../../../context/applicationRenderer.context';

import variableApiFactory from '../../../../factories/variableApi.Factory';

import ACTION_HANDLERS from './gridViewWidgetRenderer.actionHandlers';

import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import { ACTION_TYPES } from './gridViewWidgetRenderer.constants';
import { APPLICATION_CONTEXT_KEYS } from '../../../../constants/applicationRenderer.constants';
import { COMPONENT_CONFIG_KEYS, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

const SUPPORTED_CUSTOM_STYLE_GRID = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.STANDARD_WIDGET];
const GridViewWidgetRenderer = ({
  isPreviewMode,
  isViewConfigure,
  isViewConfigLoading,
  isEntityDefLoading,
  isViewOverriding,
  widgetName,
  componentConfig,
  gridViewConfiguration,
  entityDef,
  pageEntity,
  pageEntityRecord,
  viewOverrides,
  onAction,
}) => {
  const applicationContext = useContext(ApplicationRendererContext);
  const currentUserData = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.CURRENT_USER, EMPTY_OBJECT), [applicationContext]);
  const applicationVariables = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.APPLICATION_VARIABLES, {}), [applicationContext]);
  const handleSetApplicationContext = useMemo(
    () => tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_APPLICATION_VARIABLES, _noop),
    [applicationContext],
  );
  const customStyles = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.CUSTOM_STYLES}`, EMPTY_OBJECT);

  const handleSetApplicationVariables = useCallback(
    ({ variableName, value }) => {
      handleSetApplicationContext(variableName, value);
    },
    [handleSetApplicationContext],
  );

  const variablesApi = useMemo(
    () => variableApiFactory(applicationVariables, handleSetApplicationVariables),
    [applicationVariables, handleSetApplicationVariables],
  );

  const cachedEntitiesDef = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.ENTITIES_DEF, EMPTY_OBJECT), [applicationContext]);
  const cachedEntityViewConfigByNameForEntities = useMemo(
    () => tget(applicationContext, APPLICATION_CONTEXT_KEYS.ENTITY_VIEW_CONFIG_BY_NAME_FOR_ENTITIES, EMPTY_OBJECT),
    [applicationContext],
  );
  const handleSetEntityDef = useMemo(() => tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_ENTITY_DEF, _noop), [applicationContext]);
  const handleSetEntityViewConfig = useMemo(
    () => tget(applicationContext, APPLICATION_CONTEXT_KEYS.SET_ENTITY_VIEW_CONFIG, _noop),
    [applicationContext],
  );

  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_GRID, customStyles, pageEntityRecord),
    [pageEntityRecord, customStyles],
  );

  useDeepCompareEffect(() => {
    onAction({
      type: ACTION_TYPES.LOAD_ENTITY_DEF,
      payload: {
        cachedEntitiesDef,
        handleSetEntityDef,
      },
    });
  }, [cachedEntitiesDef, handleSetEntityDef, onAction]);

  useDeepCompareEffect(() => {
    onAction({
      type: ACTION_TYPES.LOAD_ENTITY_VIEW_CONFIG,
      payload: {
        cachedEntityViewConfigByNameForEntities,
        handleSetEntityViewConfig,
      },
    });
  }, [cachedEntityViewConfigByNameForEntities, handleSetEntityViewConfig, onAction]);

  useDeepCompareEffect(() => {
    onAction({
      type: ACTION_TYPES.ON_INIT,
      payload: {
        componentConfig,
        currentUserData,
        applicationVariables,
        variablesApi,
        pageEntity,
        pageEntityRecord,
      },
    });
  }, [
    onAction,
    componentConfig,
    currentUserData,
    applicationVariables,
    variablesApi,
    pageEntity,
    pageEntityRecord,
    isViewConfigLoading,
    isEntityDefLoading,
  ]);

  const renderWidget = useCallback(
    () => (
      <div className="full-width" style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}>
        <EntityViewViewer
          isPreviewMode
          widgetName={widgetName}
          entityViewConfiguration={gridViewConfiguration}
          entityDef={entityDef}
          viewOverrides={viewOverrides}
          applicationContext={applicationContext}
        />
      </div>
    ),
    [applicationContext, entityDef, gridViewConfiguration, viewOverrides, widgetName, resolvedCustomStyles],
  );

  if (isViewConfigLoading || isEntityDefLoading || isViewOverriding) {
    return <Loader />;
  }

  if (!isPreviewMode && !isViewConfigure) return <BlankWidgetContainer componentConfig={componentConfig} />;

  return renderWidget();
};

GridViewWidgetRenderer.propTypes = {
  isPreviewMode: PropTypes.bool,
  isViewConfigure: PropTypes.bool,
  isViewConfigLoading: PropTypes.bool,
  isEntityDefLoading: PropTypes.bool,
  isViewOverriding: PropTypes.bool,
  widgetName: PropTypes.string,
  gridViewConfiguration: PropTypes.object,
  entityDef: PropTypes.object,
  componentConfig: PropTypes.object.isRequired,
  pageEntity: PropTypes.object,
  pageEntityRecord: PropTypes.object,
  viewOverrides: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

GridViewWidgetRenderer.defaultProps = {
  isPreviewMode: false,
  isViewConfigure: false,
  isViewConfigLoading: false,
  isEntityDefLoading: false,
  isViewOverriding: false,
  widgetName: EMPTY_STRING,
  gridViewConfiguration: EMPTY_OBJECT,
  entityDef: EMPTY_OBJECT,
  pageEntity: EMPTY_OBJECT,
  pageEntityRecord: EMPTY_OBJECT,
  viewOverrides: EMPTY_OBJECT,
};

export default compose(withActions(EMPTY_OBJECT, ACTION_HANDLERS), React.memo)(GridViewWidgetRenderer);
