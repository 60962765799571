import { defaultMemoize } from 'reselect';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import AsyncPaginatedSelect from '../../../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';
import TemplateSection from '../../../../../../../../../organisms/templateSection/TemplateSection';
import TextInputWithTags from '../../../../../../../../../atoms/TextInputWithTagsField';
import ValueField from '../../../../../../../../../organisms/conditionBuilder/atoms/valueField/ValueField';

import { fetchEntities } from '../../../../../../../../../actions/entityManagement.actions';
import { getApplicationList } from '../../../../../../../../../actions/applicationManagement.actions';

import { getApplicationOptions, getApplicationPayload, getEntityOptions, getEntityPayload } from './pushNotificationModal.helpers';

import { PUSH_NOTIFICATION_FIELD_IDS } from '../../../../constants/nodeDataFieldIds';
import { TEMPLATE_TYPES } from '../../../../../../../../../constants/templateBuilder.constants';

const NOTIFICATION_APPLICATION_NAME_FIELD = {
  id: PUSH_NOTIFICATION_FIELD_IDS.APPLICATION_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    id: PUSH_NOTIFICATION_FIELD_IDS.APPLICATION_NAME,
    required: true,
    validators: [isRequiredRule],
    label: __('Application'),
    serviceHandler: getApplicationList,
    getOptions: getApplicationOptions,
    getPayload: getApplicationPayload,
  },
};

const NOTIFICATION_PUSHER_ENTITY_FIELD = {
  id: PUSH_NOTIFICATION_FIELD_IDS.PUSHER_ENTITY_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    id: PUSH_NOTIFICATION_FIELD_IDS.PUSHER_ENTITY_NAME,
    required: true,
    validators: [isRequiredRule],
    label: __('Entity'),
    serviceHandler: fetchEntities,
    getOptions: getEntityOptions,
    getPayload: getEntityPayload,
  },
};

const NOTIFICATION_RECEIVERS_FIELD = {
  id: PUSH_NOTIFICATION_FIELD_IDS.RECEIVER_IDS,
  renderer: ValueField,
  renderOptions: {
    required: true,
    keepAttributeAsArray: true,
    showFieldSwitcher: true,
    label: __('Receivers'),
    fieldRenderer: TextInputWithTags,
  },
};

const NOTIFICATION_PUSHER_RECORD_ID_FIELD = {
  id: PUSH_NOTIFICATION_FIELD_IDS.PUSHER_RECORD_ID,
  renderer: ValueField,
  renderOptions: {
    required: true,
    showFieldSwitcher: true,
    label: __('Record ID'),
  },
};

const NOTIFICATION_TEMPLATE_VARIABLES_FIELD = {
  id: PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES,
  renderer: TemplateSection,
  renderOptions: {
    isMultipleTemplateSelectAllowed: false,
    templateType: TEMPLATE_TYPES.PUSHER,
  },
};

const FORM_FIELD = {
  [PUSH_NOTIFICATION_FIELD_IDS.APPLICATION_NAME]: NOTIFICATION_APPLICATION_NAME_FIELD,
  [PUSH_NOTIFICATION_FIELD_IDS.PUSHER_ENTITY_NAME]: NOTIFICATION_PUSHER_ENTITY_FIELD,
  [PUSH_NOTIFICATION_FIELD_IDS.PUSHER_RECORD_ID]: NOTIFICATION_PUSHER_RECORD_ID_FIELD,
  [PUSH_NOTIFICATION_FIELD_IDS.RECEIVER_IDS]: NOTIFICATION_RECEIVERS_FIELD,
  [PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES]: NOTIFICATION_TEMPLATE_VARIABLES_FIELD,
};

const getFields = defaultMemoize((conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, templateDefinitions) => ({
  ...FORM_FIELD,
  [PUSH_NOTIFICATION_FIELD_IDS.PUSHER_RECORD_ID]: addToRenderOptions(FORM_FIELD[PUSH_NOTIFICATION_FIELD_IDS.PUSHER_RECORD_ID], [
    { path: 'conditionBuilderFieldDefinitionObject', value: conditionBuilderFieldDefinitionObject },
    { path: 'mapOfVariableToEntityName', value: mapOfVariableToEntityName },
  ]),
  [PUSH_NOTIFICATION_FIELD_IDS.RECEIVER_IDS]: addToRenderOptions(FORM_FIELD[PUSH_NOTIFICATION_FIELD_IDS.RECEIVER_IDS], [
    { path: 'conditionBuilderFieldDefinitionObject', value: conditionBuilderFieldDefinitionObject },
    { path: 'mapOfVariableToEntityName', value: mapOfVariableToEntityName },
  ]),
  [PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES]: addToRenderOptions(FORM_FIELD[PUSH_NOTIFICATION_FIELD_IDS.TEMPLATES], [
    { path: 'conditionBuilderFieldDefinitionObject', value: conditionBuilderFieldDefinitionObject },
    { path: 'mapOfVariableToEntityName', value: mapOfVariableToEntityName },
    { path: 'templateDefinitions', value: templateDefinitions },
  ]),
}));

export default getFields;
