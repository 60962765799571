exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sendEmailModalContentRenderer_flexRow__jSXifa4kfh{display:flex;flex-direction:row}.sendEmailModalContentRenderer_emailPreviewContainer__wSte1j7Lnf{width:50%;overflow-y:auto;max-height:60rem}[dir] .sendEmailModalContentRenderer_emailPreviewContainer__wSte1j7Lnf{background-color:#f4f5f6;padding:2.4rem 1rem 2rem}.sendEmailModalContentRenderer_addTagStyle__w8UPcBLaZr{width:4rem}.sendEmailModalContentRenderer_emailTemplateContainer__fkdR39YExm{overflow-y:auto}[dir] .sendEmailModalContentRenderer_emailTemplateContainer__fkdR39YExm{margin-top:4rem}.sendEmailModalContentRenderer_templateVariableContainer__7kzgtdY4W7{max-height:40rem;overflow-y:auto}[dir=ltr] .sendEmailModalContentRenderer_templateVariableContainer__7kzgtdY4W7{margin-right:1rem}[dir=rtl] .sendEmailModalContentRenderer_templateVariableContainer__7kzgtdY4W7{margin-left:1rem}.sendEmailModalContentRenderer_customSelect__pAioVVW33X{width:100%}[dir] .sendEmailModalContentRenderer_customSelect__pAioVVW33X .ant-select-selection__choice{padding-top:2px}.sendEmailModalContentRenderer_customSelect__pAioVVW33X .ant-select-selection__choice__remove{top:-1px}[dir=ltr] .sendEmailModalContentRenderer_customSelect__pAioVVW33X .ant-select-selection__choice__remove{right:-1px}[dir=rtl] .sendEmailModalContentRenderer_customSelect__pAioVVW33X .ant-select-selection__choice__remove{left:-1px}.sendEmailModalContentRenderer_richTextEditorWrapper__8sZqHFWcx7{min-height:30rem;max-height:50rem;width:98%;overflow:auto}", ""]);

// Exports
exports.locals = {
	"flexRow": "sendEmailModalContentRenderer_flexRow__jSXifa4kfh",
	"emailPreviewContainer": "sendEmailModalContentRenderer_emailPreviewContainer__wSte1j7Lnf",
	"addTagStyle": "sendEmailModalContentRenderer_addTagStyle__w8UPcBLaZr",
	"emailTemplateContainer": "sendEmailModalContentRenderer_emailTemplateContainer__fkdR39YExm",
	"templateVariableContainer": "sendEmailModalContentRenderer_templateVariableContainer__7kzgtdY4W7",
	"customSelect": "sendEmailModalContentRenderer_customSelect__pAioVVW33X",
	"richTextEditorWrapper": "sendEmailModalContentRenderer_richTextEditorWrapper__8sZqHFWcx7"
};