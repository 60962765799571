import React from 'react';

import ActionExecutionModal from '../../molecules/actionExecutionModal';
import { getUuid } from '../../utils';

const withActionExecutionModal = (WrappedComponent) => {
  const WithActionExecutionModal = (props) => {
    const actionModalContextId = getUuid();
    return (
      <>
        <WrappedComponent {...props} actionModalContextId={actionModalContextId} />
        <ActionExecutionModal actionModalContextId={actionModalContextId} />
      </>
    );
  };

  return WithActionExecutionModal;
};

export default withActionExecutionModal;
