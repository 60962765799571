exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".widgetsPanel_fontStyle__etfZLkeWVH,.widgetsPanel_listGroupHeader__KEf4EqcVDm{color:#161616;font-size:1.4rem;letter-spacing:.03rem;line-height:1.7rem}.widgetsPanel_listGroupHeader__KEf4EqcVDm{font-family:\"Proxima-Nova-Bold\"}[dir] .widgetsPanel_listGroupHeader__KEf4EqcVDm{padding:1.6rem 4rem}[dir] .widgetsPanel_collapseStyle__7j2xRc7CTG .ant-collapse-content-box{padding:0 !important}.widgetsPanel_widgets__p7ahpr9LwH{display:flex;gap:.3rem}[dir] .widgetsPanel_widgets__p7ahpr9LwH{background-color:#f4f5f6;padding:1.5rem;cursor:pointer}[dir=ltr] .widgetsPanel_widgets__p7ahpr9LwH{margin:.5rem .5rem .5rem 0}[dir=rtl] .widgetsPanel_widgets__p7ahpr9LwH{margin:.5rem 0 .5rem .5rem}.widgetsPanel_addContainerButton__5CYW49TKMx{align-self:center}[dir] .widgetsPanel_addContainerButton__5CYW49TKMx{padding:1rem}.widgetsPanel_dragIcon__gWdutXgaDu{color:#89b6df;display:flex;align-items:center}", ""]);

// Exports
exports.locals = {
	"fontStyle": "widgetsPanel_fontStyle__etfZLkeWVH",
	"listGroupHeader": "widgetsPanel_listGroupHeader__KEf4EqcVDm",
	"collapseStyle": "widgetsPanel_collapseStyle__7j2xRc7CTG",
	"widgets": "widgetsPanel_widgets__p7ahpr9LwH",
	"addContainerButton": "widgetsPanel_addContainerButton__5CYW49TKMx",
	"dragIcon": "widgetsPanel_dragIcon__gWdutXgaDu"
};