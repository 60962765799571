import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import selectInputFieldWrapper from '../../../../../../../../connectors/selectInputFieldWrapper';
import AsyncPaginatedSelect from '../../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';
import ConditionBuilder from '../../../../../../../../organisms/conditionBuilder/ConditionBuilder';

import { fetchEntities } from '../../../../../../../../actions/entityManagement.actions';

import { getEntityOptions, getEntityPayload } from './subscriptionFormModal.helpers';
import {
  CONDITION_BUILDER_FIELD_IDS,
  CONDITION_BUILDER_MODES,
  CONDITION_BUILDER_TYPES,
  CONDITION_FIELD_IDS,
  isConditionRequiredRule,
} from '../../../../../../../../organisms/conditionBuilder';
import SUBSCRIPTION_FIELD_IDS from '../constants/subscriptionFormModal.fieldIds';
import { SUBSCRIPTION_EVENT_TYPE_OPTIONS } from '../constants/subscriptionFormModal.constants';

const SUBSCRIPTION_TYPE_FIELD = {
  id: SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_TYPE,
  renderer: selectInputFieldWrapper(SelectInput),
  renderOptions: {
    label: __('Type'),
    required: true,
    validators: [isRequiredRule],
    options: SUBSCRIPTION_EVENT_TYPE_OPTIONS,
  },
};

const SUBSCRIPTION_ENTITY_NAME_FIELD = {
  id: SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_ENTITY_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    label: __('Entity Name'),
    id: SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_ENTITY_NAME,
    validators: [isRequiredRule],
    serviceHandler: fetchEntities,
    getOptions: getEntityOptions,
    getPayload: getEntityPayload,
  },
};

const FORM_FIELDS = {
  [SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_TYPE]: SUBSCRIPTION_TYPE_FIELD,
  [SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_ENTITY_NAME]: SUBSCRIPTION_ENTITY_NAME_FIELD,
};

const getFields = (entityName, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName) => ({
  ...FORM_FIELDS,
  [SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_CONDITION]: {
    id: SUBSCRIPTION_FIELD_IDS.SUBSCRIPTION_CONDITION,
    renderer: ConditionBuilder,
    renderOptions: {
      required: true,
      validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
      containerClassname: fieldLayoutStyles.fieldC,
      mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
      conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
      entityName,
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              label: __('Field'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              label: __('Operator'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              showFieldSwitcher: true,
              label: __('Value'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
          },
        },
      },
    },
  },
});

export default getFields;
