import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _nth from 'lodash/nth';
import _castArray from 'lodash/castArray';
import _head from 'lodash/head';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';
import _cloneDeep from 'lodash/cloneDeep';
import _unset from 'lodash/unset';
import _find from 'lodash/find';

import { tget } from '@tekion/tekion-base/utils/general';
import { getErrorMessage } from '@tekion/tekion-base/utils/errorUtils';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';
import TABLE_ACTION_TYPES from '@tekion/tekion-components/organisms/TableManager/constants/actionTypes';

import { fetchViewConfigurationByName, searchViewConfigurations } from '../../../../actions/viewBuilderPage.actions';
import { fetchEntityRecords, fetchSimilarEntityRecords } from '../../../../actions/recordManagement.actions';
import { retriggerExport } from '../../../../actions/exportsListPage.actions';
import { fetchEntityDefByName } from '../../../../actions/entityManagement.actions';
import { fetchAction, fetchActions, fetchApplicableActionsForRecords } from '../../../../actions/actionBuilder.actions';

import Records from '../../../../actions/customCodeApis/customCode.RecordApi';
import { generateFunction } from '../../../../factories/functionFactory';

import { configFilterOperatorsToTableManagerFilterOperators, convertTableManagerFiltersToConfigFilters } from './listViewRenderer.filterConfig';
import {
  payloadConstructor,
  getUpdatedViewContexts,
  getPayloadForExport,
  getPayloadForActions,
  getTableColumnsFromChildren,
  getViewRecordActionConfigForExpandableRows,
  getCellViewNamesFromColumnConfig,
  getPayloadForCellView,
  getCardViewNameFromGridViewRenderer,
  extractAllFieldsFromViewConfiguration,
} from './listViewRenderer.helpers';
import { setValueInLocalStorage } from '../../../../utils/localStorage';
import { triggerOnActionModalInit } from '../../../../molecules/actionExecutionModal/actionExecutionModal.helpers';
import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';
import { getResolvedRichTextEditorFieldRecords } from '../../../../helpers/richTextField.helpers';
import { extractAllCellViewNamesFromViewConfiguration } from '../../../../helpers/viewBuilder.helpers';

import ROUTES from '../../../../pages/devPlatformExperience/constants/routes';
import { VIEW_TYPES } from '../../constants/viewBuilder.constants';
import {
  VIEW_CONFIGURATION_PROPERTIES_ID,
  VIEW_CONFIGURATION_GENERAL_KEYS,
  ALL_VIEW_PROPERTY_KEYS,
  VIEW_CONFIGURATION_FIELD_IDS,
} from '../../../../constants/viewBuilder.constants';
import { EVENT_META_DATA_FIELD_IDS } from '../../../../constants/event.fieldIds';
import {
  ACTION_TYPES,
  LIST_VIEW_CONFIG_PROPERTY_KEYS,
  EXPANDABLE_ROW_PROPERTY_KEYS,
  ACTION_KEYS,
  SIMILAR_VIEW_DETAIL_FIELDS,
} from './listViewRenderer.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import LS_CACHE_KEYS from '../../../../constants/localStorage.cacheKeys';
import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../constants/eventHandlers.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../constants/actionBuilder.constants';

import localStorageReader from '../../../../readers/localStorage.reader';
import entityReader from '../../../../readers/entity.reader';

const handleUrlAndLocalStorage = ({ getState }) => {
  try {
    const { viewConfigName, history, selectedFilters, sortDetails, searchText, searchField, match } = getState();

    const viewContext = localStorageReader.viewContexts();
    let applicationId;

    if (!_isEmpty(_get(match, 'params.appName'))) {
      applicationId = _get(match, 'params.appName');
    } else {
      applicationId = 'entityTab';
    }

    const updatedViewContext = getUpdatedViewContexts(
      applicationId,
      viewContext,
      viewConfigName,
      selectedFilters,
      sortDetails,
      searchText,
      searchField,
    );
    setValueInLocalStorage(LS_CACHE_KEYS.VIEW_CONTEXTS, JSON.stringify(updatedViewContext));

    const applicationContext = _get(updatedViewContext, applicationId);
    const queryObj = _get(applicationContext, viewConfigName);

    if (!_isEmpty(queryObj)) {
      const stringifiedUrl = JSON.stringify(queryObj);
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete('query');
      searchParams.append('query', stringifiedUrl);
      history.replace({
        pathname: history.location.pathname,
        search: searchParams.toString(),
      });
    } else {
      history.replace({
        pathname: history.location.pathname,
      });
    }
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, error);
  }
};

const fetchTableData = async ({ params = EMPTY_OBJECT, setState, getState }) => {
  const {
    isChildTable = false,
    searchText,
    searchField,
    pageSize,
    entity,
    childRelationshipContext,
    componentConfig,
    selectedFilters,
    preAppliedFilters,
    sortDetails,
    cardViewConfiguration = EMPTY_OBJECT,
    cellViewConfigurationsByName = EMPTY_OBJECT,
    searchOptionToSearchMetadataMapping,
    currentLoggedInUser,
    variablesApi,
  } = getState();

  try {
    const configColumns = getTableColumnsFromChildren(_get(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.CHILDREN));
    const properties = tget(componentConfig, VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES, EMPTY_OBJECT);

    const defaultSortDetails = tget(properties, ALL_VIEW_PROPERTY_KEYS.DEFAULT_SORT_DETAILS);
    const similarViewDetails = tget(properties, ALL_VIEW_PROPERTY_KEYS.SIMILAR_VIEW_DETAILS);

    const isSimilarViewEnabled = tget(similarViewDetails, SIMILAR_VIEW_DETAIL_FIELDS.IS_SIMILAR_VIEW_ENABLED, false);

    const currentPageToken = _get(params, 'nextPageToken', EMPTY_STRING);

    const entityName = _get(entity, 'name');
    const fieldDefinitionsByName = _keyBy(tget(entity, 'fieldDefinitions', EMPTY_ARRAY), 'name');

    const preIncludeFields = [];
    extractAllFieldsFromViewConfiguration(tget(cardViewConfiguration, 'section', EMPTY_OBJECT), preIncludeFields);
    _forEach(cellViewConfigurationsByName, (value) => extractAllFieldsFromViewConfiguration(tget(value, 'section', EMPTY_OBJECT), preIncludeFields));

    const searchMetadata = tget(searchOptionToSearchMetadataMapping, [searchField], EMPTY_ARRAY);
    const newSelectedFilters = convertTableManagerFiltersToConfigFilters(selectedFilters);
    const selectedFiltersWithPreAppliedFilters = [...newSelectedFilters, ...preAppliedFilters];

    const payload = payloadConstructor(
      selectedFiltersWithPreAppliedFilters,
      searchText,
      searchMetadata,
      sortDetails,
      pageSize,
      configColumns,
      currentPageToken,
      defaultSortDetails,
      preIncludeFields,
      fieldDefinitionsByName,
      similarViewDetails,
    );

    if (isChildTable) {
      const filters = _get(payload, 'filters', []);
      filters.push(childRelationshipContext);
    }

    setState({ isLoading: true, isRecordFetch: true });

    let entityData = {};

    if (isSimilarViewEnabled) {
      const recordId = _get(similarViewDetails, SIMILAR_VIEW_DETAIL_FIELDS.RECORD_ID);
      entityData = await fetchSimilarEntityRecords(entityName, recordId, payload);
    } else {
      entityData = await fetchEntityRecords(entityName, payload);
    }

    let entityRecords = tget(entityData, 'hits', EMPTY_ARRAY);
    entityRecords = await getResolvedRichTextEditorFieldRecords(entity, entityRecords);
    const nextPageToken = _get(entityData, 'nextPageToken', '');
    const totalCount = tget(entityData, 'count');

    const eventHandlers = tget(
      componentConfig,
      `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS}`,
      EMPTY_ARRAY,
    );
    const eventHandlersMapByEventName = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

    const eventConfigDataOfAfterDataLoad = tget(eventHandlersMapByEventName, EVENT_NAMES.AFTER_DATA_LOAD, EMPTY_OBJECT);

    const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: entityRecords,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$CURRENT_USER]: currentLoggedInUser,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.VARIABLES]: variablesApi,
    };

    await executeEventFromEventViewConfigData(eventConfigDataOfAfterDataLoad, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE);

    setState({
      isLoading: false,
      data: entityRecords,
      totalCount,
      nextPageToken,
    });
  } catch (error) {
    const entityDisplayName = _get(entity, 'displayName');

    setState({
      isLoading: false,
    });
    const errorMessage = getErrorMessage(error, __('Failed to fetch {{entityDisplayName}} records, please try again later.', { entityDisplayName }));
    toaster(TOASTER_TYPE.ERROR, errorMessage);
  }
};

const handleActionsFetch = async ({ getState, setState }) => {
  const { entity, actionsToShow = EMPTY_ARRAY } = getState();

  const entityName = _get(entity, 'name');
  const data = await fetchActions(getPayloadForActions({ entityName, actionsToShow }));

  const allActions = tget(data, 'hits', EMPTY_ARRAY);

  const allActionsByName = _keyBy(allActions, ACTION_DEFINITION_FIELD_IDS.ACTION_NAME);

  setState({ allActionsByName });
};

const handleTableItemsFetch = async ({ setState, getState, params = {} }) => {
  try {
    const { history, viewConfigName, isFilterPersist = false, match, componentConfig, entity } = getState();
    const entityName = entityReader.name(entity);
    const { properties = {}, children = [] } = componentConfig;
    const showActions = tget(properties, ALL_VIEW_PROPERTY_KEYS.SHOW_ACTIONS, true);
    const cellViewNames = getCellViewNamesFromColumnConfig(children);
    const preAppliedFilters = configFilterOperatorsToTableManagerFilterOperators(
      tget(properties, LIST_VIEW_CONFIG_PROPERTY_KEYS.PRE_APPLIED_FILTERS, []),
    );
    const isExpandableRowsEnabled = tget(properties, LIST_VIEW_CONFIG_PROPERTY_KEYS.EXPANDABLE_ROWS_ENABLED, false);
    const viewRecordActionConfigForExpandableRows = getViewRecordActionConfigForExpandableRows(
      tget(properties, LIST_VIEW_CONFIG_PROPERTY_KEYS.VIEW_RECORD_ACTION_CONFIGS, EMPTY_ARRAY),
    );
    const relatedViewProps = _get(viewRecordActionConfigForExpandableRows, LIST_VIEW_CONFIG_PROPERTY_KEYS.RELATED_VIEW_PROPS, EMPTY_OBJECT);
    const childEntityName = tget(relatedViewProps, EXPANDABLE_ROW_PROPERTY_KEYS.RELATED_ENTITY);
    const childViewConfigurationName = tget(relatedViewProps, EXPANDABLE_ROW_PROPERTY_KEYS.VIEW_NAME);
    const childRelationshipField = tget(relatedViewProps, EXPANDABLE_ROW_PROPERTY_KEYS.RELATED_FIELD);
    const cardViewName = getCardViewNameFromGridViewRenderer(properties);
    const actionsToShow = tget(properties, ALL_VIEW_PROPERTY_KEYS.ACTIONS_TO_SHOW);

    const searchOptions = tget(properties, LIST_VIEW_CONFIG_PROPERTY_KEYS.SEARCH_OPTIONS, []);
    const searchOptionToSearchMetadataMapping = {};
    const searchOptionsFromProperties = _map(searchOptions, (searchOption) => {
      const displayName = tget(searchOption, 'displayName', NO_DATA);
      const name = tget(searchOption, 'name');
      const searchMetadata = tget(searchOption, 'searchMetadata', EMPTY_ARRAY);

      searchOptionToSearchMetadataMapping[name] = searchMetadata;

      return {
        label: displayName,
        value: name,
      };
    });

    const searchField = tget(searchOptionsFromProperties, [0, 'value']);

    let childEntity = {};
    let childViewConfiguration = {};
    let cellViewConfigurations = {};
    let cardViewConfiguration = {};
    let cardViewCellViewConfigs = [];
    const promises = [];

    if (!_isEmpty(cellViewNames)) {
      const payload = getPayloadForCellView(cellViewNames, entityName);
      promises.push(searchViewConfigurations(payload));
    } else {
      promises.push(Promise.resolve());
    }
    if (cardViewName) {
      const payload = getPayloadForCellView(_castArray(cardViewName), entityName);
      promises.push(searchViewConfigurations(payload));
    } else {
      promises.push(Promise.resolve());
    }
    if (isExpandableRowsEnabled) {
      promises.push(fetchEntityDefByName(childEntityName));
      promises.push(fetchViewConfigurationByName(childEntityName, childViewConfigurationName));
    }

    [cellViewConfigurations, cardViewConfiguration, childEntity, childViewConfiguration] = await Promise.all(promises);

    if (!_isEmpty(cardViewConfiguration)) {
      const cardViewConfig = _head(tget(cardViewConfiguration, 'hits', []));
      const cardViewCellViewNames = [];
      extractAllCellViewNamesFromViewConfiguration(_get(cardViewConfig, 'section', {}), cardViewCellViewNames);
      if (!_isEmpty(cardViewCellViewNames)) {
        const payload = getPayloadForCellView(_castArray(cardViewCellViewNames), entityName);
        const cardViewCellViewConfigResponse = await searchViewConfigurations(payload);
        cardViewCellViewConfigs = tget(cardViewCellViewConfigResponse, 'hits', []);
      }
    }

    const cellViewConfigurationsByName = _keyBy(
      [...tget(cellViewConfigurations, 'hits', []), ...cardViewCellViewConfigs],
      VIEW_CONFIGURATION_FIELD_IDS.NAME,
    );

    const viewType = tget(properties, 'viewType', EMPTY_STRING);
    if (viewType === VIEW_TYPES.GRID_VIEW) {
      const columnCount = tget(properties, ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_COLUMN_COUNT, 1);
      const rowCount = tget(properties, ALL_VIEW_PROPERTY_KEYS.GRID_TABLE_ROW_COUNT, 1);
      await setState({ pageSize: columnCount * rowCount });
    }

    // Extracting Custom row actions from component config
    const customRowActionsData = tget(properties, VIEW_CONFIGURATION_PROPERTIES_ID.CUSTOM_ACTIONS, EMPTY_ARRAY);

    if (isFilterPersist && !_isEmpty(history) && !_isEmpty(match)) {
      const searchParamsObj = new URLSearchParams(history.location.search);
      const queryObj = JSON.parse(searchParamsObj.get('query'));
      const viewContextFromLocalStorage = localStorageReader.viewContexts();
      let applicationId;
      if (!_isEmpty(_get(match, 'params.appName'))) applicationId = _get(match, 'params.appName');
      else applicationId = 'entityTab';

      let initialQueriesOnTable = {};
      let applicationContext = {};
      if (!_isEmpty(_get(viewContextFromLocalStorage, applicationId))) {
        applicationContext = _get(viewContextFromLocalStorage, applicationId, {});
      }
      if (!_isEmpty(applicationContext)) initialQueriesOnTable = { ...tget(applicationContext, viewConfigName, EMPTY_OBJECT) };
      if (!_isEmpty(queryObj)) initialQueriesOnTable = { ...initialQueriesOnTable, ...queryObj };
      if (_isEmpty(queryObj) && !_isEmpty(_get(applicationContext, viewConfigName))) {
        const stringifiedUrl = JSON.stringify(_get(applicationContext, viewConfigName));
        searchParamsObj.delete('query');
        searchParamsObj.append('query', stringifiedUrl);
        if (!_isEmpty(stringifiedUrl)) {
          history.replace({
            pathname: history.location.pathname,
            search: searchParamsObj.toString(),
          });
        }
      }

      // QueryObj will take preference
      setState(
        {
          ...initialQueriesOnTable,
          ...params,
          selectedFilters: [],
          preAppliedFilters,
          isExpandableRowsEnabled,
          childEntity,
          childViewConfiguration,
          cardViewConfiguration: _head(tget(cardViewConfiguration, 'hits', [])),
          childRelationshipField,
          cellViewConfigurationsByName,
          actionsToShow,
          searchField,
          searchOptionToSearchMetadataMapping,
          searchOptionsFromProperties,
          customRowActionsData,
        },
        async () => {
          await fetchTableData({ setState, getState });
          await handleActionsFetch({ getState, setState });
        },
      );
    } else {
      setState(
        {
          ...params,
          selectedFilters: [],
          preAppliedFilters,
          isExpandableRowsEnabled,
          childEntity,
          childViewConfiguration,
          cardViewConfiguration: _head(tget(cardViewConfiguration, 'hits', [])),
          childRelationshipField,
          cellViewConfigurationsByName,
          actionsToShow,
          searchField,
          searchOptionToSearchMetadataMapping,
          searchOptionsFromProperties,
          customRowActionsData,
        },
        async () => {
          await fetchTableData({ getState, setState });
          if (showActions) {
            await handleActionsFetch({ getState, setState });
          }
        },
      );
    }
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, error);
  }
};

const handleFilterChange = async ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { isFilterPersist = false } = getState();
  const filters = _get(params, 'value', []);
  const selectedFilterGroup = _get(params, 'selectedFilterGroup');

  setState({ selectedFilters: filters, currentPage: 0, selectedFilterGroup }, () => {
    if (isFilterPersist) {
      handleUrlAndLocalStorage({ getState, setState });
    }
    fetchTableData({ params: { nextPageToken: EMPTY_STRING }, setState, getState });
  });
};

const handleSearchFieldChange = ({ params, getState, setState }) => {
  const { isFilterPersist = false } = getState();
  const searchField = _get(params, 'value');

  setState({ searchField }, () => {
    if (isFilterPersist) {
      handleUrlAndLocalStorage({ params: { searchField }, getState, setState });
    }
  });
};

const handleSearchApply = ({ setState, params = EMPTY_OBJECT, getState }) => {
  const { isFilterPersist = false } = getState();
  const searchText = _get(params, 'value');

  setState({ currentPage: 0, searchText }, () => {
    if (isFilterPersist) {
      handleUrlAndLocalStorage({ params: { searchText }, getState, setState });
    }
    fetchTableData({ params: { nextPageToken: EMPTY_STRING }, setState, getState });
  });
};

const handleSetSort = ({ params = EMPTY_OBJECT, setState, getState }) => {
  const { isFilterPersist = false } = getState();
  const sortTypeMap = _get(params, 'value.sortTypeMap', EMPTY_OBJECT);

  setState({ sortDetails: sortTypeMap }, () => {
    if (isFilterPersist) {
      handleUrlAndLocalStorage({ getState, setState });
    }
    fetchTableData({ setState, getState });
  });
};

const tablePageChanged = ({ setState, getState, params = EMPTY_OBJECT }) => {
  const { pageSize, nextPageToken, currentPage, previousPageTokens, pageToken } = getState();
  const { page, resultsPerPage } = _get(params, 'value');
  let prevPageTokens = [...(previousPageTokens || [])];
  let pageNo = page;
  let currentPageToken = null;

  if (page > currentPage + 1) {
    currentPageToken = nextPageToken;
    prevPageTokens.push(pageToken);
  } else if (page === 1) {
    currentPageToken = null;
    prevPageTokens = _castArray(null);
  } else {
    currentPageToken = prevPageTokens.pop();
  }

  if (pageSize !== resultsPerPage) {
    currentPageToken = null;
    pageNo = 1;
  }

  setState(
    {
      currentPage: pageNo - 1,
      pageSize: resultsPerPage,
      previousPageTokens: prevPageTokens,
      pageToken: currentPageToken,
    },
    () => {
      fetchTableData({ params: { nextPageToken: currentPageToken }, getState, setState });
    },
  );
};

const handleTableItemClick = ({ getState, params }) => {
  const { componentConfig } = getState();
  const eventHandlers = tget(
    componentConfig,
    `${VIEW_CONFIGURATION_GENERAL_KEYS.PROPERTIES}.${VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS}`,
    EMPTY_ARRAY,
  );
  const eventHandlersMapByEventName = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

  const onRowClickEventData = tget(eventHandlersMapByEventName, EVENT_NAMES.ON_ROW_CLICK, EMPTY_OBJECT);
  const entityRecord = _get(params, 'value.original', {});

  executeEventFromEventViewConfigData(onRowClickEventData, {}, entityRecord);
};

const handleDownload = async ({ params = EMPTY_OBJECT, getState }) => {
  const { entity, viewConfigName, history } = getState();
  const { key } = params;
  const entityName = _get(entity, 'name');
  const payload = getPayloadForExport(viewConfigName, entityName, key);

  await retriggerExport(payload);
  history.push(ROUTES.EXPORT_LIST_PAGE_ROUTE);
};

const handleRowActionClick = async ({ getState, params = EMPTY_OBJECT }) => {
  const { allActionsByName, entity, actionModalContextId } = getState();

  const { id: recordId, actionName, additional = EMPTY_OBJECT } = params;

  const isCustomRowAction = tget(additional, ACTION_KEYS.IS_CUSTOM_ROW_ACTION, false);

  if (isCustomRowAction) {
    const eventHandlers = tget(additional, VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS, EMPTY_ARRAY);
    const eventHandlersMapByEventName = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

    const onClickEventData = tget(eventHandlersMapByEventName, EVENT_NAMES.CLICK, EMPTY_OBJECT);

    const entityRecord = _cloneDeep(params);
    _unset(entityRecord, 'additional');

    const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: entityRecord,
    };

    executeEventFromEventViewConfigData(onClickEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, entityRecord);
  } else {
    const action = _get(allActionsByName, actionName);

    triggerOnActionModalInit(actionModalContextId, { recordId, actionName, actionDef: action, recordData: params, entityDef: entity });
  }
};

const handleActionMenuClick = async ({ getState, setState, params }) => {
  const { data, entity, customRowActionsData = EMPTY_ARRAY } = getState();
  const { id: recordId, rowIndex } = params;
  const entityName = entityReader.name(entity);

  const validatedActionsResponse = await fetchApplicableActionsForRecords(entityName, { recordIds: [recordId] });

  const validatedActionNames = _map(_get(validatedActionsResponse, recordId, []), ACTION_DEFINITION_FIELD_IDS.ACTION_NAME);
  const record = _nth(data, rowIndex);
  const newRecord = { ...record, rowAction: validatedActionNames };
  _set(data, rowIndex, newRecord);

  // Logic of generating Enable/Disable Custom Row Action from script written by user
  const updatedCustomRowActionsData = _reduce(
    customRowActionsData,
    (result, customRowAction) => {
      const customRowActionRendererProps = tget(customRowAction, VIEW_CONFIGURATION_GENERAL_KEYS.RENDERER_PROPS, EMPTY_ARRAY);

      const actionEnableScriptRendererPropValue = _find(customRowActionRendererProps, { type: ALL_VIEW_PROPERTY_KEYS.ENABLE_ACTION_SCRIPT });
      const actionEnableScript = tget(actionEnableScriptRendererPropValue, 'value');

      let enable = true;

      if (!_isEmpty(actionEnableScript)) {
        const scriptFuncToExecute = generateFunction([MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD], actionEnableScript);
        enable = scriptFuncToExecute(params);
      }

      result.push({
        ...customRowAction,
        [ACTION_KEYS.ID]: tget(customRowAction, 'name'),
        [ACTION_KEYS.NAME]: tget(customRowAction, 'displayName', NO_DATA),
        [ACTION_KEYS.DISABLED]: !enable,
        [ACTION_KEYS.IS_CUSTOM_ROW_ACTION]: true,
      });

      return result;
    },
    [],
  );

  setState({
    data: [...data],
    customRowActionsData: updatedCustomRowActionsData,
  });
};

const handleRowExpandClick = ({ getState, setState, params = EMPTY_OBJECT }) => {
  const { currentExpandedRows = {} } = getState();
  const { cellInfo } = params;
  const newExpandedRows = { ...currentExpandedRows };
  const index = _get(cellInfo, 'index');
  newExpandedRows[index] = !newExpandedRows[index];

  setState({ currentExpandedRows: newExpandedRows });
};

const handleTriggerActionBuilderAction = async ({ getState, setState, params }) => {
  const { allActionsByName = {}, entity, actionModalContextId } = getState();

  const actionName = _get(params, EVENT_META_DATA_FIELD_IDS.ACTION_NAME_TO_EXECUTE);
  const entityRecord = _get(params, 'entityRecord', {});
  const { id: recordId } = entityRecord;
  let action = _get(allActionsByName, actionName);

  if (_isEmpty(action)) {
    action = await fetchAction(actionName);

    setState({ allActionsByName: { ...allActionsByName, [actionName]: action } });
  }

  triggerOnActionModalInit(actionModalContextId, { recordId, actionName, actionDef: action, recordData: entityRecord, entityDef: entity });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.FETCH_DATA]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: fetchTableData,
  [TABLE_ACTION_TYPES.TABLE_ITEM_CLICK]: handleTableItemClick,
  [ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleFilterChange,
  [TABLE_ACTION_TYPES.TABLE_SEARCH_FIELD]: handleSearchFieldChange,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearchApply,
  [ACTION_TYPES.TABLE_ITEMS_SORT]: handleSetSort,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: tablePageChanged,
  [ACTION_TYPES.SET_URL_AND_LOCAL_STORAGE]: handleUrlAndLocalStorage,
  [ACTION_TYPES.ON_DOWNLOAD]: handleDownload,
  [ACTION_TYPES.TABLE_ROW_ACTION_CLICK]: handleRowActionClick,
  [ACTION_TYPES.TABLE_ACTION_MENU_CLICK]: handleActionMenuClick,
  [ACTION_TYPES.TABLE_ON_EXPAND]: handleRowExpandClick,
  [ACTION_TYPES.TRIGGER_ACTION_BUILDER_ACTION]: handleTriggerActionBuilderAction,
};

export default ACTION_HANDLERS;
