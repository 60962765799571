import _reduce from 'lodash/reduce';
import _set from 'lodash/set';

import { CUSTOM_STYLE_IDS } from '../../../constants/customStyles.constants';

const ACTION_TYPES = {
  ON_SELECT: 'ON_SELECT',
  ON_DROP: 'ON_DROP',
  ON_INIT: 'ON_INIT',
  ON_WIDGET_CLICK: 'ON_WIDGET_CLICK',
  ON_WIDGET_DELETE: 'ON_WIDGET_DELETE',
  UPDATE_GRID_LAYOUT: 'UPDATE_GRID_LAYOUT',
  ADD_GRID_LAYOUT: 'ADD_GRID_LAYOUT',
  UPDATE_WIDGET_CONFIGURATION: 'UPDATE_WIDGET_CONFIGURATION',
  SET_APPLICATION_VARIABLES: 'SET_APPLICATION_VARIABLES',
  ON_CANCEL: 'ON_CANCEL',
  ON_SUBMIT: 'ON_SUBMIT',
  ON_CONFIRM_DIALOG_SUBMIT: 'ON_CONFIRM_DIALOG_SUBMIT',
  ON_CONFIRM_DIALOG_CANCEL: 'ON_CONFIRM_DIALOG_CANCEL',
  ON_PREVIEW_MODAL_CLOSE: 'ON_PREVIEW_MODAL_CLOSE',
  ON_PREVIEW_MODAL_OPEN: 'ON_PREVIEW_MODAL_OPEN',
};

const WIDGET_TYPES = {
  CONTAINER: 'CONTAINER',
  BUTTON_WIDGET: 'BUTTON_WIDGET',
  TEXT_WIDGET: 'TEXT_WIDGET',
  TAB_WIDGET: 'TAB_WIDGET',
  REPORTING_WIDGET: 'REPORTING_WIDGET',
  TAB_PANE: 'TAB_PANE',
  CHILD_WIDGET: 'CHILD_WIDGET',
  PARENT_WIDGET: 'PARENT_WIDGET',
  RELATED_WIDGET: 'RELATED_WIDGET',
  SIMILAR_WIDGET: 'SIMILARITY_WIDGET',
  STANDARD_WIDGET: 'STANDARD_WIDGET',
};

const PAGE_CONFIGURATION_FIELD_IDS = {
  CONTAINERS: 'containers',
};

const COMPONENT_CONFIG_KEYS = {
  CHILDREN: 'children',
  WIDGET_ID: 'widgetId',
  WIDGET_NAME: 'widgetName',
  PROPERTIES: 'properties',
  WIDGET_TYPE: 'widgetType',
  VIEW_TYPE: 'viewType',
  RELATIVE_FIELD_NAME: 'relativeFieldName',
  ENTITY_NAME: 'entityName',
  PRE_APPLIED_FILTERS: 'preAppliedFilters',
  CUSTOM_STYLES: 'customStyles',
  FIELD_TO_SEARCH_ON: 'fieldToSearchOn',
};

const BUTTON_VIEWS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const VISUAL_BUILDER_CONTEXT_KEYS = {
  CURRENT_USER: 'currentUser',
  APPLICATION_VARIABLES: 'applicationVariables',
  SET_APPLICATION_VARIABLES: 'setApplicationVariables',
};

const WIDGET_POSITIONS = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};

const VIEW_OVERRIDE_FIELD_IDS = {
  SIMILAR_VIEW_DETAILS: 'similarViewDetails',
  FILTER_METADATA_AND_PRE_APPLIED_FILTERS: 'filterMetadataAndPreAppliedFilters',
};

const INITIAL_PAGE_CONFIGURATION = {
  widgets: [],
  eventHandlers: [],
  layouts: [],
  customStyles: [],
};

const COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP = _reduce(
  WIDGET_TYPES,
  (result, value) => {
    switch (value) {
      default: {
        _set(result, [value], [CUSTOM_STYLE_IDS.CONTAINER]);
      }
    }

    return result;
  },
  {},
);

const WIDGET_DEFAULT_PROPERTIES_VALUE = {
  BUTTON_VIEW_TYPE: 'PRIMARY',
  TAB_POSITION: 'TOP',
  TAB_SIZE: 'MEDIUM',
};

export {
  ACTION_TYPES,
  WIDGET_TYPES,
  PAGE_CONFIGURATION_FIELD_IDS,
  COMPONENT_CONFIG_KEYS,
  BUTTON_VIEWS,
  VISUAL_BUILDER_CONTEXT_KEYS,
  WIDGET_POSITIONS,
  VIEW_OVERRIDE_FIELD_IDS,
  INITIAL_PAGE_CONFIGURATION,
  COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP,
  WIDGET_DEFAULT_PROPERTIES_VALUE,
};
