import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _split from 'lodash/split';
import _last from 'lodash/last';
import _has from 'lodash/has';
import _head from 'lodash/head';
import _replace from 'lodash/replace';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import { getVariablesFromTemplate } from '../../helpers/template.helpers';

import { CURRENT_ENTITY_NAMESPACE, ADDRESSEE_NAMESPACE } from '../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS } from '../../constants/actionBuilder.constants';
import { FIELD_IDS as TEMPLATE_BUILDER_FIELD_IDS } from '../../constants/templateBuilder.constants';

const getBasicResolvedValues = (values, recordValues, isResultWantedInMapFormat = false, derivedRecordValue = EMPTY_OBJECT) => {
  const resolvedValues = _reduce(
    values,
    (ans, value, key) => {
      if (_includes(value, CURRENT_ENTITY_NAMESPACE)) {
        const fields = _split(value, '.');
        const field = _last(fields);
        if (_has(recordValues, field)) {
          const resolveValue = recordValues[field];
          if (isResultWantedInMapFormat) {
            _set(ans, key, resolveValue);
          } else {
            ans.push(resolveValue);
          }
        } else if (_has(recordValues, `entity.${field}`)) {
          const resolveValue = recordValues.entity[field];
          if (isResultWantedInMapFormat) {
            _set(ans, key, resolveValue);
          } else {
            ans.push(resolveValue);
          }
        } else if (isResultWantedInMapFormat) {
          _set(ans, key, value);
        } else {
          ans.push(value);
        }
      } else if (_includes(value, ADDRESSEE_NAMESPACE)) {
        const fields = _split(value, '.');
        const field = _last(fields);
        if (_has(derivedRecordValue, field)) {
          const resolveValue = derivedRecordValue[field];
          if (isResultWantedInMapFormat) {
            _set(ans, key, resolveValue);
          } else {
            ans.push(resolveValue);
          }
        } else if (_has(derivedRecordValue, `entity.${field}`)) {
          const resolveValue = derivedRecordValue.entity[field];
          if (isResultWantedInMapFormat) {
            _set(ans, key, resolveValue);
          } else {
            ans.push(resolveValue);
          }
        } else if (isResultWantedInMapFormat) {
          _set(ans, key, value);
        } else {
          ans.push(value);
        }
      } else if (isResultWantedInMapFormat) {
        _set(ans, key, value);
      } else {
        ans.push(value);
      }

      return ans;
    },
    isResultWantedInMapFormat ? {} : [],
  );

  return resolvedValues;
};

const getTemplatePayload = (templateNames) => ({
  filters: [
    { field: TEMPLATE_BUILDER_FIELD_IDS.TEMPLATE_TYPE, filterType: OPERATORS.IN, values: ['EMAIL'] },
    { field: TEMPLATE_BUILDER_FIELD_IDS.NAME, filterType: OPERATORS.IN, values: templateNames },
  ],
});

const convertVariablesOfEmailTemplateToValues = (emailBody, variableToValueMap) => {
  try {
    const templateRegex = /\${(.*?)}/gm;
    const regexIterator = Array.from(emailBody.matchAll(templateRegex));
    let finalValue = emailBody;

    _forEach(regexIterator, (regexMatch) => {
      const stringMatch = _head(regexMatch);
      const stringValue = _last(regexMatch);
      const value = _isEmpty(_get(variableToValueMap, stringValue)) ? `\${${stringValue}}` : _get(variableToValueMap, stringValue);
      finalValue = _replace(finalValue, stringMatch, value);
    });
    return finalValue;
  } catch {
    return emailBody;
  }
};

const getCustomEntitySearchPayload = (ids) => ({ filters: [{ field: 'id', filterType: OPERATORS.IN, values: ids }] });

const resolveEmailTemplateVariables = (
  id,
  templateValue,
  selectedActionDef,
  recordValues,
  additionalVariableValues = EMPTY_OBJECT,
  derivedEntityRecordData = EMPTY_OBJECT,
) => {
  const templates = _get(selectedActionDef, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, EMPTY_OBJECT);
  const variablesValue = { ..._get(templates, id, EMPTY_OBJECT), ...additionalVariableValues };

  const resolvedVariablesValue = getBasicResolvedValues(variablesValue, recordValues, true, derivedEntityRecordData);

  return convertVariablesOfEmailTemplateToValues(templateValue, resolvedVariablesValue);
};

const getTemplateVariables = (selectedTemplateDef, selectedTemplateId, selectedActionDef) => {
  const selectedTemplateSubjectVariables = getVariablesFromTemplate(_get(selectedTemplateDef, 'subject', EMPTY_STRING));
  const selectedTemplateBodyVariables = getVariablesFromTemplate(_get(selectedTemplateDef, 'body', EMPTY_STRING));
  const templateVariableDefPresentInActionDef = _get(
    selectedActionDef,
    `${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES}.${selectedTemplateId}`,
    EMPTY_OBJECT,
  );
  const templateVariables = [...selectedTemplateSubjectVariables, ...selectedTemplateBodyVariables];
  return _reduce(
    templateVariables,
    (result, templateVar) => {
      if (_isEmpty(_get(templateVariableDefPresentInActionDef, templateVar))) {
        result.selectedTemplateEmptyVariables.push(templateVar);
      } else {
        _set(result.selectedTemplateDefinedVariables, templateVar, _get(templateVariableDefPresentInActionDef, templateVar));
      }
      return result;
    },
    { selectedTemplateEmptyVariables: [], selectedTemplateDefinedVariables: {} },
  );
};

export {
  getBasicResolvedValues,
  getTemplatePayload,
  convertVariablesOfEmailTemplateToValues,
  getCustomEntitySearchPayload,
  resolveEmailTemplateVariables,
  getTemplateVariables,
};
