import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { tget } from '@tekion/tekion-base/utils/general';
import FontIcon, { SIZES } from '@tekion/tekion-components/atoms/FontIcon';

import DraggableWidget from '../dragable/DraggableWidget';
import VisualBuilderContext from '../../../../context/visualBuilder.context';

import { WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';

import styles from './widgetComponent.module.scss';

const WidgetComponent = ({ index, key, className, widgetComponentConfig, children }) => {
  const { selectedWidgetComponentId, onSelect, onDelete } = useContext(VisualBuilderContext);
  const type = tget(widgetComponentConfig, 'widgetType', WIDGET_TYPES.CONTAINER);

  const widgetName = tget(widgetComponentConfig, 'widgetName', '');

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(widgetName, index, widgetComponentConfig);
  };

  const handleCaptureSelect = () => {
    onSelect(widgetComponentConfig);
  };

  const renderWidgetComponent = () => (
    <div
      key={key}
      className={cx(styles.widgetComponent, className, {
        [styles.selected]: selectedWidgetComponentId === widgetName,
      })}
      onClickCapture={handleCaptureSelect}
    >
      {children}
      {selectedWidgetComponentId === widgetName && (
        <FontIcon className={styles.btnCross} size={SIZES.S} onClick={handleDelete}>
          icon-trash
        </FontIcon>
      )}
    </div>
  );

  return type !== WIDGET_TYPES.CONTAINER ? (
    <DraggableWidget index={index} type={type} widgetComponentConfig={widgetComponentConfig}>
      {renderWidgetComponent()}
    </DraggableWidget>
  ) : (
    renderWidgetComponent()
  );
};

WidgetComponent.propTypes = {
  className: PropTypes.string,
  key: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  widgetComponentConfig: PropTypes.object.isRequired,
  children: PropTypes.any,
};

WidgetComponent.defaultProps = {
  className: '',
  children: undefined,
};

export default WidgetComponent;
