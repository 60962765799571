import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { tget } from '@tekion/tekion-base/utils/general';

import SelectEntityConfigurator from '../components/selectEntityConfigurator/SelectEntityConfigurator';
import AsyncPaginatedSelect from '../../../../../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';

import { searchPageConfigurations } from '../../../../../../../../../../../actions/visualBuilder.actions';
import { getOptionsForPageField, getPayloadForPageField } from '../../../helpers/propertiesPanel.helpers';

import { FIELD_IDS } from '../constants/tabProperties.constants';

const TAB_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.TAB_DISPLAY_NAME,
  renderer: TextInput,
  renderOptions: {
    required: true,
    label: __('Tab Name'),
    validators: [isRequiredRule],
  },
};

const PAGE_NAME_FIELD = {
  id: FIELD_IDS.PAGE_NAME,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    required: true,
    label: __('page Name'),
    validators: [isRequiredRule],
    serviceHandler: searchPageConfigurations,
    getOptions: getOptionsForPageField,
    getPayload: getPayloadForPageField,
  },
};

const TAB_ICON_FIELD = {
  id: FIELD_IDS.TAB_ICON,
  renderer: TextInput,
  renderOptions: {
    label: __('Tab Icon'),
  },
};

const SELECT_ENTITY_FIELD = {
  id: FIELD_IDS.ENTITIES_RENDERING_INFO,
  renderer: SelectEntityConfigurator,
  renderOptions: {},
};

const FORM_FIELDS = {
  [FIELD_IDS.TAB_DISPLAY_NAME]: TAB_DISPLAY_NAME_FIELD,
  [FIELD_IDS.PAGE_NAME]: PAGE_NAME_FIELD,
  [FIELD_IDS.TAB_ICON]: TAB_ICON_FIELD,
  [FIELD_IDS.ENTITIES_RENDERING_INFO]: SELECT_ENTITY_FIELD,
};

const getFields = (isModalVisible, entityErrorObject, selectedModuleIndex, selectedEntities, configureEntityValues) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.ENTITIES_RENDERING_INFO]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.ENTITIES_RENDERING_INFO], [
    { path: 'isModalVisible', value: isModalVisible },
    { path: 'selectedModuleIndex', value: selectedModuleIndex },
    { path: 'entityErrorObject', value: entityErrorObject },
    { path: 'selectedEntities', value: selectedEntities },
    { path: 'configureEntityValues', value: tget(configureEntityValues, FIELD_IDS.ENTITIES_RENDERING_INFO, [{}]) },
  ]),
});

export { getFields };
