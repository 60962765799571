import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { DEFAULT_RESULTS_PER_PAGE_OPTIONS } from '@tekion/tekion-components/molecules/table/constants/table.constants';

const ACTION_TYPES = {
  FETCH_DATA: 'FETCH_DATA',
  POPULATE_LOOKUP_DATA: 'POPULATE_LOOKUP_DATA',
  ON_VIEW_SELECT: 'ON_VIEW_SELECT',
  SET_COMPLEX_FIELD_COLUMNS_DATA: 'SET_COMPLEX_FIELD_COLUMNS_DATA',
  TABLE_ITEMS_SET_FILTER: 'TABLE_ITEMS_SET_FILTER',
  TABLE_ITEMS_SORT: 'TABLE_ITEMS_SORT',
  ON_EXPORT_CLICK: 'ON_EXPORT_CLICK',
  SET_URL_AND_LOCAL_STORAGE: 'SET_URL_AND_LOCAL_STORAGE',
  INIT: 'INIT',
  ON_DOWNLOAD: 'ON_DOWNLOAD',
  TABLE_ROW_ACTION_CLICK: 'TABLE_ROW_ACTION_CLICK',
  TABLE_ACTION_MENU_CLICK: 'TABLE_ACTION_MENU_CLICK',
  TABLE_ON_EXPAND: 'TABLE_ON_EXPAND',
  TRIGGER_ACTION_BUILDER_ACTION: 'TRIGGER_ACTION_BUILDER_ACTION',
};

const TABLE_MANAGER_PROPS = {
  showHeader: false,
  showSubHeader: true,
  showRefreshIcon: true,
  showFilter: true,
  showSearchByField: true,
  showMultiSort: true,
};

const LIST_VIEW_RENDERER_DEFAULT_PROPS = {
  currentPage: 0,
  pageSize: 50,
  minRows: 0,
  showPagination: true,
  fixedPagination: true,
  showActions: false,
  rowHeight: 50,
  pageSizeOptions: DEFAULT_RESULTS_PER_PAGE_OPTIONS,
};

const ACTION_KEYS = {
  ID: 'id',
  NAME: 'name',
  ACTION_ID: 'actionId',
  DISABLED: 'disabled',
  STATUS: 'status',
  ERROR_MESSAGE: 'errorMessage',
  IS_CUSTOM_ROW_ACTION: 'isCustomRowAction',
  DISPLAY_NAME: 'displayName',
};

const ENTITY = 'ENTITY';

const LIST_VIEW_CONFIG_PROPERTY_KEYS = {
  PRE_APPLIED_FILTERS: 'preAppliedFilters',
  FILTER_META_DATA: 'filterMetadata',
  DOWNLOAD_OPTIONS: 'downloadOptions',
  EXPANDABLE_ROWS_ENABLED: 'expandableRowsEnabled',
  VIEW_RECORD_ACTION_CONFIGS: 'viewRecordActionConfigs',
  VIEW_RECORD_ACTION_TYPE: 'viewRecordActionType',
  ON_CLICK_ACTION_TYPE: 'onClickActionType',
  RELATED_VIEW_PROPS: 'relatedViewProps',
  RELATED_ENTITY: 'relatedEntity',
  RELATED_FIELD: 'relatedField',
  VIEW_NAME: 'viewName',
  SEARCH_OPTIONS: 'searchOptions',
};

const VIEW_RECORD_ACTION_TYPES = {
  CELL_CLICK: 'CELL_CLICK',
  ROW_CLICK: 'ROW_CLICK',
};

const ON_CLICK_ACTION_TYPES = {
  EXPAND: 'EXPAND',
  OPEN_DETAIL_VIEW: 'OPEN_DETAIL_VIEW',
  RIGHT_PANE_VIEW: 'RIGHT_PANE_VIEW',
};

const EXPANDABLE_ROW_PROPERTY_KEYS = {
  RELATED_ENTITY: 'relatedEntity',
  RELATED_FIELD: 'relatedField',
  VIEW_NAME: 'viewName',
};

const EXISTENCE_CHECK_FIELDS = [OPERATORS.EXISTS, OPERATORS.NOT_EXISTS];

const SIMILAR_VIEW_DETAIL_FIELDS = {
  IS_SIMILAR_VIEW_ENABLED: 'isSimilarViewEnabled',
  RECORD_ID: 'recordId',
};

export {
  ENTITY,
  ACTION_KEYS,
  LIST_VIEW_RENDERER_DEFAULT_PROPS,
  ACTION_TYPES,
  TABLE_MANAGER_PROPS,
  LIST_VIEW_CONFIG_PROPERTY_KEYS,
  VIEW_RECORD_ACTION_TYPES,
  ON_CLICK_ACTION_TYPES,
  EXPANDABLE_ROW_PROPERTY_KEYS,
  EXISTENCE_CHECK_FIELDS,
  SIMILAR_VIEW_DETAIL_FIELDS,
};
