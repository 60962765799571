import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import IconAsBtn from '@tekion/tekion-components/atoms/iconAsBtn';
import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import Select from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/select';
import AsyncPaginatedSelect from '../../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';

import { fetchEntities } from '../../../../../../../../actions/entityManagement.actions';
import { searchPageConfigurationsCMS } from '../../../../../../../../actions/visualBuilder.actions';

import { ENTITY_APPLICATION_MAPPING_FIELD_IDS } from '../../../../../../../../constants/entityApplicationMapping.constants';
import { ACTION_TYPES } from '../entityMappingsList/helpers/entityMappingsList.actionHandlers';

import { getEntityOptions, getEntityPayload, getPageOptions, getPagePayload, getSelectedEntities } from './helpers/entityMappingRow.helpers';
import ACTION_HANDLERS from './helpers/entityMappingRow.actionHandlers';

import styles from './entityMappingRow.module.scss';

const EntityMappingRow = (props) => {
  const entityFieldRef = useRef(null);
  // Todo: After page migration
  // const pageFieldRef = useRef(null);
  const { index, value, error, rows, tabOptions } = props;
  const { message } = error;

  const entityFieldValue = tget(value, ENTITY_APPLICATION_MAPPING_FIELD_IDS.ENTITY_NAME);
  const entityFieldError = tget(error, ENTITY_APPLICATION_MAPPING_FIELD_IDS.ENTITY_NAME);

  const tabFieldValue = tget(value, ENTITY_APPLICATION_MAPPING_FIELD_IDS.TAB_NAME);
  const tabFieldError = tget(error, ENTITY_APPLICATION_MAPPING_FIELD_IDS.TAB_NAME);

  const pageFieldValue = tget(value, ENTITY_APPLICATION_MAPPING_FIELD_IDS.PAGE_NAME);
  const pageFieldError = tget(error, ENTITY_APPLICATION_MAPPING_FIELD_IDS.PAGE_NAME);

  const selectedEntities = getSelectedEntities(rows, index);

  const handleAction = (action = EMPTY_OBJECT) => {
    const { type, payload } = action;
    const handler = ACTION_HANDLERS[type];

    if (_isFunction(handler)) {
      handler({ props, params: payload });
    }
  };

  const handleDelete = () => {
    const handler = ACTION_HANDLERS[ACTION_TYPES.DELETE_ROW];

    if (_isFunction(handler)) {
      handler({ props });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEntityPayloadWrapped = getEntityPayload(selectedEntities);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPagePayloadWrapped = useCallback(getPagePayload(), []);
  // const getPagePayloadWrapped = useCallback(getPagePayload(entityFieldValue), [entityFieldValue]);

  useEffect(() => {
    const resetLoadedOptionsForEntitySelect = tget(entityFieldRef, 'current.resetLoadedOptions', _noop);
    resetLoadedOptionsForEntitySelect();
    // Todo: Enable following after page migration.
    // const resetLoadedOptionsForPageSelect = tget(pageFieldRef, 'current.resetLoadedOptions', _noop);
    // resetLoadedOptionsForPageSelect();
  }, [entityFieldValue]);

  return (
    <div>
      <div className="flex">
        <AsyncPaginatedSelect
          ref={entityFieldRef}
          required
          id={ENTITY_APPLICATION_MAPPING_FIELD_IDS.ENTITY_NAME}
          label={__('Entity')}
          value={entityFieldValue}
          error={entityFieldError}
          serviceHandler={fetchEntities}
          getOptions={getEntityOptions}
          getPayload={getEntityPayloadWrapped}
          onAction={handleAction}
        />
        {!_isEmpty(tabOptions) && (
          <Select
            fieldClassName={`${fieldLayoutStyles.fieldC} ${styles.select}`}
            selectWrapperClassName={styles.selectWrapper}
            required
            isDisabled={_isEmpty(entityFieldValue)}
            id={ENTITY_APPLICATION_MAPPING_FIELD_IDS.TAB_NAME}
            label={__('Tab')}
            value={tabFieldValue}
            error={tabFieldError}
            options={tabOptions}
            onAction={handleAction}
          />
        )}
        <AsyncPaginatedSelect
          // ref={pageFieldRef}
          required
          isDisabled={_isEmpty(entityFieldValue)}
          id={ENTITY_APPLICATION_MAPPING_FIELD_IDS.PAGE_NAME}
          label={__('Page')}
          value={pageFieldValue}
          error={pageFieldError}
          serviceHandler={searchPageConfigurationsCMS}
          getOptions={getPageOptions}
          getPayload={getPagePayloadWrapped}
          onAction={handleAction}
        />
        <IconAsBtn className={styles.deleteBtn} onClick={handleDelete}>
          icon-trash
        </IconAsBtn>
      </div>
      <Error className={styles.error} error={message} />
    </div>
  );
};

EntityMappingRow.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.object,
  error: PropTypes.object,
  rows: PropTypes.array,
  tabOptions: PropTypes.array,
};

EntityMappingRow.defaultProps = {
  value: EMPTY_OBJECT,
  error: EMPTY_OBJECT,
  rows: EMPTY_ARRAY,
  tabOptions: EMPTY_ARRAY,
};

export default EntityMappingRow;
