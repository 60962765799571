exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tabPaneContainer_container__cBMjh1Fv7X{display:flex;flex-direction:column;flex-grow:1;overflow:scroll}.tabPaneContainer_container__cBMjh1Fv7X.tabPaneContainer_builderMode__2DuUNWhp8e{height:100%;width:100%}[dir] .tabPaneContainer_container__cBMjh1Fv7X.tabPaneContainer_builderMode__2DuUNWhp8e{border:.2rem solid #969aa3;border-radius:2rem}.tabPaneContainer_container__cBMjh1Fv7X.tabPaneContainer_previewMode__puyRBz1AaQ{width:100%}[dir] .tabPaneContainer_container__cBMjh1Fv7X.tabPaneContainer_previewMode__puyRBz1AaQ{margin-bottom:0}[dir] .tabPaneContainer_container__cBMjh1Fv7X.tabPaneContainer_previewMode__puyRBz1AaQ:last-child{margin-bottom:0}.tabPaneContainer_widgetContainer__sxK5KHU9Zr{display:flex;flex-direction:column}", ""]);

// Exports
exports.locals = {
	"container": "tabPaneContainer_container__cBMjh1Fv7X",
	"builderMode": "tabPaneContainer_builderMode__2DuUNWhp8e",
	"previewMode": "tabPaneContainer_previewMode__puyRBz1AaQ",
	"widgetContainer": "tabPaneContainer_widgetContainer__sxK5KHU9Zr"
};