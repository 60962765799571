import _isEmpty from 'lodash/isEmpty';

import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';

import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import AsyncPaginatedSelect from '../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';
import EntityAsyncSelect from '../../../../../../../pages/devPlatform/molecules/EntityAsyncSelect/EntityAsyncSelect';

import { searchEntityViewConfigurations } from '../../../../../../../actions/entityViewDefinitions.actions';

import { getOptions, getPayload, getViewTypeOptions } from './entityAndViewConfigure.helpers';

import { ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS } from '../constants/entityAndViewConfigure.fieldIds';
import { WIDGET_TYPES } from '../../../../../constants/visualBuilder.general.constants';

import styles from '../entityAndViewConfigure.module.scss';

const ENTITY_NAME_FIELD = {
  id: ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME,
  renderer: EntityAsyncSelect,
  renderOptions: {
    label: __('Entity Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Entity Name'),
  },
};

const VIEW_FIELD = {
  id: ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    fieldClassName: styles.asyncPaginatedDropDown,
    label: __('Entity View Name'),
    validators: [isRequiredRule],
    placeholder: __('Enter Widget Name'),
    serviceHandler: searchEntityViewConfigurations,
    getOptions,
  },
};

const VIEW_TYPE_FIELD = {
  id: ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE,
  renderer: SelectInput,
  renderOptions: {
    label: __('View Type'),
    validators: [isRequiredRule],
    placeholder: __('Select View type'),
  },
};

const RELATIVE_FIELD_NAME_FIELD = {
  id: ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME,
  renderer: SelectInput,
  renderOptions: {
    label: __('Relative Field'),
    validators: [isRequiredRule],
    placeholder: __('Select Relative Field'),
  },
};

const getEntityAndViewConfigureFields = (
  ref,
  entityName,
  widgetType,
  viewType,
  entityAsyncSelectFilter,
  filteredRelativeFieldsNameOptions,
  pageType,
) => ({
  [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.ENTITY_NAME]: !_isEmpty(entityAsyncSelectFilter)
    ? addToRenderOptions(ENTITY_NAME_FIELD, [{ path: 'filters', value: entityAsyncSelectFilter }])
    : ENTITY_NAME_FIELD,
  [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW]: addToRenderOptions(VIEW_FIELD, [
    { path: 'ref', value: ref },
    { path: 'getPayload', value: getPayload(entityName, viewType) },
  ]),
  [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.VIEW_TYPE]: addToRenderOptions(VIEW_TYPE_FIELD, [
    {
      path: 'options',
      value: [...getViewTypeOptions(widgetType, pageType)],
    },
  ]),
  [ENTITY_AND_VIEW_CONFIGURE_FIELD_IDS.RELATIVE_FIELD_NAME]: addToRenderOptions(RELATIVE_FIELD_NAME_FIELD, [
    {
      path: 'options',
      value: [...filteredRelativeFieldsNameOptions],
    },
    {
      path: 'label',
      value: widgetType === WIDGET_TYPES.SIMILAR_WIDGET ? __('Field To Search On') : __('Relative Field'),
    },
  ]),
});

export { getEntityAndViewConfigureFields };
