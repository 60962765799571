import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _pickBy from 'lodash/pickBy';
import _reduce from 'lodash/reduce';
import _split from 'lodash/split';
import _keyBy from 'lodash/keyBy';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import _noop from 'lodash/noop';
import _filter from 'lodash/filter';
import _castArray from 'lodash/castArray';
import _slice from 'lodash/slice';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { tget, uuid } from '@tekion/tekion-base/utils/general';
import FormEvent from '@tekion/tekion-base/utils/formEvent';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import NullComponent from '@tekion/tekion-components/atoms/nullComponent';
import SIZES from '@tekion/tekion-components/molecules/Modal/constants/modal.sizes';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';
import { getCurrentTime, getUnix } from '@tekion/tekion-base/utils/dateUtils';

import SendEmailModalContentRenderer from '../sendEmailModalContentRenderer/SendEmailModalContentRenderer';
import EntityViewViewer from '../../organisms/viewBuilder/organisms/entityViewViewer/EntityViewViewer';

import { searchViewConfigurations } from '../../actions/viewBuilderPage.actions';
import { fetchEntityViewConfigurationByName } from '../../actions/entityViewDefinitions.actions';
import { fetchEntityRecords } from '../../actions/recordManagement.actions';
import { fetchEntities, fetchEntityDefByName } from '../../actions/entityManagement.actions';
import { fetchRecordForUpdateActionType } from '../../actions/actionBuilder.actions';

import { getSearchPayloadsAndMapForNestedEntities, getSelectedInputType } from '../../pages/devPlatformExperience/helpers/actionBuilder.helpers';
import { getFormViewConfiguration } from '../../organisms/viewBuilder/helpers/viewTypeConfiguration.helper';
import { resolveVariableDataFromRecordData } from '../../helpers/general.helpers';
import { getUuid } from '../../utils';

import { ACTION_EXECUTION_MODAL_TYPES, ACTION_TYPES, ACTION_EXECUTION_MODAL_EVENT_TYPES } from './actionExecutionModal.constants';
import { ACTION_DEFINITION_ACTION_TYPES, ACTION_DEFINITION_FIELD_IDS, USER_INPUT_TYPES } from '../../constants/actionBuilder.constants';
import { NAMESPACES } from '../../constants/general.constants';
import DATA_TYPES from '../../constants/fieldDefinition.dataTypes';
import {
  ALL_VIEW_PROPERTY_KEYS,
  CELL_TYPES,
  VIEW_CONFIGURATION_FIELD_IDS,
  VIEW_CONFIG_TYPES,
  VIEW_TYPES,
} from '../../constants/viewBuilder.constants';
import FIELD_TYPES from '../../constants/fieldDefinition.fieldTypes';
import SYSTEM_DEFINED_FIELDS from '../../constants/systemDefinedFields';

import entityReader from '../../readers/entity.reader';
import fieldDefinitionReader from '../../readers/fieldDefinition.reader';

const getPayloadToGetRecordTypeView = (entityName) => ({
  filters: [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      filterType: OPERATORS.IN,
      values: _castArray(entityName),
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      filterType: OPERATORS.IN,
      values: [VIEW_TYPES.RECORD_TYPE_SELECTION_VIEW],
    },
  ],
});

const getComponentRendererFromModalType = (modalType) => {
  switch (modalType) {
    case ACTION_EXECUTION_MODAL_TYPES.USER_INPUT: {
      return EntityViewViewer;
    }

    case ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT: {
      return SendEmailModalContentRenderer;
    }

    default: {
      return NullComponent;
    }
  }
};

const getModalTypeFromActionType = (actionType) => {
  if (actionType === ACTION_DEFINITION_ACTION_TYPES.SEND_EMAILS) {
    return ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT;
  }

  return ACTION_EXECUTION_MODAL_TYPES.USER_INPUT;
};

const getActionInputData = async (prefilledData, params, entityDef) => {
  const promisesForVariableResolving = [];
  const targetFieldNameVariableNames = [];

  const actionResolvedWithApiCallData = _reduce(
    prefilledData,
    (draftInput, valueToPopulate, targetFieldName) => {
      if (_includes(valueToPopulate, NAMESPACES.CURRENT_RECORD)) {
        const parsedResourceValue = _split(valueToPopulate, '.');
        const parsedResourceValueWithOutCurrentRecordNameSpace = _slice(parsedResourceValue, 1);

        promisesForVariableResolving.push(resolveVariableDataFromRecordData(parsedResourceValueWithOutCurrentRecordNameSpace, 0, params, entityDef));

        targetFieldNameVariableNames.push(targetFieldName);

        return draftInput;
      } else if (valueToPopulate === NAMESPACES.SYSTEM_TIME || valueToPopulate === NAMESPACES.SYSTEM_DATE) {
        return { ...draftInput, [targetFieldName]: getUnix(getCurrentTime()) };
      } else {
        return {
          ...draftInput,
          [targetFieldName]: valueToPopulate,
        };
      }
    },
    {},
  );

  const resolvedValues = await Promise.all(promisesForVariableResolving);

  const finalResolvedActionValue = _reduce(
    resolvedValues,
    (result, resolvedValue, index) => {
      _set(result, targetFieldNameVariableNames[index], resolvedValue || '');

      return result;
    },
    { ...actionResolvedWithApiCallData },
  );

  return finalResolvedActionValue;
};

const getUpdatedFieldDefinitions = (entity, complexEntity) => {
  const complexEntitiesByName = _keyBy(complexEntity, 'name');

  const updatedFieldDefinitions = _map(entity?.fieldDefinitions, (field) => {
    const dataType = fieldDefinitionReader.dataType(field);

    if (dataType === DATA_TYPES.COMPLEX) {
      const lookUpEntity = fieldDefinitionReader.complexFieldDefinitionEntityName(field);
      const complexEntityFieldDefinitions = _get(complexEntitiesByName, `${lookUpEntity}.fieldDefinitions`);
      return {
        ...field,
        complexEntityFieldDefinitions: {
          ..._keyBy(complexEntityFieldDefinitions, 'name'),
        },
      };
    }

    return field;
  });

  return updatedFieldDefinitions;
};

const getComplexViewConfigMapper = (complexViewConfigResponse, complexFieldNameViewConfigMapper) => {
  const viewConfigByNames = _keyBy(tget(complexViewConfigResponse, 'hits', EMPTY_ARRAY), VIEW_CONFIGURATION_FIELD_IDS.NAME);
  const viewConfigByFieldName = {};
  _forEach(complexFieldNameViewConfigMapper, (viewName, fieldName) => {
    _set(viewConfigByFieldName, fieldName, _get(viewConfigByNames, viewName));
  });

  return viewConfigByFieldName;
};

const getUserInputModalData = (
  complexEntity,
  complexViewConfigResponse,
  complexFieldNameViewNameMap,
  targetEntityDef,
  viewConfiguration,
  targetRecordData,
  actionInputRecord,
  userInputOfValidationOverride = {},
) => {
  // adding complex and relationship lookup entity's field def in  original field
  const updatedFieldDefinitions = getUpdatedFieldDefinitions(targetEntityDef, tget(complexEntity, 'hits', EMPTY_ARRAY));

  // mapper with {complexFieldName:  complexField's lookup entity's standard form view}
  const complexViewConfigMapper = getComplexViewConfigMapper(complexViewConfigResponse, complexFieldNameViewNameMap);

  const targetEntity = { ...targetEntityDef, fieldDefinitions: updatedFieldDefinitions };
  const entityRecord = { ...targetRecordData, ...actionInputRecord, ...userInputOfValidationOverride };

  return {
    entity: targetEntity,
    entityDef: targetEntity,
    complexViewConfiguration: complexViewConfigMapper,
    entityRecord,
    entityViewConfiguration: viewConfiguration,
  };
};

const getSearchViewPayload = (entityName) => ({
  filters: [
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.ENTITY_NAME,
      values: entityName,
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_CONFIG_TYPE,
      values: [VIEW_CONFIG_TYPES.STANDARD],
      filterType: OPERATORS.IN,
    },
    {
      field: VIEW_CONFIGURATION_FIELD_IDS.VIEW_TYPE,
      values: [VIEW_TYPES.FORM_VIEW],
      filterType: OPERATORS.IN,
    },
  ],
});

const getFormViewConfigurationFromFields = async (entityDef, selectedFieldsForInput) => {
  const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
  const fieldDefinitionsByName = _keyBy(fieldDefinitions, 'name');

  const viewSummary = {
    viewType: [VIEW_TYPES.FORM_VIEW],
  };

  const complexEntities = _reduce(
    selectedFieldsForInput,
    (result, field) => {
      const fieldName = _get(field, 'fieldName');
      const fieldDef = fieldDefinitionsByName[fieldName];
      const dataType = fieldDefinitionReader.dataType(fieldDef);
      if (dataType === DATA_TYPES.COMPLEX) {
        const complexEntityName = fieldDefinitionReader.complexFieldDefinitionEntityName(fieldDef);
        result.push(complexEntityName);
      }
      return result;
    },
    [],
  );

  let complexEntitiesResponse = [];
  if (!_isEmpty(complexEntities)) {
    complexEntitiesResponse = await searchViewConfigurations(getSearchViewPayload(complexEntities));
  }
  const complexEntitiesView = tget(complexEntitiesResponse, 'hits', EMPTY_ARRAY);
  const complexEntitiesViewByName = _keyBy(complexEntitiesView, 'entityName');

  const columns = _map(selectedFieldsForInput, (field) => {
    const fieldName = _get(field, 'fieldName');
    const fieldDef = fieldDefinitionsByName[fieldName];

    const fieldType = fieldDefinitionReader.fieldType(fieldDef);
    const dataType = fieldDefinitionReader.dataType(fieldDef);

    const viewDefMetaData = {
      cellType: CELL_TYPES.SIMPLE,
      title: fieldDefinitionReader.displayName(fieldDef),
      fieldNames: [fieldDefinitionReader.name(fieldDef)],
      template: `\${${fieldDefinitionReader.name(fieldDef)}}`,
      mandatory: fieldDefinitionReader.mandatory(fieldDef),
      editable: fieldDefinitionReader.editable(fieldDef),
      disabled: fieldDefinitionReader.disabled(fieldDef),
    };

    if (fieldType === FIELD_TYPES.RELATIONSHIP) {
      _set(viewDefMetaData, 'lookupFieldNames', [fieldDefinitionReader.lookupFieldDisplayField(fieldDef)]);
    }

    if (dataType === DATA_TYPES.COMPLEX) {
      const complexFieldEntityName = fieldDefinitionReader.complexFieldDefinitionEntityName(fieldDef);
      _set(
        viewDefMetaData,
        ALL_VIEW_PROPERTY_KEYS.COMPLEX_VIEW_NAME,
        _get(complexEntitiesViewByName[complexFieldEntityName], VIEW_CONFIGURATION_FIELD_IDS.NAME),
      );
    }

    return viewDefMetaData;
  });

  _set(viewSummary, 'columns', columns);

  return getFormViewConfiguration(viewSummary, entityDef);
};

const getViewConfigByNameForEachRecordTypeFromFields = async (entityDef, selectedFields, recordTypesDef) => {
  const recordTypesViewConfigsFromFields = await Promise.all(
    _map(recordTypesDef, (recordTypeDef) => {
      const recordTypeAvailableFields = tget(recordTypeDef, 'availableFields', EMPTY_ARRAY);
      const allFieldsAvailable = tget(recordTypeDef, 'allFieldsAvailable', false);
      return getFormViewConfigurationFromFields(
        entityDef,
        allFieldsAvailable
          ? selectedFields
          : _filter(selectedFields, (selectedField) => _includes(recordTypeAvailableFields, _get(selectedField, 'fieldName'))),
      );
    }),
  );

  const viewConfigsByName = {};

  const recordTypeToViewConfigName = _reduce(
    recordTypesViewConfigsFromFields,
    (result, recordTypeViewConfigFromFields, index) => {
      const viewName = getUuid();

      _set(recordTypeViewConfigFromFields, VIEW_CONFIGURATION_FIELD_IDS.NAME, viewName);
      _set(result, _get(recordTypesDef, `${index}.name`), viewName);
      _set(viewConfigsByName, viewName, recordTypeViewConfigFromFields);

      return result;
    },
    {},
  );

  return { recordTypeToViewConfigName, viewConfigsByName };
};

const getEntityViewFromFields = (entityName, recordTypeSelectViewConfigName, recordTypeToViewConfigName) => ({
  id: getUuid(),
  projectId: null,
  applicationId: null,
  entityName,
  schemaId: '1',
  name: 'entity_view_rom_fields',
  displayName: 'Static entity view config from fields',
  viewType: VIEW_TYPES.FORM_VIEW,
  recordTypeSelectionViewName: recordTypeSelectViewConfigName,
  viewRecordTypeMetadataList: _map(recordTypeToViewConfigName, (value, key) => ({ recordTypeName: key, viewName: value })),
});

const getPropsForModalTypeUserInput = async (recordId, actionName, entityDef, actionDef, recordData) => {
  const contextId = uuid();
  const propsDefaultData = { isPreviewMode: true, fetchEntityRecords, contextId };
  const actionType = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_TYPE);
  const prefilledData = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.ACTION_DEFINITION, EMPTY_OBJECT);
  const entityName = _get(entityDef, 'name');

  const actionInputRecord = await getActionInputData(prefilledData, recordData, entityDef);

  let targetRecordData = {};
  if (actionType === ACTION_DEFINITION_ACTION_TYPES.UPDATE_RECORD) {
    targetRecordData = await fetchRecordForUpdateActionType(actionName, recordId);
  }

  if (!_isEmpty(actionDef)) {
    const targetEntityName = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.TARGET_ENTITY_NAME);
    let targetEntityDef = {};
    if (targetEntityName === entityName) {
      targetEntityDef = entityDef;
    } else if (!_isEmpty(targetEntityName)) {
      targetEntityDef = await fetchEntityDefByName(targetEntityName);
    }

    const actionInputFormViewName = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.FORM_VIEW_NAME, EMPTY_STRING);
    const selectedFieldsForInput = _get(actionDef, ACTION_DEFINITION_FIELD_IDS.SELECTED_FIELDS, EMPTY_ARRAY);
    const selectedInputType = getSelectedInputType(actionInputFormViewName, selectedFieldsForInput);

    let inputFormViewConfiguration = {};

    switch (selectedInputType) {
      case USER_INPUT_TYPES.FORM: {
        inputFormViewConfiguration = await fetchEntityViewConfigurationByName(targetEntityName, actionInputFormViewName);
        const targetEntityFieldDefByName = _keyBy(entityReader.fieldDefinitions(targetEntityDef), 'name');
        targetRecordData = _pickBy({ ...targetRecordData, ...targetRecordData.entity }, (value, key) => {
          const fieldDef = _get(targetEntityFieldDefByName, key, EMPTY_OBJECT);
          return fieldDefinitionReader.editable(fieldDef) || fieldDefinitionReader.name(fieldDef) === SYSTEM_DEFINED_FIELDS.ID;
        });
        break;
      }
      case USER_INPUT_TYPES.FIELD: {
        const recordTypeSelectFieldViewConfigResponse = await searchViewConfigurations(getPayloadToGetRecordTypeView(targetEntityName));
        const recordTypeSelectFieldViewConfig = getArraySafeValue(tget(recordTypeSelectFieldViewConfigResponse, 'hits', [{}]));
        const recordTypeSelectViewConfigName = _get(recordTypeSelectFieldViewConfig, VIEW_CONFIGURATION_FIELD_IDS.NAME);

        const defaultRecordType = entityReader.defaultRecordType(targetEntityDef);
        const recordTypes = entityReader.recordTypeDefinitionRecordTypes(targetEntityDef);

        const { recordTypeToViewConfigName, viewConfigsByName } = await getViewConfigByNameForEachRecordTypeFromFields(
          targetEntityDef,
          selectedFieldsForInput,
          recordTypes,
        );

        _set(recordTypeToViewConfigName, defaultRecordType, recordTypeSelectViewConfigName);
        _set(viewConfigsByName, recordTypeSelectViewConfigName, recordTypeSelectFieldViewConfig);

        inputFormViewConfiguration = getEntityViewFromFields(targetEntityDef, recordTypeSelectViewConfigName, recordTypeToViewConfigName);

        const selectedFields = _map(selectedFieldsForInput, 'fieldName');
        targetRecordData = _pickBy({ ...targetRecordData, ...targetRecordData.entity }, (value, fieldId) => _includes(selectedFields, fieldId));

        _set(propsDefaultData, 'initialViewConfigByName', viewConfigsByName);
        break;
      }
      default: {
        break;
      }
    }

    const { entityDefsSearchPayload, viewConfigsSearchPayload, complexFieldNameViewNameMap } = getSearchPayloadsAndMapForNestedEntities(
      targetEntityDef,
      inputFormViewConfiguration,
    );

    const [complexEntity, complexViewConfigResponse] = await Promise.all([
      fetchEntities(entityDefsSearchPayload, true),
      searchViewConfigurations(viewConfigsSearchPayload),
    ]);

    const userInputModalData = getUserInputModalData(
      complexEntity,
      complexViewConfigResponse,
      complexFieldNameViewNameMap,
      targetEntityDef,
      inputFormViewConfiguration,
      targetRecordData,
      actionInputRecord,
    );

    return { ...userInputModalData, ...propsDefaultData };
  }

  return { ...propsDefaultData };
};

const getPropsForRenderedComponent = async (modalType, recordId, actionName, entityDef, actionDef, recordData) => {
  let propsForRenderedComponent = {};

  if (modalType === ACTION_EXECUTION_MODAL_TYPES.USER_INPUT) {
    propsForRenderedComponent = await getPropsForModalTypeUserInput(recordId, actionName, entityDef, actionDef, recordData);
  } else if (modalType === ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT) {
    propsForRenderedComponent = { rowData: recordData, selectedActionDef: actionDef, entityDef, isEmailPreviewEnabled: true };
  }

  return propsForRenderedComponent;
};

const mapOfModalTypeToSubmitHandler = (modalType, additionalData) => {
  switch (modalType) {
    case ACTION_EXECUTION_MODAL_TYPES.USER_INPUT: {
      return () => {
        const { contextId } = additionalData;
        triggerSubmit(contextId);
      };
    }

    case ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT: {
      return () => {
        const { onAction } = additionalData;
        onAction({ type: ACTION_TYPES.ON_MODAL_SUBMIT });
      };
    }

    default: {
      return _noop;
    }
  }
};

const getModalPropsFromModalType = (modalType, additionalData) => {
  const modalProps = {
    width: SIZES.L,
    title: __('Input Details'),
    submitBtnText: __('Save'),
  };

  _set(modalProps, 'onSubmit', mapOfModalTypeToSubmitHandler(modalType, additionalData));

  if (modalType === ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT) {
    _set(modalProps, 'width', SIZES.XXL);
    _set(modalProps, 'submitBtnText', __('Send'));
  }

  return modalProps;
};

const getAdditionalPropForRenderedComponent = (modalType, onAction) => {
  const additionalProps = {};

  if (modalType === ACTION_EXECUTION_MODAL_TYPES.USER_INPUT) {
    const handleFormSubmit = (payload) => {
      onAction({ type: ACTION_TYPES.ON_MODAL_SUBMIT, payload });
    };
    _set(additionalProps, 'onFormSubmit', handleFormSubmit);
  } else if (modalType === ACTION_EXECUTION_MODAL_TYPES.BULK_EMAIL_USER_INPUT) {
    _set(additionalProps, 'onModalAction', onAction);
  }

  return additionalProps;
};

const triggerOnActionModalInit = (contextId, additional) => {
  FormEvent.emit(`${ACTION_EXECUTION_MODAL_EVENT_TYPES.ON_MODAL_INIT}:${contextId}`, additional);
};

export {
  getComponentRendererFromModalType,
  getModalTypeFromActionType,
  getPropsForRenderedComponent,
  getModalPropsFromModalType,
  getAdditionalPropForRenderedComponent,
  getActionInputData,
  getFormViewConfigurationFromFields,
  getUserInputModalData,
  triggerOnActionModalInit,
};
