import React, { createRef, useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _size from 'lodash/size';
import _values from 'lodash/values';
import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import DropZone from '../dropZone/DropZone';
import WidgetComponent from '../widgetComponent/WidgetComponent';

import { getWidgetReference } from '../../helpers/visualBuilder.helper';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import { ACTION_TYPES } from '../../organisms/pageViewer/pageViewer.constants';
import {
  COMPONENT_CONFIG_KEYS,
  COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP,
  WIDGET_POSITIONS,
  WIDGET_TYPES,
} from '../../constants/visualBuilder.general.constants';
import { CONTAINER_PROPERTIES } from '../../constants/visualBuilder.properties.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

import styles from './container.module.scss';

const SUPPORTED_CUSTOM_STYLE_FOR_CONTAINER = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.CONTAINER];

const Container = (props) => {
  const { isPreviewMode, componentConfig, pageViewerOnAction, visualBuilderOnAction, ...restProps } = props;

  const scrollEnabled = useMemo(() => _get(componentConfig, 'properties.scrollEnabled', false), [componentConfig]);

  const handleLayoutChange = useCallback(
    (height) => {
      pageViewerOnAction({
        type: ACTION_TYPES.LAYOUT_CHANGE,
        payload: {
          value: height,
          widgetName: tget(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING),
        },
      });
    },
    [componentConfig, pageViewerOnAction],
  );

  const containerRef = createRef();

  const components = tget(componentConfig, COMPONENT_CONFIG_KEYS.CHILDREN, EMPTY_ARRAY);
  const widgetName = tget(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING);
  const widgetPosition = tget(
    componentConfig,
    `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${CONTAINER_PROPERTIES.WIDGET_POSITION}`,
    WIDGET_POSITIONS.VERTICAL,
  );
  const customStyles = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${COMPONENT_CONFIG_KEYS.CUSTOM_STYLES}`, {});
  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_FOR_CONTAINER, customStyles),
    [customStyles],
  );
  const containerStyles = tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, {});

  const renderContainerInBuilderMode = () => (
    <div
      ref={containerRef}
      key={widgetName}
      style={containerStyles}
      className={cx(styles.builderMode, styles.widgetContainer, {
        [styles.horizontalGrid]: widgetPosition === WIDGET_POSITIONS.HORIZONTAL,
        [styles.verticalGrid]: widgetPosition === WIDGET_POSITIONS.VERTICAL,
      })}
    >
      {_map(components, (widgetConfig, index) => {
        const { widgetType, componentRef, properties } = widgetConfig;
        const viewType = tget(properties, 'viewType', '');
        const ComponentRenderer = componentRef || getWidgetReference({ componentType: widgetType, isPreviewMode: true, viewType });

        return (
          <>
            <DropZone id={widgetName} key={widgetName} index={index} acceptedComponentTypes={_values(WIDGET_TYPES)} />
            <WidgetComponent key={widgetName} index={index} widgetComponentConfig={widgetConfig}>
              <ComponentRenderer
                isPreviewMode={isPreviewMode}
                key={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
                widgetName={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
                widgetType={widgetType}
                componentConfig={widgetConfig}
                visualBuilderOnAction={visualBuilderOnAction}
                {...restProps}
              />
            </WidgetComponent>
          </>
        );
      })}
      <DropZone id={widgetName} index={_size(components)} acceptedComponentTypes={_values(WIDGET_TYPES)} />
    </div>
  );

  const renderContainerInPreviewMode = () => (
    <div
      ref={containerRef}
      style={containerStyles}
      className={cx(styles.previewMode, styles.widgetContainer, {
        [styles.horizontalGrid]: widgetPosition === WIDGET_POSITIONS.HORIZONTAL,
        [styles.verticalGrid]: widgetPosition === WIDGET_POSITIONS.VERTICAL,
        [styles.scrollEnabled]: scrollEnabled,
      })}
    >
      {_map(components, (widgetConfig) => {
        const { widgetType, componentRef, properties } = widgetConfig;
        const viewType = tget(properties, 'viewType', '');
        const ComponentRenderer = componentRef || getWidgetReference({ componentType: widgetType, isPreviewMode: true, viewType });

        return (
          <ComponentRenderer
            isPreviewMode={isPreviewMode}
            key={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
            widgetName={tget(widgetConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_STRING)}
            widgetType={widgetType}
            componentConfig={widgetConfig}
            visualBuilderOnAction={visualBuilderOnAction}
            {...restProps}
          />
        );
      })}
    </div>
  );

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        if (entries) {
          const { height } = entries[0].contentRect;
          if (!scrollEnabled) {
            handleLayoutChange(height);
          }
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleLayoutChange, containerRef, scrollEnabled]);

  return isPreviewMode ? renderContainerInPreviewMode() : renderContainerInBuilderMode();
};

Container.propTypes = {
  isPreviewMode: PropTypes.bool,
  componentConfig: PropTypes.object.isRequired,
  pageViewerOnAction: PropTypes.func.isRequired,
};

Container.defaultProps = {
  isPreviewMode: false,
};

export default Container;
