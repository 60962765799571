import React, { useCallback, useState, useMemo, useEffect } from 'react';
import ReactSelect from 'react-select';
import { compose } from 'recompose';
import cx from 'classnames';
import PropTypes from 'prop-types';

import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _isNil from 'lodash/isNil';
import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _isObject from 'lodash/isObject';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget, uuid } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import withFetchAsyncResource from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncResource/withFetchAsyncResource';
import WithAsyncSelectHOC from '@tekion/tekion-components/molecules/advancedSelect/containers/withAsyncSelect';

import Error from '@tekion/tekion-components/organisms/FormBuilder/components/error';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import { VirtualizedMultiAsync } from '@tekion/tekion-components/molecules/virtualizedMultiSelect';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { toaster, TOASTER_TYPE } from '@tekion/tekion-components/organisms/NotificationWrapper';

import ClearIndicator from './ClearIndicator';
import CreateNewRecordModal from './createNewRecordModal';
import CustomCodeEventEmitter from '../../eventEmitters/customCodeEventEmitter';

import { fetchEntityRecords } from '../../actions/recordManagement.actions';
import Records from '../../actions/customCodeApis/customCode.RecordApi';
import {
  getHasMore,
  getLoadingMessage,
  getOptions as defaultGetOptions,
  getPayload,
  getPayloadForInit,
  getResourceParams,
} from './relationshipField.helper';
import { executeEventFromEventViewConfigData } from '../../utils/eventHandlers';
import { triggerCustomCodeEvent } from '../../eventEmitters/helpers/customCodeEventEmitter.helpers';

import { RESOURCE_TYPE, RELOAD_OPTIONS_DELAY, CREATE_STATIC_OPTIONS, CREATE_NEW_OPTION } from './relationshipField.constant';
import { EVENT_NAMES } from '../../constants/eventActions.constants';
import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS } from '../../constants/eventHandlers.constants';

import styles from './relationship.module.scss';

const AsyncSelect = compose(withFetchAsyncResource, WithAsyncSelectHOC)(ReactSelect);

const RelationshipField = (props) => {
  const {
    isClearable,
    isMulti,
    isCreateNewRecordEnabled,
    isDisplayNameResolvedAccordingToTemplate,
    value,
    dropDownClassName,
    additional,
    onAction,
    id,
    label,
    lookUpEntityName,
    lookUpDisplayNames,
    lookUpFieldName,
    lookUpEntityViewName,
    template,
    fieldClassName,
    error,
    mapOfFormFieldNameToEditable,
    eventHandlers,
    customStyles,
    getOptions,
    getFieldValue,
    ...restProps
  } = props;

  const [nextPageToken, setNextPageToken] = useState(EMPTY_STRING);
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const [key, setKey] = useState(id);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateNewModalVisible, setIsCreateNewModalVisible] = useState(false);

  const staticOptions = useMemo(() => (isCreateNewRecordEnabled ? CREATE_STATIC_OPTIONS : EMPTY_ARRAY), [isCreateNewRecordEnabled]);

  const newValue = useMemo(
    () =>
      _map(_castArray(value), (data) => {
        if (_isObject(data)) {
          const lookupField = _isEmpty(lookUpFieldName) ? 'id' : lookUpFieldName;
          return _get(data, lookupField) || tget(data, `entity.${lookupField}`, EMPTY_STRING);
        } else {
          return data;
        }
      }),
    [lookUpFieldName, value],
  );

  const Component = useMemo(() => (isMulti ? VirtualizedMultiAsync : AsyncSelect), [isMulti]);

  const handleNewRecordCreation = useCallback(
    (entityRecordResponse) => {
      const lookUpFieldValue = tget(entityRecordResponse, lookUpFieldName, tget(entityRecordResponse, `entity.${lookUpFieldName}`));
      setIsCreateNewModalVisible(false);

      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: isMulti ? [...newValue, lookUpFieldValue] : lookUpFieldValue,
        },
      });

      setTimeout(() => {
        setKey(uuid());
      }, [RELOAD_OPTIONS_DELAY]);
    },
    [id, isMulti, lookUpFieldName, newValue, onAction],
  );

  const handleInputChange = useCallback((searchValue) => {
    setSearchText(searchValue);
  }, []);

  const handleLoadOptions = useCallback(
    async (_, additionalPayloadData = EMPTY_OBJECT) => {
      let payload = EMPTY_OBJECT;
      let hits = [];
      let currentNextPageToken = null;
      setIsLoading(true);
      const inputString = tget(additionalPayloadData, 'searchQuery');
      const start = tget(additionalPayloadData, 'pageInfo.start', 0);

      if (start > 0) {
        payload = getPayload(nextPageToken, inputString, lookUpDisplayNames);
      } else {
        payload = getPayload(EMPTY_STRING, inputString, lookUpDisplayNames);
        setNextPageToken(EMPTY_STRING);
        setSearchText(inputString);
      }

      // Executing Before Data Load event which expects to return payload
      const beforeDataLoadEventData = tget(eventHandlers, EVENT_NAMES.BEFORE_DATA_LOAD, EMPTY_OBJECT);
      const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE_FOR_BEFORE_DATA_LOAD = {
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD]: {
          get: (getterFieldName) => getFieldValue(getterFieldName),
          set: (setterFieldName, setterValue) => {
            // Checking the value dispatched for field can be done or not
            const isSetterFieldEditable = tget(mapOfFormFieldNameToEditable, [setterFieldName], false);

            if (isSetterFieldEditable) {
              onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: setterFieldName, value: setterValue } });
            }
          },
        },
      };

      const scriptReturnValue = await executeEventFromEventViewConfigData(
        beforeDataLoadEventData,
        MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE_FOR_BEFORE_DATA_LOAD,
      );
      const isReturnValuePresentFromScript = tget(scriptReturnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.IS_RETURN_VALUE_PRESENT, false);
      if (isReturnValuePresentFromScript) {
        const scriptPayload = tget(scriptReturnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.RETURN_VALUE, EMPTY_OBJECT);

        // Currently reading only filters from the script return value
        const filtersFromScript = tget(scriptPayload, 'filters', []);
        const existingPayloadFilter = tget(payload, 'filters', []);
        payload = { ...payload, filters: [...existingPayloadFilter, ...filtersFromScript] };
      }

      if (lookUpEntityName) {
        const response = await fetchEntityRecords(lookUpEntityName, payload);
        hits = tget(response, 'hits', []);
        currentNextPageToken = _get(response, 'nextPageToken');
      }

      const newOptions = getOptions(hits, lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate);

      const addOption = (option) => {
        newOptions.push(option);
      };

      const optionsLoadEventData = tget(eventHandlers, EVENT_NAMES.OPTIONS_LOAD, EMPTY_OBJECT);
      const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECEIVED_OPTIONS]: newOptions,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.SEARCH_TEXT]: inputString,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ADD_OPTION]: addOption,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD]: {
          get: (getterFieldName) => getFieldValue(getterFieldName),
          set: (setterFieldName, setterValue) => {
            // Checking the value dispatched for field can be done or not
            const isSetterFieldEditable = tget(mapOfFormFieldNameToEditable, [setterFieldName], false);

            if (isSetterFieldEditable) {
              onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: setterFieldName, value: setterValue } });
            }
          },
        },
      };

      executeEventFromEventViewConfigData(optionsLoadEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE);

      const hasMore = getHasMore(currentNextPageToken);
      setNextPageToken(currentNextPageToken);
      setIsLoading(false);

      return { resources: newOptions, hasMore };
    },
    [
      eventHandlers,
      lookUpEntityName,
      getOptions,
      lookUpDisplayNames,
      lookUpFieldName,
      template,
      isDisplayNameResolvedAccordingToTemplate,
      nextPageToken,
      getFieldValue,
      mapOfFormFieldNameToEditable,
      onAction,
    ],
  );

  const handleKeySearch = useCallback(
    async (_, selectedValues) => {
      setIsLoading(true);

      let hits = [];
      const payload = getPayloadForInit(selectedValues, lookUpFieldName);
      if (lookUpEntityName) {
        const response = await fetchEntityRecords(lookUpEntityName, payload);
        hits = tget(response, 'hits', []);
      }

      const newOptions = getOptions(hits, lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate);
      setIsLoading(false);

      return newOptions;
    },
    [getOptions, isDisplayNameResolvedAccordingToTemplate, lookUpDisplayNames, lookUpEntityName, lookUpFieldName, template],
  );

  const onChange = useCallback(
    async (payload) => {
      const _value = isMulti ? _get(payload, 'value', []) : getArraySafeValue(_get(payload, 'value', []));
      if (_includes(_value, CREATE_NEW_OPTION.value)) {
        setIsCreateNewModalVisible(true);
        return;
      }
      const reloadOptions = () => {
        setTimeout(() => {
          setKey(tget(payload, 'eventDetails.option.value'));
        }, [ES_REFETCH_DELAY]);
      };

      const changeEventData = tget(eventHandlers, EVENT_NAMES.CHANGE, EMPTY_OBJECT);
      const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.SEARCH_TEXT]: searchText,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD_VALUE]: _value,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ENTITY_NAME]: lookUpEntityName,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RELOAD_OPTIONS]: reloadOptions,
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.FIELD]: {
          get: (getterFieldName) => getFieldValue(getterFieldName),
          set: (setterFieldName, setterValue) => {
            // Checking the value dispatched for field can be done or not
            const isSetterFieldEditable = tget(mapOfFormFieldNameToEditable, [setterFieldName], false);

            if (isSetterFieldEditable) {
              onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id: setterFieldName, value: setterValue } });
            }
          },
        },
        [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RESET_RELATIONAL_FIELD_OPTIONS]: (fieldName) => {
          triggerCustomCodeEvent(`${fieldName}:${MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RESET_RELATIONAL_FIELD_OPTIONS}`);
        },
      };

      const scriptReturnValue = await executeEventFromEventViewConfigData(changeEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE);
      const isReturnValuePresentFromScript = tget(scriptReturnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.IS_RETURN_VALUE_PRESENT, false);
      const valueFromScript = tget(scriptReturnValue, EXECUTE_EVENT_FUNC_RETURN_VALUE_IDS.RETURN_VALUE);
      let valueToSet = isMulti ? _get(payload, 'value', []) : getArraySafeValue(_get(payload, 'value', []));

      if (isReturnValuePresentFromScript) {
        valueToSet = !_isEmpty(valueFromScript) ? valueFromScript : valueToSet;
      }

      if (!_isNil(valueToSet)) {
        onAction({
          type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            id,
            value: valueToSet,
          },
        });
      } else {
        toaster(TOASTER_TYPE.WARN, __('LookUp value of Selected Record is Empty'));
      }
    },
    [isMulti, eventHandlers, searchText, lookUpEntityName, getFieldValue, mapOfFormFieldNameToEditable, onAction, id],
  );

  const renderClearIcon = useCallback(
    () => <ClearIndicator isLoading={isLoading} id={id} values={value} onAction={onAction} />,
    [id, isLoading, onAction, value],
  );

  useEffect(() => {
    const reloadOptions = () => {
      setTimeout(() => {
        setKey(uuid());
      }, [RELOAD_OPTIONS_DELAY]);
    };

    CustomCodeEventEmitter.on(`${id}:${MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RESET_RELATIONAL_FIELD_OPTIONS}`, reloadOptions);

    return () => {
      CustomCodeEventEmitter.removeAllListeners(`${id}:${MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RESET_RELATIONAL_FIELD_OPTIONS}`);
    };
  }, [id]);

  return (
    <>
      <PropertyControlledComponent controllerProperty={isCreateNewRecordEnabled && !_isEmpty(lookUpEntityViewName)}>
        <CreateNewRecordModal
          isCreateNewModalVisible={isCreateNewModalVisible}
          lookUpEntityName={lookUpEntityName}
          lookUpEntityViewName={lookUpEntityViewName}
          setIsCreateNewModalVisible={setIsCreateNewModalVisible}
          onNewRecordCreation={handleNewRecordCreation}
        />
      </PropertyControlledComponent>
      <div className={cx(fieldLayoutStyles.fieldC, fieldClassName)}>
        <FieldLabel style={customStyles?.label} {...props} />
        <div
          className={cx(styles.field, {
            [styles.multiSelect]: isMulti,
          })}
        >
          <Component
            {...restProps}
            refreshOptions
            openMenuOnFocus
            closeMenuOnSelect={!isMulti}
            hideSelectedOptions={false}
            isMulti={isMulti}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: 20,
              }),
            }}
            className={styles.select}
            key={key}
            resourceType={RESOURCE_TYPE}
            value={newValue}
            staticOptions={staticOptions}
            resourceParams={getResourceParams()}
            lookUpSearchApi={handleLoadOptions}
            lookupByKeysApi={handleKeySearch}
            onInputChange={handleInputChange}
            onChange={onChange}
            loadingMessage={getLoadingMessage}
            suffix={renderClearIcon}
          />
        </div>
        <Error error={error} />
      </div>
    </>
  );
};

RelationshipField.propTypes = {
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isCreateNewRecordEnabled: PropTypes.bool,
  isDisplayNameResolvedAccordingToTemplate: PropTypes.bool,
  placeholder: PropTypes.string,
  dropDownClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  lookUpEntityName: PropTypes.string,
  lookUpFieldName: PropTypes.string.isRequired,
  lookUpDisplayNames: PropTypes.string,
  template: PropTypes.string,
  fieldClassName: PropTypes.string,
  error: PropTypes.string,
  lookUpEntityViewName: PropTypes.string,
  additional: PropTypes.object,
  mapOfFormFieldNameToEditable: PropTypes.object,
  eventHandlers: PropTypes.object,
  customStyles: PropTypes.object,
  value: PropTypes.array,
  getOptions: PropTypes.func,
  getFieldValue: PropTypes.func,
  onAction: PropTypes.func,
};

RelationshipField.defaultProps = {
  isClearable: false,
  isMulti: false,
  isCreateNewRecordEnabled: false,
  isDisplayNameResolvedAccordingToTemplate: false,
  placeholder: __('Type Here'),
  dropDownClassName: EMPTY_STRING,
  id: EMPTY_STRING,
  label: EMPTY_STRING,
  lookUpEntityViewName: EMPTY_STRING,
  lookUpEntityName: EMPTY_STRING,
  lookUpDisplayNames: EMPTY_STRING,
  template: EMPTY_STRING,
  fieldClassName: EMPTY_STRING,
  error: EMPTY_STRING,
  additional: EMPTY_OBJECT,
  mapOfFormFieldNameToEditable: EMPTY_OBJECT,
  eventHandlers: EMPTY_OBJECT,
  customStyles: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
  getOptions: defaultGetOptions,
  getFieldValue: _noop,
  onAction: _noop,
};

export default RelationshipField;
