import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import _keys from 'lodash/keys';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _reduce from 'lodash/reduce';
import _has from 'lodash/has';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _unset from 'lodash/unset';
import _concat from 'lodash/concat';
import _uniq from 'lodash/uniq';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

import { searchTemplate } from '../../actions/template.actions';
import { fetchEntityRecords } from '../../actions/recordManagement.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../actions/conditionBuilder.actions';
import { getPreSignedUrls } from '../../actions/media.actions';
import {
  getBasicResolvedValues,
  getTemplatePayload,
  getCustomEntitySearchPayload,
  resolveEmailTemplateVariables,
  getTemplateVariables,
} from './sendEmailModalContentRenderer.helpers';
import { getTemplateJson } from '../../helpers/template.helpers';
import { getHtmlMediaIds, getResolvedMediaJson, getResolvedMediaTemplate } from '../../utils/template.utils';
import { isSystemDefinedField } from '../../utils';
import {
  ACTION_TYPES,
  FIELD_IDS,
  EMAIL_USER_INPUT_ID,
  RICH_TEXT_EDITOR_FIELDS,
  VARIABLE_ERROR_MESSAGE,
} from './sendEmailModalContentRenderer.constants';
import { SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS, SEND_EMAILS_ADDRESSEE_TYPE } from '../../constants/actionBuilder.constants';
import { ACTION_TYPES as ACTION_EXECUTION_MODAL_ACTION_TYPES } from '../actionExecutionModal/actionExecutionModal.constants';
import entityReader from '../../readers/entity.reader';
import fieldDefinitionReader from '../../readers/fieldDefinition.reader';

const handleInit = async ({ getState, setState }) => {
  const { rowData, selectedActionDef, entityDef } = getState();

  const addresseeType = _get(selectedActionDef, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE);
  const fromValue = _castArray(_get(selectedActionDef, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.FROM, EMPTY_STRING));
  const sourceField = _get(selectedActionDef, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.SOURCE_FIELD}`);
  const derivedEntity = _get(selectedActionDef, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY}`);
  const derivedEntityField = _get(selectedActionDef, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY_FIELD}`);
  let toValue = [];
  if (addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.STATIC) {
    toValue = _get(selectedActionDef, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.TO, EMPTY_ARRAY);
  } else if (addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED) {
    toValue = [`$record.${sourceField}`];
  }
  const templates = _get(selectedActionDef, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.EMAIL_TEMPLATES, EMPTY_OBJECT);
  const templateNames = _keys(templates);
  const templatePayload = getTemplatePayload(templateNames);

  setState({ isDataLoading: true });
  let toResolvedValue = [];
  let fromResolvedValue = [];

  if (addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.STATIC) {
    toResolvedValue = getBasicResolvedValues(toValue, rowData);
    fromResolvedValue = getBasicResolvedValues(fromValue, rowData);
  } else if (addresseeType === SEND_EMAILS_ADDRESSEE_TYPE.DERIVED) {
    const { entity } = rowData;
    // when record group comes needs to do api call to fetch that record group name
    const fieldDefinitions = entityReader.fieldDefinitions(entityDef);
    const sourceFieldDef = _find(fieldDefinitions, { name: getArraySafeValue(sourceField) });
    const lookupFieldName = fieldDefinitionReader.lookupFieldDisplayField(sourceFieldDef);

    const recordRelationshipEntityIds = isSystemDefinedField(getArraySafeValue(sourceField))
      ? _castArray(_get(rowData, sourceField, EMPTY_ARRAY))
      : _castArray(_get(entity, sourceField, EMPTY_ARRAY));
    const payload = getCustomEntitySearchPayload(recordRelationshipEntityIds);
    const response = await fetchEntityRecords(derivedEntity, payload);
    const recordData = tget(response, 'hits', EMPTY_ARRAY);

    toResolvedValue = _reduce(
      recordData,
      (ans, data) => {
        if (_has(data, derivedEntityField)) {
          ans.push(data[derivedEntityField]);
        } else if (_has(data, `entity.${derivedEntityField}`)) {
          ans.push(data.entity[derivedEntityField]);
        }
        return ans;
      },
      [],
    );
    fromResolvedValue = getBasicResolvedValues(fromValue, rowData);

    setState({ derivedEntityRecordDataById: _keyBy(recordData, 'id'), derivedEntityLookupFieldName: lookupFieldName });
  }

  const sendEmailsType = _get(selectedActionDef, SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.ADDRESSEE_TYPE);
  const sourceEntityName = _get(selectedActionDef, 'entityName');
  const sourceFieldEntityName = _get(selectedActionDef, `emailAddresseeConfig.${SEND_EMAILS_ACTION_DEFINITION_FIELD_IDS.DERIVED_ENTITY}`);
  const mapOfVariableToEntityNameForDerived = { $record: sourceEntityName, $addressee: sourceFieldEntityName };
  const mapOfVariableToEntityNameForStatic = { $record: sourceEntityName };
  const mapOfVariableToEntityName =
    sendEmailsType === SEND_EMAILS_ADDRESSEE_TYPE.STATIC ? mapOfVariableToEntityNameForStatic : mapOfVariableToEntityNameForDerived;

  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName, {}, false, false, false);

  const response = await searchTemplate(templatePayload);
  let allTemplates = tget(response, 'hits', EMPTY_ARRAY);
  let mediaIds = [];
  _forEach(allTemplates, (template) => {
    mediaIds = _concat(mediaIds, getHtmlMediaIds(_get(template, RICH_TEXT_EDITOR_FIELDS.BODY, EMPTY_STRING)));
  });

  const mediaData = await getPreSignedUrls(_uniq(mediaIds));
  const mediaDataById = _keyBy(mediaData, 'mediaId');

  allTemplates = _map(allTemplates, (template) => {
    const body = getResolvedMediaTemplate(_get(template, RICH_TEXT_EDITOR_FIELDS.BODY), mediaDataById);
    const json = getTemplateJson(_get(template, RICH_TEXT_EDITOR_FIELDS.JSON_BODY));
    const newJson = getResolvedMediaJson(json, mediaDataById);
    return { ...template, [RICH_TEXT_EDITOR_FIELDS.BODY]: body, [RICH_TEXT_EDITOR_FIELDS.JSON_BODY]: newJson };
  });
  const emailTemplateDefStore = _keyBy(allTemplates, 'id');

  const emailTemplateOptions = _map(emailTemplateDefStore, (emailTemplateDef) => ({
    label: tget(emailTemplateDef, 'displayName', NO_DATA),
    value: tget(emailTemplateDef, 'id'),
  }));

  const initialValues = {
    [FIELD_IDS.TO]: toResolvedValue,
    [FIELD_IDS.FROM]: getArraySafeValue(fromResolvedValue),
  };

  setState({
    isDataLoading: false,
    values: initialValues,
    emailTemplateDefStore,
    emailTemplateOptions,
    mapOfVariableToEntityNameForDerived,
    mapOfVariableToEntityNameForStatic,
    conditionBuilderFieldDefinitionObject,
  });
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { id, value } = params;
  const {
    values = EMPTY_OBJECT,
    emailTemplateDefStore = EMPTY_OBJECT,
    selectedActionDef = EMPTY_OBJECT,
    derivedEntityRecordDataById = EMPTY_OBJECT,
    rowData,
    errors,
    onModalAction,
  } = getState();
  const newErrors = { ...errors };

  if (id === FIELD_IDS.MAP_OF_VARIABLES || id === FIELD_IDS.ADDRESSEE_VALUE) {
    const selectedTemplate = values[FIELD_IDS.TEMPLATES];
    const selectedTemplateId = getArraySafeValue(selectedTemplate);
    const selectedTemplateDef = emailTemplateDefStore[selectedTemplateId];
    const emailSubject = tget(selectedTemplateDef, 'subject', EMPTY_STRING);
    const emailBody = tget(selectedTemplateDef, RICH_TEXT_EDITOR_FIELDS.BODY, EMPTY_STRING);
    const templateId = _get(selectedTemplateDef, 'id');
    const selectedAddressee = getArraySafeValue(_get(values, FIELD_IDS.ADDRESSEE_VALUE, ['']));
    const resolvedEmailBody =
      id === FIELD_IDS.MAP_OF_VARIABLES
        ? resolveEmailTemplateVariables(
            templateId,
            emailBody,
            selectedActionDef,
            rowData,
            value,
            _get(derivedEntityRecordDataById, selectedAddressee, EMPTY_OBJECT),
          )
        : resolveEmailTemplateVariables(
            templateId,
            emailBody,
            selectedActionDef,
            rowData,
            _get(values, FIELD_IDS.MAP_OF_VARIABLES, EMPTY_OBJECT),
            _get(derivedEntityRecordDataById, getArraySafeValue(value), EMPTY_OBJECT),
          );
    const resolvedEmailSubject =
      id === FIELD_IDS.MAP_OF_VARIABLES
        ? resolveEmailTemplateVariables(
            templateId,
            emailSubject,
            selectedActionDef,
            rowData,
            value,
            _get(derivedEntityRecordDataById, selectedAddressee, EMPTY_OBJECT),
          )
        : resolveEmailTemplateVariables(
            templateId,
            emailSubject,
            selectedActionDef,
            rowData,
            _get(values, FIELD_IDS.MAP_OF_VARIABLES, EMPTY_OBJECT),
            _get(derivedEntityRecordDataById, getArraySafeValue(value), EMPTY_OBJECT),
          );

    // Action execution modal onAction
    if (id === FIELD_IDS.MAP_OF_VARIABLES) {
      _forEach(value, (val, key) => {
        if (!_isEmpty(val)) _unset(newErrors, key);
        else {
          _set(newErrors, key, VARIABLE_ERROR_MESSAGE);
        }
      });

      onModalAction({
        type: ACTION_EXECUTION_MODAL_ACTION_TYPES.ON_EMAIL_MODAL_CONTENT_CHANGE,
        payload: { id: EMAIL_USER_INPUT_ID, value, errors: newErrors },
      });
    }

    setState({ selectedTemplateResolvedBody: resolvedEmailBody, selectedTemplateResolvedSubject: resolvedEmailSubject, errors: newErrors });
  }

  if (id === FIELD_IDS.EDITOR_ID) {
    const selectedTemplate = values[FIELD_IDS.TEMPLATES];
    const selectedTemplateId = getArraySafeValue(selectedTemplate);
    const selectedTemplateDef = emailTemplateDefStore[selectedTemplateId];
    _set(emailTemplateDefStore, selectedTemplateId, {
      ...selectedTemplateDef,
      [RICH_TEXT_EDITOR_FIELDS.JSON_BODY]: JSON.stringify(_get(value, 'editorContent')),
      [RICH_TEXT_EDITOR_FIELDS.BODY]: _get(value, 'htmlContent'),
    });

    onModalAction({
      type: ACTION_EXECUTION_MODAL_ACTION_TYPES.ON_EMAIL_MODAL_CONTENT_CHANGE,
      payload: {
        id: EMAIL_USER_INPUT_ID,
        value: {
          templateId: selectedTemplateId,
          templateRequest: { ...selectedTemplateDef, [RICH_TEXT_EDITOR_FIELDS.BODY]: _get(value, 'htmlContent') },
        },
        errors: newErrors,
      },
    });
    setState({ emailTemplateDefStore, errors: newErrors });
  }
  if (id === FIELD_IDS.TO) {
    // Action execution modal onAction
    onModalAction({
      type: ACTION_EXECUTION_MODAL_ACTION_TYPES.ON_EMAIL_MODAL_CONTENT_CHANGE,
      payload: { id: EMAIL_USER_INPUT_ID, value: { email: value } },
    });
  }
  let newValues = { ...values };
  if (id === FIELD_IDS.TEMPLATES) {
    const selectedTemplateId = getArraySafeValue(value);
    const selectedTemplateDef = emailTemplateDefStore[selectedTemplateId];
    newValues = {
      ...values,
      [FIELD_IDS.EDITOR_ID]: getTemplateJson(_get(selectedTemplateDef, RICH_TEXT_EDITOR_FIELDS.JSON_BODY)),
    };
  }
  setState({ values: { ...newValues, [id]: value } });
};

const handleOnSelectedTemplateChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { selectedTemplateDef } = params;
  const { isEmailPreviewEnabled = false, selectedActionDef = EMPTY_OBJECT, rowData, values, emailTemplateDefStore, onModalAction } = getState();

  if (isEmailPreviewEnabled && !_isEmpty(selectedTemplateDef)) {
    // Action execution modal onAction
    const selectedTemplateId = getArraySafeValue(values[FIELD_IDS.TEMPLATES]);
    const { selectedTemplateEmptyVariables, selectedTemplateDefinedVariables } = getTemplateVariables(
      selectedTemplateDef,
      selectedTemplateId,
      selectedActionDef,
    );

    const newErrors = {};
    _forEach(selectedTemplateEmptyVariables, (variable) => {
      if (_isEmpty(_get(values, `${FIELD_IDS.MAP_OF_VARIABLES}.${variable}`))) {
        _set(newErrors, variable, VARIABLE_ERROR_MESSAGE);
      }
    });

    onModalAction({
      type: ACTION_EXECUTION_MODAL_ACTION_TYPES.ON_EMAIL_MODAL_CONTENT_CHANGE,
      payload: {
        id: EMAIL_USER_INPUT_ID,
        value: { templateId: selectedTemplateId, templateRequest: { ...tget(emailTemplateDefStore, selectedTemplateId, EMPTY_OBJECT) } },
        errors: newErrors,
      },
    });
    setState({ isEmailResolving: true });

    const emailSubject = tget(selectedTemplateDef, 'subject', EMPTY_STRING);
    const emailBody = tget(selectedTemplateDef, RICH_TEXT_EDITOR_FIELDS.BODY, EMPTY_STRING);
    const id = _get(selectedTemplateDef, 'id');
    const resolvedEmailBody = resolveEmailTemplateVariables(id, emailBody, selectedActionDef, rowData);
    const resolvedEmailSubject = resolveEmailTemplateVariables(id, emailSubject, selectedActionDef, rowData);

    setState({
      isEmailResolving: false,
      selectedTemplateResolvedBody: resolvedEmailBody,
      selectedTemplateResolvedSubject: resolvedEmailSubject,
      selectedTemplateEmptyVariables,
      selectedTemplateDefinedVariables,
      errors: newErrors,
    });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT]: handleInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_SELECTED_TEMPLATE_CHANGE]: handleOnSelectedTemplateChange,
};

export default ACTION_HANDLERS;
