import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import { getEntityViewDefFromCache, getMasterEntityDefFromCache } from '../../../../actions/applicationRenderer.actions';
import Records from '../../../../actions/customCodeApis/customCode.RecordApi';
import Actions from '../../../../actions/customCodeApis/customCode.ActionApi';

import { fallBackForFunctionUsingPromise } from '../../../../factories/functionFactory';

import { getViewOverrides } from './gridViewWidgetRenderer.helpers';

import { ACTION_TYPES } from './gridViewWidgetRenderer.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import { FIELD_IDS as EVENT_FIELD_IDS, SCRIPT_TYPES } from '../../../../constants/event.fieldIds';
import { COMPONENT_CONFIG_KEYS } from '../../constants/visualBuilder.general.constants';
import { GENERAL_PROPERTIES } from '../../constants/visualBuilder.properties.constants';
import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../constants/eventHandlers.constants';
import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';

const handleOnInit = async ({ getState, setState, params = {} }) => {
  const {
    isViewConfigure = false,
    isEntityDefConfigured = false,
    isLoadScriptExecuted = false,
    componentConfig = EMPTY_OBJECT,
    pageEntity: pageEntityDef = EMPTY_OBJECT,
    pageEntityRecord = EMPTY_OBJECT,
    entityDef,
  } = getState();

  const { currentUserData, variablesApi, applicationVariables } = params;
  if (!isViewConfigure || !isEntityDefConfigured) return;

  const widgetType = _get(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_TYPE);
  const widgetName = _get(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME);

  const eventHandlers = tget(componentConfig, `${COMPONENT_CONFIG_KEYS.PROPERTIES}.${GENERAL_PROPERTIES.EVENT_HANDLERS}`, EMPTY_ARRAY);
  const eventHandlersMapByEventName = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

  const onLoadEventData = tget(eventHandlersMapByEventName, EVENT_NAMES.LOAD, EMPTY_OBJECT);

  const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
    [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$CURRENT_USER]: currentUserData,
    [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
    [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.VARIABLES]: variablesApi,
    [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ACTIONS]: Actions,
  };

  const metaData = tget(onLoadEventData, EVENT_FIELD_IDS.META_DATA, EMPTY_OBJECT);
  const scriptExecuteType = tget(metaData, EVENT_FIELD_IDS.GENERATOR_TYPE, SCRIPT_TYPES.SYNC);
  const fallbackForScript = scriptExecuteType === SCRIPT_TYPES.SYNC ? EMPTY_STRING : fallBackForFunctionUsingPromise;
  const onLoadScript = tget(onLoadEventData, EVENT_FIELD_IDS.SCRIPT, fallbackForScript);
  const isOnLoadScriptPresent = !_isEmpty(onLoadScript);

  setState({ isViewOverriding: true });
  if (!isLoadScriptExecuted) {
    if (!_isEmpty(onLoadScript)) {
      await executeEventFromEventViewConfigData(onLoadEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, pageEntityRecord, widgetName);

      setState({ isLoadScriptExecuted: true });
    } else {
      setState({ isLoadScriptExecuted: true });
    }
  }
  if (isLoadScriptExecuted || !isOnLoadScriptPresent) {
    const viewOverrides = getViewOverrides({
      widgetType,
      componentConfig,
      pageEntityRecord,
      masterEntityDef: entityDef,
      currentUserData,
      applicationVariables,
      pageEntityDef,
    });
    setState({
      viewOverrides,
    });

    // adding this timeout to mount entity view viewer only when view overrides are stable
    setTimeout(() => {
      setState({ isViewOverriding: false });
    }, 2000);
  }
};

const handleLoadEntityDef = async ({ getState, setState, params }) => {
  const { componentConfig = EMPTY_OBJECT, isLoadScriptExecuted = false } = getState();

  const { cachedEntitiesDef, handleSetEntityDef } = params;

  const entityName = _get(componentConfig, 'properties.entityName');

  setState({ isEntityDefLoading: true });

  if (!_isEmpty(entityName) && !isLoadScriptExecuted) {
    const masterEntityDef = await getMasterEntityDefFromCache({
      cacheValue: cachedEntitiesDef,
      setCacheHandler: handleSetEntityDef,
      entityName,
    });

    setState({
      entityDef: masterEntityDef,
      isEntityDefConfigured: true,
      isEntityDefLoading: false,
    });
  } else {
    setState({ isEntityDefConfigured: false, isEntityDefLoading: false });
  }
};

const handleLoadEntityViewConfig = async ({ getState, setState, params }) => {
  const { componentConfig = EMPTY_OBJECT, isLoadScriptExecuted = false } = getState();

  const { cachedEntityViewConfigByNameForEntities, handleSetEntityViewConfig } = params;

  const entityViewName = _get(componentConfig, 'properties.entityViewName');
  const entityName = _get(componentConfig, 'properties.entityName');

  setState({ isViewConfigLoading: true });
  if (!_isEmpty(entityViewName) && !isLoadScriptExecuted) {
    const gridViewConfiguration = await getEntityViewDefFromCache({
      cacheValue: cachedEntityViewConfigByNameForEntities,
      setCacheHandler: handleSetEntityViewConfig,
      entityViewName,
      entityName,
    });

    setState({
      isViewConfigLoading: false,
      isViewConfigure: true,
      gridViewConfiguration,
    });
  } else {
    setState({ isViewConfigLoading: false, isViewConfigure: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_INIT]: handleOnInit,
  [ACTION_TYPES.LOAD_ENTITY_DEF]: handleLoadEntityDef,
  [ACTION_TYPES.LOAD_ENTITY_VIEW_CONFIG]: handleLoadEntityViewConfig,
};

export default ACTION_HANDLERS;
