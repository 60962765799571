import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import { FIELD_IDS } from './templateBuilderForm.fields';
import { TEMPLATE_TYPES } from '../../../../../../constants/templateBuilder.constants';

import styles from '../templateBuilderForm.module.scss';

const rowCheck = {
  [FIELD_IDS.SUBJECT]: ({ templateType }) => templateType === TEMPLATE_TYPES.EMAIL || templateType === TEMPLATE_TYPES.PUSHER,
  [FIELD_IDS.JSON_BODY]: ({ templateType }) => templateType === TEMPLATE_TYPES.EMAIL,
  [FIELD_IDS.BODY_TITLE]: ({ templateType }) => templateType === TEMPLATE_TYPES.EMAIL,
  [FIELD_IDS.BODY]: ({ templateType }) => templateType === TEMPLATE_TYPES.TEXT || templateType === TEMPLATE_TYPES.PUSHER,
};

const getTemplateSections = (templateType) => [
  {
    subHeader: {
      className: 'p-t-12',
      label: __('Template details'),
    },
    rows: [
      {
        className: styles.halfColumn,
        columns: [FIELD_IDS.TEMPLATE_TYPE],
      },
      {
        columns: [FIELD_IDS.DISPLAY_NAME, FIELD_IDS.NAME],
      },
      {
        columns: [FIELD_IDS.DESCRIPTION],
      },
      {
        columns: filterRows([FIELD_IDS.SUBJECT], { templateType }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.BODY], { templateType }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.BODY_TITLE], { templateType }, rowCheck),
      },
      {
        columns: filterRows([FIELD_IDS.JSON_BODY], { templateType }, rowCheck),
      },
    ],
  },
];

export default getTemplateSections;
