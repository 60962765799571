import React from 'react';

import ActionExecutionModal from '../../../../molecules/actionExecutionModal/ActionExecutionModal';
import ListViewRenderer from '../../components/listViewRenderer/ListViewRenderer';
import { getUuid } from '../../../../utils';

const WithListActionExecution = (props) => {
  const actionModalContextId = getUuid();

  return (
    <>
      <ListViewRenderer {...props} actionModalContextId={actionModalContextId} />
      <ActionExecutionModal actionModalContextId={actionModalContextId} />
    </>
  );
};

export default WithListActionExecution;
