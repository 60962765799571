import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import _size from 'lodash/size';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import PropertyControlledComponent from '@tekion/tekion-components/molecules/PropertyControlledComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import AsyncPaginatedSelect from '../../../../../../../../molecules/asyncPaginatedSelect';
import IconRenderer from '../../../../../../../../atoms/IconRenderer';

import { generateRecordTypeOptions, getOptions, getPayload, handleSearchViewConfigurationsByRecordType } from '../helpers/entityViewForm.helpers';

import { ENTITY_VIEW_FORM_FIELD_IDS } from '../constants/entityViewForm.general.constants';

import styles from '../../../createEntityView.module.scss';

const EntityViewFormField = ({ index, entityName, viewType, error, recordTypeOptions, viewRecordTypeMetadataList, handleDeleteRow, onAction }) => {
  const viewRecordTypeMetadata = tget(viewRecordTypeMetadataList, index, EMPTY_OBJECT);
  const recordTypeName = getArraySafeValue(_get(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME));
  const filteredRecordTypeOptions = useMemo(
    () => generateRecordTypeOptions(recordTypeOptions, viewRecordTypeMetadataList, recordTypeName),
    [recordTypeOptions, viewRecordTypeMetadataList, recordTypeName],
  );
  const recordTypeNameError = _get(error, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME);

  return (
    <div className={styles.entityViewFormFields}>
      <SelectInput
        required
        id={ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME}
        className={styles.recordTypeSelect}
        placeholder={__('Select Record Type')}
        value={_get(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.RECORD_TYPE_NAME)}
        error={recordTypeNameError}
        errorClassName={styles.errorMessage}
        options={filteredRecordTypeOptions}
        onAction={onAction}
      />

      <AsyncPaginatedSelect
        required
        shouldFetchOnValueChange
        isDisabled={_isEmpty(recordTypeName)}
        id={ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME}
        value={_get(viewRecordTypeMetadata, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME)}
        fieldClassName={styles.asyncPaginatedSelect}
        error={_get(error, ENTITY_VIEW_FORM_FIELD_IDS.VIEW_NAME)}
        getPayload={getPayload({ entityName, viewType })}
        getOptions={getOptions(viewRecordTypeMetadata, viewRecordTypeMetadataList)}
        serviceHandler={!_isEmpty(recordTypeName) ? handleSearchViewConfigurationsByRecordType(recordTypeName) : _noop}
        onAction={onAction}
      />
      <PropertyControlledComponent controllerProperty={_size(viewRecordTypeMetadataList) > 1}>
        <IconRenderer className={styles.deleteIcon} iconName="icon-trashcan" onClick={handleDeleteRow} />
      </PropertyControlledComponent>
    </div>
  );
};

EntityViewFormField.propTypes = {
  index: PropTypes.number.isRequired,
  entityName: PropTypes.string,
  viewType: PropTypes.string,
  error: PropTypes.object,
  recordTypeOptions: PropTypes.array,
  viewRecordTypeMetadataList: PropTypes.array.isRequired,
  handleDeleteRow: PropTypes.func,
  onAction: PropTypes.func.isRequired,
};

EntityViewFormField.defaultProps = {
  entityName: EMPTY_STRING,
  viewType: EMPTY_STRING,
  error: EMPTY_OBJECT,
  recordTypeOptions: EMPTY_ARRAY,
  handleDeleteRow: _noop,
};

export default React.memo(EntityViewFormField);
