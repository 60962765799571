import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import InfoMessage from '@tekion/tekion-components/molecules/infoMessage';
import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import AsyncPaginatedSelect from '../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';

import { fetchActions } from '../../../../../actions/actionBuilder.actions';

import { getPayload, getActionNameOptions, getCustomActionsInfoMessage } from './customActionsConfigurator.helper';

import { FIELD_IDS } from '../constants/customActionsConfigurator.constants';

import entityReader from '../../../../../readers/entity.reader';

const CUSTOM_ACTIONS_INFO_FIELD = {
  id: FIELD_IDS.CUSTOM_ACTIONS_INFO,
  renderer: InfoMessage,
};

const ENTITY_ACTIONS_TO_SHOW = {
  id: FIELD_IDS.ACTIONS_TO_SHOW,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    isMulti: true,
    label: __('Select Action(s)'),
    id: FIELD_IDS.ACTIONS_TO_SHOW,
    serviceHandler: fetchActions,
    getOptions: getActionNameOptions,
  },
};

const CUSTOM_ACTIONS_FORM_FIELDS = {
  [FIELD_IDS.ACTIONS_TO_SHOW]: ENTITY_ACTIONS_TO_SHOW,
  [FIELD_IDS.CUSTOM_ACTIONS_INFO]: CUSTOM_ACTIONS_INFO_FIELD,
};

const getFields = (entity, formValues, showActions) => {
  const entityName = entityReader.name(entity);
  const actionsToShow = tget(formValues, [FIELD_IDS.ACTIONS_TO_SHOW], EMPTY_ARRAY);

  return {
    ...CUSTOM_ACTIONS_FORM_FIELDS,
    [FIELD_IDS.ACTIONS_TO_SHOW]: addToRenderOptions(CUSTOM_ACTIONS_FORM_FIELDS[FIELD_IDS.ACTIONS_TO_SHOW], [
      { path: 'getPayload', value: getPayload(entityName) },
    ]),
    [FIELD_IDS.CUSTOM_ACTIONS_INFO]: addToRenderOptions(CUSTOM_ACTIONS_FORM_FIELDS[FIELD_IDS.CUSTOM_ACTIONS_INFO], [
      {
        path: 'infoMessage',
        value: getCustomActionsInfoMessage(showActions, actionsToShow),
      },
    ]),
  };
};

export default getFields;
