import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import _valuesIn from 'lodash/valuesIn';
import { defaultMemoize } from 'reselect';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';
import { tget } from '@tekion/tekion-base/utils/general';
import SelectInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/SelectInput';
import CheckboxRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import AsyncPaginatedSelect from '../../../../../../../../molecules/asyncPaginatedSelect';

import { fetchEntities } from '../../../../../../../../actions/entityManagement.actions';

import { getPayload, getPayloadForInitialFetch } from './relationshipDetailsForm.helpers';
import { getAsyncPaginatedSelectEntityOptions, getInfoBadge, mergeRenderOptions } from '../../../helpers/fieldsForm.general.helpers';

import RELATIONSHIP_DETAILS_FORM_FIELD_IDS from '../constants/relationshipDetailsForm.fieldIds';
import RELATIONSHIP_DETAILS_FORM_LABELS from '../constants/relationshipDetailsForm.labels';

const LOOKUP_ENTITY_FIELD = {
  id: RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    placeholder: __('Select Lookup Entity'),
    label: __('Lookup Entity'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
    shouldFetchOnValueChange: true,
    getOptions: getAsyncPaginatedSelectEntityOptions,
    serviceHandler: fetchEntities,
  },
};

const LOOKUP_FIELD = {
  id: RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select Lookup field'),
    label: __('Lookup field'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const LOOKUP_DISPLAY_FIELD = {
  id: RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY,
  renderer: SelectInput,
  renderOptions: {
    placeholder: __('Select display field'),
    label: __('Lookup display field'),
    size: 10,
    required: true,
    validators: [isRequiredRule],
  },
};

const GROUPS_ALLOWED_FIELD = {
  id: RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED,
  renderer: CheckboxRenderer,
  renderOptions: {
    checkboxLabel: __('Groups Allowed'),
  },
};

const RELATIONSHIP_DETAILS_FORM_FIELDS = {
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY]: LOOKUP_ENTITY_FIELD,
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP]: LOOKUP_FIELD,
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY]: LOOKUP_DISPLAY_FIELD,
  [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED]: GROUPS_ALLOWED_FIELD,
};

const getFieldLabel = defaultMemoize((fieldId) => tget(RELATIONSHIP_DETAILS_FORM_LABELS, fieldId));

const getFields = (entity, values, isGroupingAllowed, lookupDisplayFieldOptions, lookupFieldOptions, renderOptions) => {
  const lookupEntity = _get(values, RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY);
  const entityName = _get(entity, 'name');
  const fieldsRenderOptions = {
    [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_ENTITY]: [
      {
        path: 'getPayload',
        value: getPayload(entityName),
      },
      {
        path: 'getPayloadForInitialFetch',
        value: getPayloadForInitialFetch(entityName),
      },
    ],
    [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP]: [
      {
        path: 'options',
        value: lookupFieldOptions,
      },
      ...(_isEmpty(lookupEntity) ? [{ path: 'isDisabled', value: true }] : EMPTY_ARRAY),
    ],
    [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.LOOKUP_DISPLAY]: [
      {
        path: 'options',
        value: lookupDisplayFieldOptions,
      },
      ...(_isEmpty(lookupEntity) ? [{ path: 'isDisabled', value: true }] : EMPTY_ARRAY),
    ],
    ...(!isGroupingAllowed && {
      [RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED]: [
        {
          path: 'disabled',
          value: true,
        },
        {
          path: 'checkboxLabel',
          value: getInfoBadge(getFieldLabel(RELATIONSHIP_DETAILS_FORM_FIELD_IDS.GROUPS_ALLOWED), __('Grouping is not allowed for this entity')),
        },
      ],
    }),
  };
  const mergedRenderOptions = mergeRenderOptions(fieldsRenderOptions, _valuesIn(RELATIONSHIP_DETAILS_FORM_FIELD_IDS), renderOptions, getFieldLabel);

  return _reduce(
    _valuesIn(RELATIONSHIP_DETAILS_FORM_FIELD_IDS),
    (fields, fieldId) =>
      _set(fields, fieldId, addToRenderOptions(_get(RELATIONSHIP_DETAILS_FORM_FIELDS, fieldId), _get(mergedRenderOptions, fieldId, EMPTY_ARRAY))),
    {},
  );
};
export default getFields;
