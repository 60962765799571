import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Lodash
import _map from 'lodash/map';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, NO_DATA } from '@tekion/tekion-base/app.constants';
import Dropdown, { DROPDOWN_PLACEMENT, DROPDOWN_TRIGGER } from '@tekion/tekion-components/molecules/DropDown';

import Menu from '@tekion/tekion-components/molecules/Menu';
import FontIcon from '@tekion/tekion-components/atoms/FontIcon';
import Tooltip from '@tekion/tekion-components/atoms/tooltip';
import makeCellRenderer from '@tekion/tekion-components/molecules/CellRenderers/makeCellRenderer';
import LoadingSpinner from '@tekion/tekion-components/molecules/loadingSpinner';

import { ACTION_KEYS, ACTION_TYPES } from '../listViewRenderer.constants';
import { ACTION_DEFINITION_FIELD_IDS } from '../../../../../constants/actionBuilder.constants';

import styles from '../listViewRenderer.module.scss';

const CustomActionCellRenderer = ({ index, className, data, original, allActionsByName, customRowActionsData, onAction }) => {
  const [isLoading, setIsLoading] = useState(false);

  const actionOptions = useMemo(() => {
    const actionBuilderRowActionOptions = _map(allActionsByName, (action) => ({
      [ACTION_KEYS.ID]: _get(action, ACTION_KEYS.ID, EMPTY_STRING),
      [ACTION_KEYS.NAME]: _get(action, ACTION_KEYS.NAME, EMPTY_STRING),
      [ACTION_KEYS.DISABLED]: !_includes(data, _get(action, ACTION_KEYS.NAME, EMPTY_STRING)),
      [ACTION_KEYS.ERROR_MESSAGE]: _get(action, ACTION_KEYS.ERROR_MESSAGE, false) || __('Record could not qualify entry condition'),
      [ACTION_KEYS.DISPLAY_NAME]: _get(action, ACTION_DEFINITION_FIELD_IDS.ACTION_DISPLAY_NAME, NO_DATA),
    }));

    return [...actionBuilderRowActionOptions, ...(customRowActionsData || [])];
  }, [allActionsByName, customRowActionsData, data]);

  const handleMenuClick = useCallback(
    async (event) => {
      event.stopPropagation();
      setIsLoading(true);
      await onAction({ type: ACTION_TYPES.TABLE_ACTION_MENU_CLICK, payload: { ...original, rowIndex: index } });
      setIsLoading(false);
    },
    [onAction, original, index],
  );

  const onRowActionClick = useCallback(
    (rowActionData) => (action) => {
      const actionName = _get(action, 'key', EMPTY_STRING);
      const event = _get(action, 'domEvent');
      event.stopPropagation();

      onAction({ type: ACTION_TYPES.TABLE_ROW_ACTION_CLICK, payload: { ...original, actionName, additional: rowActionData } });
    },
    [onAction, original],
  );

  const renderActionMenuItems = useCallback(
    (actionOption) =>
      _isEmpty(actionOption) ? (
        <div className={styles.noDataContainer}>{__('No Actions Added')}</div>
      ) : (
        _map(actionOption, ({ id, displayName, name, disabled = false, errorMessage, ...rest }) => (
          <Menu.Item
            key={name}
            className={styles.actionMenuItem}
            onClick={onRowActionClick({ id, name, disabled, errorMessage, ...rest })}
            disabled={disabled}
          >
            <div>{displayName}</div>
            {disabled ? (
              <Tooltip className={styles.actionMenuTooltip} placement="topRight" title={errorMessage}>
                <FontIcon>icon-information-filled</FontIcon>
              </Tooltip>
            ) : null}
          </Menu.Item>
        ))
      ),
    [onRowActionClick],
  );

  const renderActionMenu = useCallback(
    () => (
      <Menu className={styles.actionMenu}>
        {isLoading ? (
          <div className={styles.actionLoader}>
            <LoadingSpinner size={35} />
          </div>
        ) : (
          renderActionMenuItems(actionOptions)
        )}
      </Menu>
    ),
    [actionOptions, isLoading, renderActionMenuItems],
  );

  return (
    <Dropdown
      className={cx(styles.actionDropDown, className)}
      onClick={handleMenuClick}
      overlay={renderActionMenu}
      placement={DROPDOWN_PLACEMENT.BOTTOM_RIGHT}
      trigger={DROPDOWN_TRIGGER.CLICK}
    >
      <FontIcon data-disable-sort-start>icon-overflow</FontIcon>
    </Dropdown>
  );
};

CustomActionCellRenderer.propTypes = {
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  onAction: PropTypes.func.isRequired,
  original: PropTypes.object.isRequired,
  allActionsByName: PropTypes.object,
  customRowActionsData: PropTypes.array,
};

CustomActionCellRenderer.defaultProps = {
  className: EMPTY_STRING,
  allActionsByName: EMPTY_OBJECT,
  data: EMPTY_ARRAY,
  customRowActionsData: EMPTY_ARRAY,
};

export default makeCellRenderer(CustomActionCellRenderer);
