import React from 'react';
import PropTypes from 'prop-types';

import AsyncPaginatedSelect from '../../../../../../../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';
import { searchPageConfigurations } from '../../../../../../../../../../../../actions/visualBuilder.actions';
import { getOptionsForPageField, getPayloadForPageField } from '../../../../helpers/propertiesPanel.helpers';

import styles from './pageComponent.module.scss';

const PageComponent = ({ onAction, ...restProps }) => (
  <div className={styles.pageContainer}>
    <div className={styles.pageHeader}>{__('Page')}</div>
    <AsyncPaginatedSelect
      {...restProps}
      label={__('Add Page')}
      serviceHandler={searchPageConfigurations}
      getOptions={getOptionsForPageField}
      getPayload={getPayloadForPageField}
      onAction={onAction}
    />
  </div>
);

PageComponent.propTypes = {
  onAction: PropTypes.func.isRequired,
};

export default PageComponent;
