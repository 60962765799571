import _map from 'lodash/map';

import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInputRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import RadioRenderer from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/radio';

import subscriptionListRenderer from '../components/subscriptionListRenderer/SubscriptionListRenderer';
import AsyncPaginatedSelect from '../../../../../../molecules/asyncPaginatedSelect/AsyncPaginatedSelect';

import { searchServerCredentials } from '../../../../../../actions/serverCredential.actions';

import { getServerCredentialOptions, getServerCredentialPayload } from '../helpers/subscriptionConfigForm.helpers';

import { SUBSCRIPTION_CONFIG_STATUS_RADIO_OPTIONS, SUBSCRIPTION_CONFIG_STATUS_RADIO_LABEL_MAP } from './subscriptionConfigForm.general';
import FIELD_IDS from './subscriptionConfigForm.fieldIds';

const createRadio = (option) => ({
  label: SUBSCRIPTION_CONFIG_STATUS_RADIO_LABEL_MAP[option],
  value: option,
});

const createSubscriptionConfigStatusRadios = () => _map(SUBSCRIPTION_CONFIG_STATUS_RADIO_OPTIONS, createRadio);

const SUBSCRIPTION_CONFIG_NAME_FIELD = {
  id: FIELD_IDS.SUBSCRIPTION_CONFIG_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Subscription Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const SUBSCRIPTION_CONFIG_DISPLAY_NAME_FIELD = {
  id: FIELD_IDS.SUBSCRIPTION_CONFIG_DISPLAY_NAME,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Subscription Display Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

const SUBSCRIPTION_CONFIG_STATUS_FIELD = {
  id: FIELD_IDS.STATUS,
  renderer: RadioRenderer,
  renderOptions: {
    label: __('Status'),
    required: true,
    validators: [isRequiredRule],
    radios: createSubscriptionConfigStatusRadios(),
    labelClassName: 'm-b-8',
  },
};

const SUBSCRIPTION_CONFIG_PATH_FIELD = {
  id: FIELD_IDS.PATH,
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Path'),
    required: true,
    validators: [isRequiredRule],
  },
};

const SUBSCRIPTION_CONFIG_SERVER_CREDENTIAL_FIELD = {
  id: FIELD_IDS.SERVER_CREDENTIAL,
  renderer: AsyncPaginatedSelect,
  renderOptions: {
    label: __('Select Server Credential'),
    id: FIELD_IDS.SERVER_CREDENTIAL,
    serviceHandler: searchServerCredentials,
    getOptions: getServerCredentialOptions,
    getPayload: getServerCredentialPayload,
  },
};

const SUBSCRIPTION_CONFIG_SUBSCRIPTIONS_FIELD = {
  id: FIELD_IDS.SUBSCRIPTIONS,
  renderer: subscriptionListRenderer,
  renderOptions: {
    label: __('Subscriptions'),
  },
};

export {
  SUBSCRIPTION_CONFIG_NAME_FIELD,
  SUBSCRIPTION_CONFIG_STATUS_FIELD,
  SUBSCRIPTION_CONFIG_DISPLAY_NAME_FIELD,
  SUBSCRIPTION_CONFIG_PATH_FIELD,
  SUBSCRIPTION_CONFIG_SERVER_CREDENTIAL_FIELD,
  SUBSCRIPTION_CONFIG_SUBSCRIPTIONS_FIELD,
};
