import OPERATORS from '@tekion/tekion-base/constants/filterOperators';
import { FILTER_FIELDS } from './filterBuilder.constants';
import { addFilterObjectToFilterPayload, convertToFilterPayload, getFilterObject, getOrTypeFilterObject } from './filterBuilder.helpers';
import FILTER_FIELD_KEYS from '../../constants/filter.constants';

class FilterBuilder {
  constructor() {
    this.filterPayload = [];
  }

  // filters in arg should be created from filter builder
  addOrTypeFilters(filters) {
    const filterObject = getOrTypeFilterObject(filters);
    this.filterPayload.push(filterObject);

    return this;
  }

  // below method will convert selected filter of filter section into filter payload
  addFilterSectionFilters(filterSectionFilters) {
    const convertedFilters = convertToFilterPayload(filterSectionFilters);
    this.filterPayload = [...this.filterPayload, ...convertedFilters];

    return this;
  }

  // Below methods are for specific filed ids
  addIdFilter({ values, allowEmptyValuesInFilter = false, operator = OPERATORS.IN }) {
    const filterObject = getFilterObject(FILTER_FIELDS.ID, operator, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  addNameFilter({ values, allowEmptyValuesInFilter = false, operator = OPERATORS.IN }) {
    const filterObject = getFilterObject(FILTER_FIELDS.NAME, operator, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  addDisplayNameFilter({ values, allowEmptyValuesInFilter = false, operator = OPERATORS.IN }) {
    const filterObject = getFilterObject(FILTER_FIELDS.DISPLAY_NAME, operator, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  addEntityNameFilter({ values, allowEmptyValuesInFilter = false, operator = OPERATORS.IN }) {
    const filterObject = getFilterObject(FILTER_FIELDS.ENTITY_NAME, operator, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  addViewNameFilter({ values, allowEmptyValuesInFilter = false, operator = OPERATORS.IN }) {
    const filterObject = getFilterObject(FILTER_FIELDS.VIEW_NAME, operator, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  addDeletedFilter({ values, allowEmptyValuesInFilter = false, operator = OPERATORS.IN }) {
    const filterObject = getFilterObject(FILTER_FIELDS.DELETED, operator, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  addViewTypeFilter({ values, allowEmptyValuesInFilter = false, operator = OPERATORS.IN }) {
    const filterObject = getFilterObject(FILTER_FIELDS.VIEW_TYPE, operator, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  // for custom filter
  addCustomFilter({
    [FILTER_FIELD_KEYS.FIELD]: field,
    [FILTER_FIELD_KEYS.FILTER_TYPE]: filterType,
    [FILTER_FIELD_KEYS.VALUES]: values,
    allowEmptyValuesInFilter = false,
  }) {
    const filterObject = getFilterObject(field, filterType, values, allowEmptyValuesInFilter);
    addFilterObjectToFilterPayload(this.filterPayload, filterObject);

    return this;
  }

  // always use this function at the end to get filter payload array
  getFilterPayload() {
    return this.filterPayload;
  }
}

export default FilterBuilder;
